<form
  [formGroup]="crownSectionForm"
  class="crown-section"
  [class.read-only]="isReadOnly || (isCrownSectionDisabled$ | async)"
>
  <div
    fxFlex
    fxLayout="row"
    fxLayoutAlign="center"
    fxLayout.sm="column stretch"
    fxLayout.xs="column stretch"
  >
    <div
      class="left-content"
      fxFlex="1 1 60%"
      fxLayout="row wrap"
      fxLayoutAlign="space-between stretch"
    >
      <rx-object-selector
        fxFlex="100"
        [id]="'material'"
        [label]="'ToothEditor.Material' | translate"
        [isRequired]="shouldValidateForSend$ | async"
        [isDisabled]="isCrownSectionDisabled$ | async"
        [isReadOnly]="isReadOnly"
        [formControl]="materialControl"
        [options]="materialOptions"
        name="materialControl"
      ></rx-object-selector>
      <rx-object-selector
          fxFlex="45"
        [id]="'preparation-design-buccal'"
        [label]="'ToothEditor.PreparationDesign' | translate"
        [secondaryLabel]="'ToothEditor.Buccal' | translate"
        [isRequired]="(shouldValidateForSend$ | async) && materialOption?.PrepDesignMandatory"
        [formControl]="preparationDesignBuccalControl"
        [isDisabled]="!materialOption?.PrepDesignVisible"
        [isReadOnly]="isReadOnly"
        [options]="preparationDesignOptions"
        name="preparationDesignBuccalControl"
      ></rx-object-selector>
      <rx-object-selector
          fxFlex="45"
        [id]="'preparation-design-lingual'"
        [label]="'ToothEditor.PreparationDesign' | translate"
        [secondaryLabel]="'ToothEditor.Lingual' | translate"
        [isRequired]="(shouldValidateForSend$ | async) && materialOption?.PrepDesignMandatory"
        [formControl]="preparationDesignLingualControl"
        [isDisabled]="!materialOption?.PrepDesignVisible"
        [isReadOnly]="isReadOnly"
        [options]="preparationDesignOptions"
        name="preparationDesignLingualControl"
      ></rx-object-selector>
      <rx-object-selector
          fxFlex="45"
        [id]="'margin-design-buccal'"
        [label]="'ToothEditor.MarginDesign' | translate"
        [secondaryLabel]="'ToothEditor.Buccal' | translate"
        [isRequired]="(shouldValidateForSend$ | async) && materialOption?.MarginDesignMandatory"
        [formControl]="marginDesignBuccalControl"
        [isDisabled]="!materialOption?.MarginDesignVisible"
        [isReadOnly]="isReadOnly"
        [options]="marginDesignOptions"
        name="marginDesignBuccalControl"
      ></rx-object-selector>
      <rx-object-selector
          fxFlex="45"
        [id]="'margin-design-lingual'"
        [label]="'ToothEditor.MarginDesign' | translate"
        [secondaryLabel]="'ToothEditor.Lingual' | translate"
        [isRequired]="(shouldValidateForSend$ | async) && materialOption?.MarginDesignMandatory"
        [formControl]="marginDesignLingualControl"
        [isDisabled]="!materialOption?.MarginDesignVisible"
        [isReadOnly]="isReadOnly"
        [options]="marginDesignOptions"
        name="marginDesignLingualControl"
      ></rx-object-selector>
      <rx-object-selector
          fxFlex="45"
        *ngIf="(copyFromToothOptions$ | async)?.length && isToothClickedOnAPrep"
        [id]="'copy-from-tooth'"
        [label]="'ToothEditor.CopyFromTooth' | translate"
        [formControl]="copyFromToothControl"
		[isCancellable]="true"
        [isDisabled]="isCrownSectionDisabled$ | async"
        [isReadOnly]="isReadOnly"
		(cancelSelection)="handleCancelCopyFromToothSelection()"
        [options]="copyFromToothOptions$ | async"
        name="copyFromToothControl"
      ></rx-object-selector>
    </div>
    <div
      fxFlex="1 1 40%"
      fxLayout="column start"
      fxLayout.sm="column"
      fxLayout.xs="column"
    >
      <rx-object-selector
        [id]="'shade-system'"
        [label]="'ToothEditor.ShadeSystem' | translate"
        [isDisabled]="isShadeDisabled"
        [isReadOnly]="isReadOnly"
        [formControl]="shadeSystemControl"
        [options]="shadeSystemOptions"
        name="shadeSystemControl"
      ></rx-object-selector>
        <ng-container *ngIf="!isOtherShadeSystemSelected">
            <rx-object-selector
                [id]="'incisal'"
                [label]="'ToothEditor.Incisal' | translate"
                [isDisabled]="isShadeDisabled"
                (isDisabledEvent)="clearControl(incisalControl)"
                [isReadOnly]="isReadOnly"
                [formControl]="incisalControl"
                [options]="incisalBodyGingivalOptions"
                name="incisalControl"
            ></rx-object-selector>
            <rx-object-selector
                [id]="'body'"
                [label]="'ToothEditor.Body' | translate"
                [isRequired]="(shouldValidateForSend$ | async) && materialOption?.BodyShadeMandatory"
                [isDisabled]="isShadeDisabled"
                (isDisabledEvent)="clearControl(bodyControl)"
                [isReadOnly]="isReadOnly"
                [formControl]="bodyControl"
                [options]="incisalBodyGingivalOptions"
                name="bodyControl"
            ></rx-object-selector>
            <rx-object-selector
                [id]="'gingival'"
                [label]="'ToothEditor.Gingival' | translate"
                [isDisabled]="isShadeDisabled"
                (isDisabledEvent)="clearControl(gingivalControl)"
                [isReadOnly]="isReadOnly"
                [formControl]="gingivalControl"
                [options]="incisalBodyGingivalOptions"
                name="gingivalControl"
            ></rx-object-selector>

        </ng-container>
        <ng-container *ngIf="isOtherShadeSystemSelected">
            <div>
                <mat-form-field fxFlex="1 1 100%">
                    <mat-label>{{ 'ToothEditor.Incisal' | translate }}</mat-label>
                    <input
                        matInput
                        [maxlength]="maxInputLengthForOtherShadeSystem"
                        [formControl]="incisalControl"
                        [rxIsDisabled]="isShadeDisabled"
                        [readonly]="isReadOnly"
                        (rxIsDisabledEvent)="clearControl(incisalControl)"
                        autocomplete="off"
                        name="incisalControl"
                    />
                </mat-form-field>
            </div>
            <div>
                <mat-form-field fxFlex="1 1 100%">
                    <mat-label>{{ 'ToothEditor.Body' | translate }}</mat-label>
                    <input
                        matInput
                        [maxlength]="maxInputLengthForOtherShadeSystem"
                        [formControl]="bodyControl"
                        [rxIsDisabled]="isShadeDisabled"
                        [readonly]="isReadOnly"
                        (rxIsDisabledEvent)="clearControl(bodyControl)"
                        [required]="materialOption?.BodyShadeMandatory && (shouldValidateForSend$ | async)"
                        autocomplete="off"
                        name="bodyControl"
                    />
                </mat-form-field>
            </div>
            <div>
                <mat-form-field fxFlex="1 1 100%">
                    <mat-label>{{ 'ToothEditor.Gingival' | translate }}</mat-label>
                    <input
                        matInput
                        [maxlength]="maxInputLengthForOtherShadeSystem"
                        [formControl]="gingivalControl"
                        [rxIsDisabled]="isShadeDisabled"
                        [readonly]="isReadOnly"
                        (rxIsDisabledEvent)="clearControl(gingivalControl)"
                        autocomplete="off"
                        name="gingivalControl"
                    />
                </mat-form-field>
            </div>
        </ng-container>

      <rx-object-selector
        [id]="'stump-shade'"
        [label]="'ToothEditor.StumpShade' | translate"
        [isRequired]="(shouldValidateForSend$ | async) && materialOption?.StumpShadeMandatory"
        [isDisabled]="!materialOption?.StumpShadeVisible"
        (isDisabledEvent)="clearControl(stumpShadeControl)"
        [isReadOnly]="isReadOnly"
        [formControl]="stumpShadeControl"
        [options]="stumpShadeOptions"
        name="stumpShadeControl"
      ></rx-object-selector>
    </div>
  </div>
</form>
