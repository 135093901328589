import { Injectable } from '@angular/core';
import { LoggerService } from '@core/services/logger/logger.service';
import { TeethDiagramStore } from '@modules/teeth-diagram/state/teeth-diagram-store';
import { PopUpActions } from '@shared/models/enums/popup-modal-actions.enum';
import { IdName } from '@shared/models/id-name';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { combineLatest, Observable, of } from 'rxjs';
import { audit, delay, distinctUntilChanged, filter, map, mapTo, shareReplay, tap, withLatestFrom } from 'rxjs/operators';
import { CaseTypeEnum } from './models/case-type.enum';
import { OrderForm } from './models/order-form';
import { CaseTypeFormState } from './models/case-type-form-state';
import { CaseTypeChangedService } from './services/case-type-changed.service';
import { CaseTypeFormStateService } from './services/case-type-form-state.service';
import { TreatmentStageChangedService } from './services/treatment-stage-changed.service';
import { OrderQuery } from './state/order-query';
import { getMaximumDueDateForReferral, OrderStore } from './state/order-store';
import { RxForDoctorQuery } from '@modules/rx-for-doctor/state/rx-for-doctor-query';
import { OrderFormConfirmationService } from './services/order-form-confirmation.service';
import { SoftwareOptionsService } from '@shared/services/software-options.service';
import { ScanOptionsStore } from '@modules/scan-options/state/scan-options-store';
import { combineQueries } from '@datorama/akita';
import { SoftwareOptions } from '@shared/models/enums/enums';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { RxStatusService } from '@core/services/rx-status.service';
import { OrderFacadeBase } from '@modules/order/order.facade.base';
import { ProcedureEnum } from '@core/procedure-helpers/models/procedure.enum';

@Injectable()
export class OrderV0Facade extends OrderFacadeBase {
	protected static get componentName() { return 'OrderV0Facade'; }
	caseTypeOrderForm$: Observable<OrderForm> = this.query.caseTypeOrderForm$;
	caseTypes$: Observable<IdName[]> = combineQueries([
		this.query.availableCaseTypeIds$,
		this.shellQuery.caseTypes$,
		this.shellQuery.isCloningMode$,
		this.shellQuery.enableAllCaseTypesForAddRx$
	]).pipe(
		map(([availableCaseTypeIds, caseTypes, isCloningMode, enableAllCaseTypesForAddRx]) =>
			caseTypes.filter(
				caseType =>
					availableCaseTypeIds?.some(caseTypeId => caseType.Id === caseTypeId) &&
					(!isCloningMode || enableAllCaseTypesForAddRx || caseType.IsCloningAvailable)
			)
		),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	treatmentStages$: Observable<IdName[]> = this.query.treatmentStages$;
	isReturned$: Observable<boolean> = this.shellQuery.isReturned$;
	maxDueDate$: Observable<Date> = this.query.maxDueDate$;
	dueDate$: Observable<Date> = this.query.dueDate$;
	isReferralWorkflowPractice$: Observable<boolean> = this.rxForDoctorQuery.isReferralWorkflowPractice$;
	isFinalRecord$: Observable<boolean> = this.query.isFinalRecord$;
	isViveraCasetypeSelected$: Observable<boolean> = this.isViveraCasetypeSelected();
	rx$: Observable<RxModel> = this.shellQuery.rx$;

	caseTypeFormState$: Observable<CaseTypeFormState> = combineQueries([
		this.query.caseType$,
		this.query.treatmentStage$,
		this.shellQuery.shouldValidateForSend$,
		this.shellQuery.isReadOnly$,
		this.isReferralWorkflowPractice$,
		this.isFinalRecord$,
		this.rxScanStatusServiceService.status$
	]).pipe(
		map(([caseType, treatmentStage, shouldValidateForSend, isReadOnly, isReferralWorkflowPractice, isFinalRecord, rxScanStatus]) => {
			return this.orderFormControlsStatusService.getFormState({
				caseType,
				treatmentStage,
				shouldValidateForSend,
				isReadOnly,
				isReferralWorkflowPractice,
				isFinalRecord,
				rxScanStatus
			});
		}),
		tap(status => this.logger.debug(`order Form Controls Status: ${JSON.stringify(status)}`, { module: OrderV0Facade.componentName }))
	);
	get isCaseTypeReadOnly$(): Observable <boolean> {
		return combineLatest([this.isReadOnly$, this.shellQuery.isRxTakenForScan$, this.shellQuery.rx$])
			.pipe(map(([isReadOnly, isRxTakenForScan, rx]) => {
				return isReadOnly || (isRxTakenForScan && rx?.PrePrepScan);
			}));
	}

	constructor(
		protected store: OrderStore,
		protected query: OrderQuery,
		protected shellQuery: ShellQuery,
		protected logger: LoggerService,
		private orderFormControlsStatusService: CaseTypeFormStateService,
		private caseTypeChangedService: CaseTypeChangedService,
		private treatmentStageChangedService: TreatmentStageChangedService,
		private orderFormConfirmationService: OrderFormConfirmationService,
		private teethDiagramStore: TeethDiagramStore,
		private scanOptionsStore: ScanOptionsStore,
		private rxForDoctorQuery: RxForDoctorQuery,
		private softwareOptionsService: SoftwareOptionsService,
		private rxScanStatusServiceService: RxStatusService,
	) {
		super(store, query, shellQuery, logger);
	}

	setIsFinalRecordsSoftWareOption(): Observable<boolean> {
		return this.softwareOptionsService
			.hasScannerSoftwareOptions$([SoftwareOptions.FinalRecords])
			.pipe(tap(isFinalRecord => this.store.update({ isFinalRecord })));
	}

	cancelShipTo(): void {
		this.store.update({ sendTo: null });
	}

	setDefaultCaseType(): Observable<any> {
		return combineQueries([
			this.caseTypes$,
			this.shellQuery.defaultCaseTypeId$,
			this.isReferralWorkflowPractice$,
			this.shellQuery.selectLoading()
		]).pipe(
			distinctUntilChanged(),
			filter(
				([availableCaseTypes, defaultCaseTypeId, isReferralWorkflowPractice, isShellStoreLoading]) =>
					!isShellStoreLoading &&
					!!availableCaseTypes &&
					availableCaseTypes.length &&
					(!!defaultCaseTypeId || isReferralWorkflowPractice)
			),
			tap(([availableCaseTypes, defaultCaseTypeId, isReferralPracticeEnabled]) => {
				if (!this.shellQuery.rx?.Order?.CaseTypeId) {
					if (isReferralPracticeEnabled && (!defaultCaseTypeId || defaultCaseTypeId === -1)) {
						defaultCaseTypeId = CaseTypeEnum.Invisalign;
					}
					const defaultCaseType = availableCaseTypes.find(caseType => caseType.Id === defaultCaseTypeId);
					if (defaultCaseType) {
						this.store.update({ caseType: defaultCaseType });
					}
				}
			})
		);
	}

	handleCaseTypeChanged(): Observable<any> {
		return this.query.caseType$.pipe(
			withLatestFrom(this.shellQuery.dentalLabs$, this.shellQuery.orthoLabs$, this.query.dueDate$, this.query.selectLoading()),
			filter(([, , , , isLoading]) => !isLoading),
			tap(([caseType, dentalLabs, orthoLabs, dueDate]) =>
				this.caseTypeChangedService.resetControls({ caseType, dentalLabs, orthoLabs, dueDate })
			)
		);
	}

	handleTreatmentStageChanged(): Observable<IdName> {
		return this.query.treatmentStage$.pipe(
			tap(treatmentStage => this.treatmentStageChangedService.updateCurrentAlignerId({ treatmentStage }))
		);
	}

	updateCaseTypeBasedForm({
		formValue,
		isNewOrder = false
	}: {
		formValue: OrderForm;
		isNewOrder?: boolean;
	}): Observable<PopUpActions | undefined> {
		const updatedCaseType = formValue.caseType;
		const currentCaseType = this.query.caseType;
		const currentSendTo = this.query.sendTo;
		if (!isNewOrder && this.orderFormConfirmationService.shouldShowPopUpWhenCaseTypeChanged({ currentCaseType, updatedCaseType })) {
			return this.orderFormConfirmationService.openPopUp$(false).pipe(
				tap(canSwitchCaseType => {
					if (canSwitchCaseType === PopUpActions.Ok) {
						const initialFormValue: OrderForm = OrderV0Facade.getOrderFormInitialValue();
						initialFormValue.caseType = updatedCaseType;
						this.store.update({ ...initialFormValue });
						this.teethDiagramStore.resetTeeth();
						this.scanOptionsStore.reset();
					} else {
						const currentCaseTypeCopy = { ...this.query.caseType };
						this.store.update({ caseType: currentCaseTypeCopy });
					}
				})
			);
		}

		if (!formValue.caseType && currentCaseType) {
			formValue.caseType = currentCaseType;
		}

		if (!formValue.sendTo && currentSendTo) {
			formValue.sendTo = currentSendTo;
		}

		this.store.update({ ...formValue });
		return of(undefined);
	}

	updateCaseTypes(availableCaseTypeIds: number[]): void {
		this.store.update({ availableCaseTypeIds });
	}

	setMaxDueDate(): Observable<boolean> {
		return this.rxForDoctorQuery.isReferralWorkflowPractice$.pipe(
			tap(isReferralWorkflowPractice => {
				const maxDueDate = isReferralWorkflowPractice ? getMaximumDueDateForReferral() : null;
				this.store.update({ maxDueDate });
			})
		);
	}

	loadOrder(): Observable<any> {
		const orderDataIsLoaded$ = combineQueries([this.rx$, this.caseTypes$, this.shellQuery.allLabs$, this.treatmentStages$]).pipe(
			filter(([rx, caseTypes, labs, treatmentStages]) => !!(rx && caseTypes && labs && treatmentStages)),
			mapTo(true)
		);
		return this.shellQuery.selectLoading().pipe(
			filter(isShellStoreLoading => !isShellStoreLoading),
			// wait until order data is loaded for withLatestFrom to emit loaded values
			audit(_ => orderDataIsLoaded$),
			withLatestFrom(
				this.rx$,
				this.shellQuery.caseTypes$,
				this.shellQuery.allLabs$,
				this.treatmentStages$,
				this.shellQuery.rxConfiguration$
			),
			tap(([, rx, caseTypes, labs, treatmentStages, rxConfiguration]) => {
				this.store.setLoading(true);

				const availableCaseTypeIds: number[] = [...this.query.availableCaseTypeIds];
				if (availableCaseTypeIds.indexOf(rx.Order.CaseTypeId) < 0) {
					availableCaseTypeIds.unshift(rx.Order.CaseTypeId);
					this.store.update({ availableCaseTypeIds });
				}
				const alignerNumber = rx.AlignerNumber === null || rx.AlignerNumber === undefined ? '' : rx.AlignerNumber;
				const availableLabs: IdName[] = Array.from(new Set(labs.map(lab => lab.Id))).map(id => ({
					Id: id,
					Name: labs.find(lab => lab.Id === id).Name
				}));
				const orderForm = {
					caseType: caseTypes.find(caseType => caseType.Id === rx.Order.CaseTypeId),
					sendTo: labs.find(lab => lab.Id === rx.Order.ShipToId),
					isMultiBiteSelected: rx.MultiBiteScan,
					treatmentStage: treatmentStages.find(ts => ts.Id === rx.TreatmentStage),
					dueDate: rx.Order.DueDate ? new Date(rx.Order.DueDate) : null,
					currentAlignerId: `${alignerNumber}`,
					availableLabs
					/**
					 * In rx-app 1.0.0, `getProcedureFlowConfiguration` should NEVER be chosen, as it's not fully implemented yet.
					 * DONT MERGE THIS BACK TO `develop` BRANCH
					 */
					// type: rxConfiguration.Types.find(x => x.Id === rx.Order.ProcedureTypeId),
					// procedure: rxConfiguration.Procedures.find(x => x.Id === rx.Order.ProcedureId)
				};

				this.updateCaseTypeBasedForm({ formValue: orderForm, isNewOrder: true });
			}),
			delay(0),
			tap(() => this.store.setLoading(false))
		);
	}

	private static getOrderFormInitialValue(): OrderForm {
		return {
			caseType: null,
			sendTo: null,
			isMultiBiteSelected: null,
			treatmentStage: null,
			dueDate: null,
			currentAlignerId: null
		};
	}

	private isViveraCasetypeSelected(): Observable<boolean> {
		return this.query.caseType$.pipe(
			map(casetype => {
				return [CaseTypeEnum.Vivera, CaseTypeEnum.ViveraPreDebond].includes(casetype?.Id);
			})
		);
	}
}
