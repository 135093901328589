import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationPopUpInput } from '@shared/models/confirmation-pop-up-input';
import { PopUpActions } from '@shared/models/enums/popup-modal-actions.enum';
import { ShellQuery } from '@shared/store/shell/shell-query';

@Component({
	selector: 'rx-confirmation-pop-up',
	templateUrl: './confirmation-pop-up.component.html',
	styleUrls: ['./confirmation-pop-up.component.scss']
})
export class ConfirmationPopUpComponent implements OnInit {

	popUpActions = PopUpActions;
	iconSrc: string;
	private defaultIconName = 'information-icon';
	dontShowAgain = false;
	isDontShowAgainVisible = !!this.dialogInput.dontShowAgainAction;

	constructor(
		public dialogRef: MatDialogRef<ConfirmationPopUpComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogInput: ConfirmationPopUpInput,
		private shellQuery: ShellQuery
	) { }

	ngOnInit(): void {
		const iconName = this.dialogInput.iconName ?? this.defaultIconName;
		this.iconSrc = `${this.shellQuery.staticFilesEndpoint}/assets/icons/${iconName}.png`
	}

}
