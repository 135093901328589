import { Injectable } from '@angular/core';
import { ApiService, IApiCall } from '@core/services/api.service';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';

@Injectable({ providedIn: 'root' })
export class RxForDoctorApiService {
 
	constructor(
		private apiService: ApiService
	) { }
 
	saveRx(rx: RxModel) {
		const config: IApiCall = {
			selector: 'saveRx',
			options: { body: rx }
		};
		return this.apiService.request(config);
	}
}
