<mat-form-field class="moulds-dropdown" [class.read-only]="isReadOnly">
    <mat-label [class.read-only-label]="isReadOnly && !control.value">{{ label }}:</mat-label>
    <mat-select
        [id]="'rx-moulds-select-' + id"
        [formControl]="control"
        name="option"
        class="moulds-dropdown__wrapper"
        panelClass="moulds-dropdown__panel"
        [compareWith]="compareWith"
    >
        <mat-option
            *ngFor="let mould of options; let index = index;"
            [class]="'rx-auto-simple-select-option-' + index"
            class="moulds-dropdown__option"
            [value]="mould"
        >
            <div class="moulds-dropdown__image-wrapper">
                <rx-mould-element [mould]="mould" [isSelected]="mould.Id === (selectedMouldId$ | async)"></rx-mould-element>
            </div>
        </mat-option>
    </mat-select>
</mat-form-field>
