import { Component, Injector, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ControlValueAccessorConnector } from '@shared/base-classes/control-value-accessor-connector';
import { IdName } from '@shared/models/id-name';

export class ErrorStateMatcherForInstantDisplay implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		if (form.form.controls.body === control) {
			return !!(control && control.invalid && control.touched);
		}
		return !!(control && control.invalid);
	}
}
@Component({
	selector: 'rx-simple-selector',
	templateUrl: './simple-selector.component.html',
	styleUrls: ['./simple-selector.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: SimpleSelectorComponent,
			multi: true
		}
	]
})
export class SimpleSelectorComponent extends ControlValueAccessorConnector {
	@Input() id: string;
	@Input() label: string;
	@Input() secondaryLabel: string;
	@Input() options: IdName[];
	@Input() isRequired: boolean;
	@Input() isDisabled: boolean;
	@Input() isReadOnly: boolean;
	@Input() isCancellable: boolean;
	@Input() labelSeparator: string;
	@Input() matcher = new ErrorStateMatcherForInstantDisplay();
	@Output() cancelSelection: EventEmitter<any> = new EventEmitter<any>();

	constructor(injector: Injector) {
		super(injector);
	}

	compareIds(item1: any, item2: any): boolean {
		if (!item1 || !item2) {
			return false;
		}
		return item1.Id === item2.Id;
	}

	handleCancelSelection(event: any): void {
		event.stopPropagation();
		this.cancelSelection.emit(null);
	}

	generateOptionId(option, label, secondaryLabel): string {
		return this.getOptionId(option.Name, label, secondaryLabel);
	}

	protected getOptionId(value, label, secondaryLabel): string {
		return 'simple-select-option-' + label?.toLowerCase().replace(' ', '-') +
			(secondaryLabel ? '-' + secondaryLabel?.toLowerCase().replace(' ', '-') : '') +
			'-' + value?.replace(/[\W_]+/g, '')?.toLowerCase();
	}
}
