import { UnitTypesInBridge } from '@modules/teeth-diagram/models/unit-type-in-bridge.enum';
import { ShareToothPropsRule } from '@shared/models/share-tooth-props-rule';

export const shareToothPropsRules: ShareToothPropsRule[] = [
	{
		unitTypes: [
			UnitTypesInBridge.Abutment,
			UnitTypesInBridge.Pontic,
			UnitTypesInBridge.Maryland,
			UnitTypesInBridge.ImplantBased,
			UnitTypesInBridge.Inlay,
			UnitTypesInBridge.Onlay
		],
		sharedProps: ['SpecificationId', 'MaterialID'],
	},
	{
		unitTypes: [UnitTypesInBridge.Pontic],
		sharedProps: ['PonticDesignID'],
	}
]