import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DoctorFacade } from '@modules/doctor/doctor.facade';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { Doctor } from '@shared/models/doctor';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

@Component({
	selector: 'rx-doctor',
	templateUrl: './doctor.component.html',
	styleUrls: ['./doctor.component.scss'],
	providers: [DoctorFacade]
})
export class DoctorComponent extends BaseDestroyableComponent implements OnInit {
	doctor$: Observable<Doctor> = this.facade.doctor$;
	license$: Observable<string> = this.facade.doctor$.pipe(map(doctor => doctor?.Licence));
	isLicenseEditable$: Observable<boolean> = this.facade.isLicenseEditable$.pipe(
		withLatestFrom(this.facade.isReadOnly$),
		map(([isLicenseEditable, isReadOnly]) => {
			return !isReadOnly && isLicenseEditable;
		})
	);
	isReadOnly$: Observable<boolean> = this.facade.isReadOnly$;

	licenseFormControl = new FormControl();

	constructor(private facade: DoctorFacade) {
		super();
	}

	ngOnInit(): void {
		this.getDoctorInformation();
		this.subscribeToUserChangesLicense();
		this.subscribeToStoreChanges();
	}

	private getDoctorInformation(): void {
		this.facade
			.getDoctorInformation()
			.pipe(
				switchMap(doctorInfo => this.afterRxValidForSave(doctorInfo)),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private afterRxValidForSave(doctorInfo: Doctor): Observable<boolean> {
		const isDoctorInfoValid = !!(doctorInfo?.Id && doctorInfo?.Licence && doctorInfo?.Name);
		return this.facade.isRxValidForSave$.pipe(
			tap(isRxValidForSave => {
				if (isRxValidForSave === false && !this.licenseFormControl.value && isDoctorInfoValid) {
					this.licenseFormControl.setErrors({ required: true });
					this.licenseFormControl.markAsTouched();
				}
			})
		);
	}

	private subscribeToUserChangesLicense(): void {
		this.licenseFormControl.valueChanges
			.pipe(
				debounceTime(300),
				distinctUntilChanged(),
				tap(license => this.facade.updateLicense({ license })),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private subscribeToStoreChanges(): void {
		this.license$
			.pipe(
				tap(license => this.licenseFormControl.patchValue(license, { emitEvent: false })),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}
}
