export interface DefaultCrownState {
	crownData: Record<number, CrownData>;
}

export interface CrownData {
	specificationId?: number;
	materialId?: number;
	ponticId?: number;
	touchedPontics?: number[];
}

export enum CrownDefaultProperties {
	SpecificationId = 'SpecificationId',
	MaterialId = 'MaterialID',
	PonticId = 'PonticDesignID'
}
