import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { DefaultCrownStore } from '@modules/tooth-editor/state/default-crown-store';
import { DefaultCrownState } from '@modules/tooth-editor/models/default-crown-data';

@Injectable({ providedIn: 'root' })
export class DefaultCrownQuery extends Query<DefaultCrownState> {
	constructor(store: DefaultCrownStore) {
		super(store);
	}

	getDefaultProperty(index: number, propertyName: string): number {
		return this.getValue().crownData[index]?.[propertyName] ?? null;
	}

	wasPonticTouched(index: number, toothId: number): boolean {
		return this.getValue().crownData[index]?.touchedPontics?.includes(toothId);
	}
}
