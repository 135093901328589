import { ElementRef, Injector, Input, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorConnector } from '@shared/base-classes/control-value-accessor-connector';
import { ErrorStateMatcherForInstantDisplay } from "@shared/components/simple-selector/simple-selector.component";

@Component({
	selector: 'rx-due-date-picker',
	templateUrl: './due-date-picker.component.html',
	styleUrls: ['./due-date-picker.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR, useExisting: DueDatePickerComponent, multi: true
		}
	]
})
export class DueDatePickerComponent extends ControlValueAccessorConnector {
	@Input() minimalAvailableDueDate: Date;
	@Input() maxDueDate: Date;
	@Input() isRequired: boolean;
	@Input() isReadOnly: boolean;
	@Input() matcher = new ErrorStateMatcherForInstantDisplay();

	constructor(injector: Injector) {
		super(injector);
	}
}
