import { Directive, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormControlDirective } from "@angular/forms";

@Directive({
  	selector: '[rxIsDisabled][formControl]'
})
export class IsDisabledDirective {
	@Input('rxIsDisabled')
	set disabled(value: boolean | string) {
		this.handleDisabled(value);
	}

	@Output()
	rxIsDisabledEvent: null | EventEmitter<void> = new EventEmitter<void>();

	get control(): AbstractControl {
		return this.formControlDirective.control;
	}

  	constructor(protected formControlDirective: FormControlDirective) { }

	handleDisabled(value: boolean | string): boolean {
		const disabled = !!value && value !== 'false';

		if (!this.control || this.control.disabled === disabled) {
			return;
		}

		if (disabled) {
			this.control.disable({emitEvent: false});
			this.rxIsDisabledEvent?.emit();
		} else {
			this.control.enable({emitEvent: false});
		}
		
		return disabled; 
	}
}
