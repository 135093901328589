import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RxForLabFacade } from '../../rx-for-lab.facade';

@Component({
	selector: 'rx-for-lab-v0',
	templateUrl: './rx-for-lab-v0.component.html',
	styleUrls: ['./rx-for-lab-v0.component.scss'],
	providers: [RxForLabFacade]
})
export class RxForLabV0Component implements OnInit {
	isRxloadedAndReady$: Observable<boolean> = this.facade.isRxloadedAndReady$;
	isTeethDiagramAreaVisible$: Observable<boolean> = this.facade.isTeethDiagramAreaVisible$;
	caseTypeId$: Observable<number> = this.facade.caseTypeId$;

	constructor(private facade: RxForLabFacade) {}

	ngOnInit(): void {
		this.caseTypeId$
			.pipe(
				tap(caseTypeId => {
					this.facade.updateCaseTypeId({ caseTypeId });
				})
			)
			.subscribe();
	}
}
