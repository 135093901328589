import { Component, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorConnector } from '@shared/base-classes/control-value-accessor-connector';

@Component({
	selector: 'rx-simple-form-field',
	templateUrl: './simple-form-field.component.html',
	styleUrls: ['./simple-form-field.component.scss'],

})
export class SimpleFormFieldComponent {
}
