import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { ScanOptionsQuery } from './state/scan-options-query';
import { ScanOptionsStore } from './state/scan-options-store';
import { LoggerService } from '@core/services/logger/logger.service';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { map, tap } from 'rxjs/operators';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { OrderStore } from '@modules/order/state/order-store';
import { OrderQuery } from '@modules/order/state/order-query';
import { ProcedureMap } from '@shared/models/procedure-map';
import { DentureDetailsStore } from '@modules/denture-details/state/denture-details.store';
import { DentureDetailsQuery } from '@modules/denture-details/state/denture-details.query';
import { combineQueries } from '@datorama/akita';
import { ProcedureEnum } from '@core/procedure-helpers/models/procedure.enum';

@Injectable()
export class ScanOptionsFacade {
	isReadOnly$: Observable<boolean> = this.shellQuery.getReadOnlyState('scanOptions');

	isNiriCaptureVisible$: Observable<boolean> = this.shellQuery.isNiriCaptureVisible$;
	isNiriCaptureChecked$: Observable<boolean> = this.query.isNiriCaptureChecked$;

	isSleeveConfirmationVisible$: Observable<boolean> = this.shellQuery.isSleeveConfirmationVisible$;
	isSleeveChecked$: Observable<boolean> = this.query.isSleeveChecked$.pipe(map(x => x.value));
	isSleeveConfirmationReadOnly$: Observable<boolean> = this.shellQuery.isSleeveConfirmationReadOnly$;

	isPreTreatmentVisible$: Observable<boolean> = 
		combineQueries([this.shellQuery.isPreTreatmentVisibleForV0$, this.orderQuery.procedureMap$])
		.pipe(map(([isPreTreatmentVisibleForV0, procedureMap ]) => 
			isPreTreatmentVisibleForV0 || !!procedureMap?.PreTreatmentScanIsEnabled));
	isPreTreatmentChecked$: Observable<boolean> = this.query.isPreTreatmentChecked$;
	isPreTreatmentReadOnly$: Observable<boolean> = this.shellQuery.isPreTreatmentReadOnly$;

	isMultiBiteSelected$: Observable<boolean> = this.orderQuery.isMultiBiteSelected$;
	procedureMap$: Observable<ProcedureMap> = this.orderQuery.procedureMap$;

	isDentureCopyScan$: Observable<boolean> = this.dentureDetailsQuery.isDentureCopyScan$;
	isDentureCopyScanVisible$: Observable<boolean> = this.orderQuery.procedureMap$.pipe(
		map(procedureMap => procedureMap?.DentureCopyScanIsEnabled));

	shouldShowMultiBiteInformationMessage$: Observable<boolean> = 
		combineQueries([this.orderQuery.procedureMap$, this.isMultiBiteSelected$])
		.pipe(map(([procedureMap, isMultiBiteSelected]) => 
		procedureMap?.ProcedureId === ProcedureEnum.StudyModel_iRecord
		&& isMultiBiteSelected));

	isRxTakenForScan$ = this.shellQuery.isRxTakenForScan$;

	constructor(
		private store: ScanOptionsStore,
		private query: ScanOptionsQuery,
		private shellQuery: ShellQuery,
		private logger: LoggerService,
		private orderStore: OrderStore,
		private orderQuery: OrderQuery,
		private dentureDetailsStore: DentureDetailsStore,
		private dentureDetailsQuery: DentureDetailsQuery
	) {}

	updateSleeveConfirmation(value: boolean) {
		this.logger.info(`updateSleeveConfirmation: with-> ${value} `, { module: 'ScanOptionsFacade' });
		this.store.update({ isSleeveChecked: { value, isValueChangedByUser: true } });
	}

	updatePreTreatmentConfirmation({ isPreTreatmentChecked }: { isPreTreatmentChecked: boolean }) {
		this.logger.info(`updatePreTreatment: with-> ${isPreTreatmentChecked} `, { module: 'ScanOptionsFacade' });
		this.store.update({ isPreTreatmentChecked });
	}

	updateNiriCapture({ isNiriCaptureChecked }: { isNiriCaptureChecked: boolean }) {
		this.logger.info(`updateNiriCapture: with-> ${isNiriCaptureChecked} `, { module: 'ScanOptionsFacade' });
		this.store.update({ isNiriCaptureChecked });
	}

	loadNiri(): Observable<[boolean, RxModel]> {
		return combineLatest([this.shellQuery.niriCaptureDefaultValue$, this.shellQuery.rx$]).pipe(
			tap(([niriCaptureDefaultValue, rx]) => {
				const isNiriChecked = typeof rx?.NIRIEnabled === 'boolean' ? rx.NIRIEnabled : niriCaptureDefaultValue;
				this.store.update({ isNiriCaptureChecked: isNiriChecked ?? false });
			})
		);
	}

	updateScanOptionsMultiBite(isMultiBite: boolean): void {
		this.orderStore.update({ isMultiBiteSelected: isMultiBite });
	}

	loadSleeveConfirmation(): Observable<boolean> {
		return combineLatest([this.shellQuery.isRxSent$, this.shellQuery.rx$, this.query.isSleeveCheckedSentByScanner$]).pipe(
			map(([isRxSent, rx, isSleeveCheckedSentByScanner]) => {
				// this value is used by scanner in cases when doctor returns from scan page to rx page and already confirmed new sleeve.
				if (!!isSleeveCheckedSentByScanner) {
					return true;
				}
				if (!isRxSent) {
					return false;
				}
				return !!rx.IsSleeveConfirmed;
			}),
			tap((value: boolean) => this.store.update({ isSleeveChecked: { value, isValueChangedByUser: false}})),
		);
	}

	loadPreTreatment(): Observable<[boolean, RxModel]> {
		return combineLatest([this.shellQuery.isRxSent$, this.shellQuery.rx$]).pipe(
			tap(([, rx]) => {
				const isPreTreatmentChecked = typeof rx?.PrePrepScan === 'boolean' ? rx.PrePrepScan : false;
				this.store.update({ isPreTreatmentChecked: isPreTreatmentChecked ?? false });
			})
		);
	}

	updateScanOptionsDentureCopyScan(isDentureCopyScan: boolean): void {
		this.dentureDetailsStore.update({ isDentureCopyScan });
	}

	get isProcedureFlow(): boolean {
		return this.shellQuery.isProcedureFlow;
	}
}
