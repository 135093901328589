import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { DentureDetailsStore, DentureDetailsState } from './denture-details.store';
import { Observable } from 'rxjs';
import { ShadeSystem } from "@shared/models/shade-system";

@Injectable({ providedIn: 'root' })
export class DentureDetailsQuery extends Query<DentureDetailsState> {
	shadeSystem$: Observable<ShadeSystem> = this.select(state => state.shadeSystem);
	isDentureCopyScan$: Observable<boolean> = this.select(state => state.isDentureCopyScan);

	get shadeSystem(): ShadeSystem { return this.getValue().shadeSystem }
	
	constructor(protected store: DentureDetailsStore) {
		super(store);
	}
}
