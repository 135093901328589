import { Component, Input } from '@angular/core';
import { getJawByToothNumber } from '@modules/teeth-diagram/models/teeth-numbering';
import { TeethNumberingSystem } from '@modules/teeth-diagram/models/teeth-numbering-system.enum';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { ToothEditorModalActions } from '@modules/teeth-diagram/models/tooth-editor-actions.enum';
import { UnitTypesInBridge } from '@modules/teeth-diagram/models/unit-type-in-bridge.enum';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { TreatmentInformationFacade } from '@modules/treatment-information/treatment-information.facade';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { AbutmentTypes } from '@shared/models/abutment-type.enum';
import { unitTypesWithoutDetails } from '@shared/models/consts';
import { RoleTypeEnum } from '@shared/models/role-type';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { ToothEditorService } from '@shared/services/tooth-editor.service';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { TreatmentInformationComponentPart } from '../models/treatment-information-component.enum';

@Component({ template: '' })
export class TreatmentInformationBaseComponent extends BaseDestroyableComponent {
	@Input() rxForPrint: { rx: RxModel; componentPart?: TreatmentInformationComponentPart };
	@Input() isTreatmentsTableShownEmpty = true;
	displayedColumns: string[] = ['Tooth No', 'Treatment', 'Material', 'Preparation Design', 'Shade', 'Show details'];
	teethNumberingSystem: TeethNumberingSystem;
	isHidden = false;

	treatments$: Observable<Tooth[]> = this.sortTreatments();
	upperJaw$: Observable<Tooth[]> = this.facade.upperJaw$;
	lowerJaw$: Observable<Tooth[]> = this.facade.lowerJaw$;
	bridges$: Observable<Tooth[][]> = this.facade.bridges$;

	get isPrintContext() {
		return !!this.rxForPrint?.rx;
	}

	get printComponentPart() {
		return this.rxForPrint?.componentPart;
	}

	get hasNoBridge() {
		return !this.facade.getBridgesSync({ teeth: this.rxForPrint?.rx?.Teeth })?.length;
	}

	constructor(protected facade: TreatmentInformationFacade, protected toothEditorService: ToothEditorService) {
		super();
	}

	ngOnInit(): void {
		if (this.isPrintContext) {
			this.treatments$ = of(this.facade.getTreatmentTeethSync({ teeth: this.rxForPrint.rx.Teeth }));
			this.bridges$ = of(this.facade.getBridgesSync({ teeth: this.rxForPrint.rx.Teeth }));
			this.displayedColumns.pop();
		}
		this.subscribeToIsTreatmentInfoHidden();

		this.facade.teethNumberingSystem$
			.pipe(takeUntil(this.componentAlive$))
			.subscribe(teethNumberingSystem => (this.teethNumberingSystem = teethNumberingSystem));
	}

	getToothNumberById(toothId: number): Observable<number | string> {
		return this.facade.getToothNumberById(toothId);
	}
	getUnitType(tooth: Tooth): string {
		return this.facade.getUnitType(tooth);
	}
	getBridgeTreatment(tooth: Tooth): string {
		return this.facade.getBridgeTreatment(tooth);
	}
	getMaterialName(materialId: number): Observable<string> {
		return this.facade.getMaterialName(materialId);
	}

	getSpecificationName(specId: number): Observable<string> {
		return this.facade.getSpecificationName(specId);
	}
	getAbutmentTypeById(tooth: Tooth) {
		return AbutmentTypes[tooth.AbutmentType || this.getDefaultAbutmentType(tooth)];
	}

	isAbutmentInfoAvailable(tooth: Tooth) {
		const isBridge = !!tooth.BridgeIndex;
		const isScanBody = isBridge ? tooth.ToothInBridgeTypeID === UnitTypesInBridge.ScanBody : tooth.UnitTypeID === UnitTypes.ScanBody;
		return isScanBody && this.getDefaultAbutmentType(tooth) !== 0;
	}

	isTreatmentClickable(tooth: Tooth): boolean {
		const isGlidewellNonBrigdeTooth = this.toothEditorService.isGlidewellOrder && !tooth.BridgeIndex;

		return !isGlidewellNonBrigdeTooth && !unitTypesWithoutDetails.includes(tooth.UnitTypeID);
	}

	sortTreatments(): Observable<Tooth[]> {
		return this.facade.treatments$.pipe(
			map(treatments => {
				if (treatments?.length) {
					return treatments.sort((a, b) => a.ToothID - b.ToothID);
				}
				return [];
			})
		);
	}

	openToothEditor(event: Event, tooth?: Tooth) {
		event.stopPropagation();
		if (!this.isTreatmentClickable(tooth)) {
			return;
		}
		const isBridge = !!tooth.BridgeIndex;
		if (isBridge) {
			this.openToothEditorInBridgeMode({ tooth });
			return;
		}
		this.toothEditorService
			.openToothEditor({
				unitType: tooth.UnitTypeID,
				teeth: [tooth],
				toothClickedOn: tooth
			})
			.pipe(
				tap((result: { teeth: Tooth[]; action: ToothEditorModalActions }) =>
					this.handleModalResultAndUpdateTeeth({ result, toothId: tooth.ToothID })
				),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	getBridgeSpanTitle = (teeth: Tooth[]): string => {
		const { firstTooth, lastTooth } = this.facade.getBridgeSpan(teeth);
		return `: ${firstTooth} - ${lastTooth}`;
	};

	getBridgeSpanId = (teeth: Tooth[]): string => {
		const { firstTooth, lastTooth } = this.facade.getBridgeSpan(teeth);
		return `-${firstTooth}-${lastTooth}`;
	};

	private openToothEditorInBridgeMode({ tooth }: { tooth: Tooth }) {
		combineLatest([this.upperJaw$, this.lowerJaw$])
			.pipe(
				take(1),
				switchMap(([upperJaw, lowerJaw]) => {
					const jaw = getJawByToothNumber({ toothId: tooth.ToothID });
					const allTeethInJaw = jaw === 'upper' ? upperJaw : lowerJaw;
					return this.toothEditorService.openToothEditorInBridgeMode({ tooth, upperJaw, lowerJaw }).pipe(
						tap((result: { teeth: Tooth[]; action: ToothEditorModalActions }) =>
							this.handleModalResultAndUpdateTeeth({
								result,
								toothId: tooth.ToothID,
								upperJaw,
								lowerJaw,
								allTeethInJaw
							})
						)
					);
				})
			)
			.subscribe();
	}

	private handleModalResultAndUpdateTeeth(modal: {
		result: { teeth: Tooth[]; action: ToothEditorModalActions };
		toothId: number;
		allTeethInJaw?: Tooth[];
		upperJaw?: Tooth[];
		lowerJaw?: Tooth[];
	}): void {
		const teethToUpdate = this.toothEditorService.handleModalResult(modal);
		this.facade.updateTeeth({ teethToUpdate });
	}

	private getDefaultAbutmentType(tooth: Tooth) {
		const { AbutmentType, CAMaterialID, CAMarginStyle } = tooth;
		return AbutmentType || CAMaterialID || CAMarginStyle ? AbutmentTypes.CustomAbutment : 0;
	}

	private subscribeToIsTreatmentInfoHidden() {
		combineLatest([this.facade.userRole$, this.treatments$, this.bridges$])
			.pipe(
				map(([userRole, treatments, bridges]) => {
					if (userRole === RoleTypeEnum.Doctor) {
						this.isHidden = !(treatments.length || bridges.length);
					}
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}
}
