import { Component, OnInit } from '@angular/core';
import { patientSectionModeEnum } from '@modules/patient/models/patient-section-visibility.enum';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { PatientModel } from '@shared/models/rx-models/interfaces/patient-model';
import { merge, Observable } from 'rxjs';
import { map, takeUntil, take } from 'rxjs/operators';
import { PatientFacade } from '../../patient.facade';
import { PatientPopupCommands } from '@modules/patient/services/patient-app-iframe-communication.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
	selector: 'rx-patient',
	templateUrl: './patient.component.html',
	styleUrls: ['./patient.component.scss'],
	providers: [PatientFacade]
})
export class PatientComponent extends BaseDestroyableComponent implements OnInit {
	isReadOnly$: Observable<boolean> = this.facade.isReadOnly$;
	patient$: Observable<PatientModel> = this.facade.patient$;
	maxPatientLastNameLength$: Observable<number> = this.facade.maxPatientLastNameLength$;
	patientAppInlineUrl$: Observable<SafeResourceUrl> = this.facade.getInlinePatientAppUrl().pipe(
		map(url => this.sanitizer.bypassSecurityTrustResourceUrl(url))
	);
	isPatientInConflict$: Observable<boolean> = this.facade.isPatientInConflict$;
	isResizePatientAppDialog$: Observable<boolean> = this.facade.isPatientInConflict$;
	patientSectionMode$: Observable<patientSectionModeEnum> = this.facade.patientSectionMode$;
	isEditButtonVisibleForReadOnly$: Observable<boolean> = this.facade.isEditButtonVisibleForReadOnly$;
	isClearButtonVisibleForReadOnly$: Observable<boolean> = this.facade.isClearButtonVisibleForReadOnly$;
	isRxFromReferralDoctorAndUserIsScannigCenter$: Observable<boolean> = this.facade.isRxFromReferralDoctorAndUserIsScannigCenter$;
	patientSectionVersionEnum = patientSectionModeEnum;
	regulatoryDOBMask$: Observable<string> = this.facade.regulatoryDOBMask$;
	dateFormat$: Observable<string> = this.facade.dateFormat$;

	constructor(
		private facade: PatientFacade,
		private sanitizer: DomSanitizer) {
		super();
	}

	ngOnInit(): void {
		merge(
			this.registerToPatientAppEvents(),
			this.facade.loadPatientFromRx(),
			this.facade.patientPopupCommandHandler$
		).pipe(
			takeUntil(this.componentAlive$)
		).subscribe();
	}

	editPatient(): void {
		this.facade.patientPopupCommandEmitter$.next(PatientPopupCommands.EditPatient);
	}

	clearPatient(): void {
		this.facade.clearPatient().pipe(
			take(1),
			takeUntil(this.componentAlive$)
		).subscribe();
	}

	private registerToPatientAppEvents(): Observable<MessageEvent> {
		return this.facade.registerToPatientAppEvents();
	}
}
