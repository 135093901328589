<form
    [formGroup]="form"
    [class.read-only]="isReadOnly$ | async"
    fxFlex
    fxLayout="row"
    fxLayoutAlign="space-between"
    fxLayout.sm="column stretch"
    fxLayout.xs="column stretch"
    class="restoration-type-section"
>
    <div fxFlex="1 1 40%"
        fxLayout="column wrap"
        fxLayoutAlign="space-between stretch"
    >
        <rx-object-selector
            [id]="'restoration-type'"
            [label]="'ToothEditor.RestorationType' | translate"
            [isReadOnly]="isReadOnly$ | async"
            [formControl]="restorationTypeControl"
            [options]="restorationTypes$ | async"
            name="restorationTypeControl"
        ></rx-object-selector>
        <rx-object-selector
            [id]="'abutment-type'"
            [label]="'ToothEditor.AbutmentType' | translate"
            [isReadOnly]="isReadOnly$ | async"
            [formControl]="abutmentTypeControl"
            [options]="abutmentTypes$ | async"
            [isDisabled]="isScrewRetainedOrEmpty$ | async"
            cleanOnDisable
            name="abutmentTypeControl"
        ></rx-object-selector>
    </div>
    <div fxFlex="1 1 40%"
        fxLayout="column wrap"
        fxLayoutAlign="space-between stretch"
    >
        <rx-object-selector
            [id]="'abutment-material'"
            [label]="'ToothEditor.AbutmentMaterial' | translate"
            [isReadOnly]="isReadOnly$ | async"
            [formControl]="abutmentMaterialControl"
            [options]="materials$ | async"
            [isDisabled]="(isScrewRetainedOrEmpty$ | async) || (!(materials$ | async)?.length)"
            cleanOnDisable
            name="abutmentMaterialControl"
        ></rx-object-selector>
        <mat-slide-toggle
            [formControl]="isTiBasedControl"
            [id]="'ti-based'"
            [color]="'primary'"
            name="isTiBasedControl"
            labelPosition="before"
            [rxIsDisabled]="isTiBasedDisabled$ | async"
            (rxIsDisabledEvent)="switchOffIsTiBasedControl()"
        >
            {{ 'ToothEditor.TiBaseTitle' | translate }}
        </mat-slide-toggle>
    </div>
</form>
