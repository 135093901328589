import { Injectable } from '@angular/core';
import { SoftwareOptions } from '@shared/models/enums/enums';
import { ScannerRxConfig } from '@shared/models/scanner-rx-config';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HostPlatformService } from '@shared/services/host-platform.service';

@Injectable({ providedIn: 'root' })
export class SoftwareOptionsService {
	constructor(private shellQuery: ShellQuery, private hostPlatformService: HostPlatformService) {}

	static checkSoftwareOptions(relatedSoftwareOptions: number[], checkedSoftwareOptions: number[]): boolean {
		return checkedSoftwareOptions.some(value => relatedSoftwareOptions.includes(value));
	}

	hasScannerSoftwareOption$(softwareOption: SoftwareOptions): Observable<boolean> {
		return this.hasScannerSoftwareOptions$([softwareOption]);
	}

	hasScannerSoftwareOptions$(softwareOptions: SoftwareOptions[]): Observable<boolean> {
		return combineLatest([this.hostPlatformService.isScanner$, this.shellQuery.rxConfiguration$]).pipe(
			switchMap(([isHostPlatformScanner]) => {
				if (isHostPlatformScanner) {
					return this.shellQuery.scanner$.pipe(map(scanner => this.hasSoftwareOptions([scanner], softwareOptions)));
				}
				return this.shellQuery.scanners$.pipe(map(scanners => this.hasSoftwareOptions(scanners, softwareOptions)));
			})
		);
	}

	hasCompanySoftwareOption$(softwareOption: SoftwareOptions): Observable<boolean> {
		return this.hasCompanySoftwareOptions$([softwareOption]);
	}

	hasCompanySoftwareOptions$(softwareOptions: SoftwareOptions[]): Observable<boolean> {
		return this.shellQuery.rxConfiguration$.pipe(
			map(
				configuration =>
					configuration &&
					SoftwareOptionsService.checkSoftwareOptions(
						configuration.CompanyConfiguration.SoftwareOptionsForCompany,
						softwareOptions
					)
			)
		);
	}

	private hasSoftwareOptions(scanners: ScannerRxConfig[], softwareOptions: SoftwareOptions[]): boolean {
		return (
			scanners &&
			scanners.some(
				scanner => scanner && SoftwareOptionsService.checkSoftwareOptions(scanner.RelatedSoftwareOptions, softwareOptions)
			)
		);
	}
}
