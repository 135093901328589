<form
  [formGroup]="manufactureForm"
  fxLayout="row"
  fxLayoutAlign="space-between"
  fxLayout.sm="column"
  fxLayout.xs="column"
  [class.read-only]="isReadOnly"
>
    <rx-favorite-selector
            fxFlex="1 1 28%"
            [id]="'scan-body-manufacturer'"
            [label]="'ToothEditor.ScanBodyManufacturer' | translate"
            [isRequired]="shouldValidateForSend$ | async"
            [formControl]="scanBodyManufacturerControl"
            [isReadOnly]="isReadOnly"
            [options]="scanBodyManufacturerOptions$ | async"
            [favoriteName]="favoriteScanBody"
            name="scanBodyManufacturerControl"
            [enableSimpleMode]="isFavoritesHidden$ | async"
    ></rx-favorite-selector>
    <rx-favorite-selector
            fxFlex="1 1 28%"
            [id]="'implant-manufacturer'"
            [label]="'ToothEditor.ImplantManufacturer' | translate"
            [isReadOnly]="isReadOnly"
            [isRequired]="shouldValidateForSend$ | async"
            [formControl]="implantManufacturerControl"
            [options]="implantManufacturerOptions"
            [isDisabled]="!implantManufacturerOptions?.length"
            [favoriteName]="favoriteImplant"
            name="implantManufacturerControl"
            [enableSimpleMode]="isFavoritesHidden$ | async"
    ></rx-favorite-selector>
    <mat-form-field
        [attr.id]="'rx-mat-form-field-implant-type-scan-body'"
        fxFlex="1 1 28%"
        [class.disabled]="!implantTypeScanBodyOptions?.length"
        [class.read-only]="isReadOnly"
    >
        <mat-label [class.read-only-label]="isReadOnly && !implantTypeScanBodyControl.value">{{
            'ToothEditor.ImplantTypeScanBody' | translate
        }}</mat-label>
        <mat-select
            [id]="implantTypeScanBodyId"
            [value]="implantTypeScanBodyControl.value"
            [formControl]="implantTypeScanBodyControl"
            [required]="shouldValidateForSend$ | async"
            name="option"
            [errorStateMatcher]="matcher"
            [compareWith]="compareIds"
            [panelClass]="'implant-type-scan-body-panel'"
        >
            <mat-option
                [id]="option | mapper : generateOptionId : ('ToothEditor.ImplantTypeScanBody' | translate)"
                [class]="'rx-auto-simple-select-option-' + index"
                *ngFor="let option of implantTypeScanBodyOptions; let index = index"
                [value]="option"
            >
                {{ option.Name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</form>
