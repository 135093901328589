<label [ngClass]="{'mr-3': true, 'slide-toggle__label--disabled': disabled}">
    {{ "OrderSection.IcastOrthoModel" | translate }}
</label>

<mat-slide-toggle
    [checked]="checked"
    (change)="onCheck($event)"
    color="primary"
    name="isIcastOrthoModelSelected"
    [disabled]="disabled"
>
</mat-slide-toggle>
