import { Inject, Injectable } from '@angular/core';
import { CountryRegionRule, Region } from '@shared/models/country-region-rule';
import { COUNTRY_EMAIL_RULES, COUNTRY_REGIONS_RULES } from '@shared/rules/rules-tokens';
import { SoftwareOptionsService } from '@shared/services/software-options.service';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { HostPlatformService } from '@shared/services/host-platform.service';

@Injectable({
	providedIn: 'root'
})
export class AwarenessFacade {
	constructor(
		private shellQuery: ShellQuery,
		private softwareOptionsService: SoftwareOptionsService,
		@Inject(COUNTRY_REGIONS_RULES) private countries: CountryRegionRule,
		@Inject(COUNTRY_EMAIL_RULES) private countryCodesForEmailNotifications: ReadonlyArray<string>,
		private hostPlatformService: HostPlatformService
	) {}

	region$: Observable<Region> = this.shellQuery.companyCountryCode$.pipe(
		filter(code => !!code),
		map(code => this.getRegionByCountryCode(code)),
		shareReplay({ bufferSize: 1, refCount: true })
	);
	shouldNotifyCompanyByEmail$: Observable<boolean> = this.shellQuery.companyCountryCode$.pipe(
		filter(code => !!code),
		map(code => this.countryCodesForEmailNotifications.includes(code)),
		shareReplay({ bufferSize: 1, refCount: true })
	);
	linkToTheLearningCenter$: Observable<string> = this.region$.pipe(map(region => region.Link));

	isChina$ = this.region$.pipe(map(region => region.Name === 'China'));
	isHostPlatformScanner$ = this.hostPlatformService.isScanner$;
	showLearningCenterPart$ = combineLatest([this.isChina$, this.isHostPlatformScanner$]).pipe(
		map(([isChina, isHostPlatformScanner]) => !(isChina || isHostPlatformScanner))
	);

	get isProcedureFlow(): boolean {
		return this.shellQuery.isProcedureFlow;
	}

	private getRegionByCountryCode(countryCode: string): Region {
		return this.countries.Regions.find(region => {
			return region.Countries.find(country => country.Code.toLowerCase() === countryCode.toLowerCase());
		});
	}
}
