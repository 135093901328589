import { Injectable } from '@angular/core';
import { ApiService, IApiCall } from '@core/services/api.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class OrderApiService {
	constructor(private apiService: ApiService) { } 

	getAvailableCaseTypeIds({ contactId, companyId }: { contactId: number, companyId: number }): Observable<number[]> {
		const apiCallConfig: IApiCall = {
			selector: 'getAvailableCaseTypes',
			pathParams: { contactId },
			queryParams: { companyId }
		};
		return this.apiService.request(apiCallConfig);
	}

	getAvailableProcedureMapIds(contactId: number, companyId: number): Observable<number[]> {
		const apiCallConfig: IApiCall = {
			selector: 'getAvailableProcedureMaps',
			pathParams: { contactId },
			queryParams: { companyId }
		};
		return this.apiService.request(apiCallConfig);
	}
}
 