import { Component, forwardRef, Injector, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ObjectSelectorComponent } from '@shared/components/object-selector/object-selector.component';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConfigurationItem } from '@shared/models/configuration-item';

const MOULDS_VALUE_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => MouldsDropdownComponent),
	multi: true,
};

@Component({
	selector: 'rx-moulds-dropdown',
	templateUrl: './moulds-dropdown.component.html',
	styleUrls: ['./moulds-dropdown.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [MOULDS_VALUE_ACCESSOR]
})
export class MouldsDropdownComponent extends ObjectSelectorComponent implements OnInit, ControlValueAccessor {
	public selectedMouldId$: Observable<number>;

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit(): void {
		this.selectedMouldId$ = this.control.valueChanges.pipe(map(value => value?.Id));
	}
}
