import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseTypeFlowOrderComponent } from './containers/case-type-order/case-type-flow-order.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CaseTypeSelectorComponent } from './components/case-type-selector/case-type-selector.component';
import { SimpleFormFieldComponent } from '../../shared/components/simple-form-field/simple-form-field.component';
import { DueDatePickerComponent } from './components/due-date-picker/due-date-picker.component';
import { CurrentAlignerInputComponent } from './components/current-aligner-input/current-aligner-input.component';
import { CaseTypeChangedService } from './services/case-type-changed.service';
import { TreatmentStageChangedService } from './services/treatment-stage-changed.service';
import { IcastOrthoModelToggleComponent } from '@modules/order/components/icast-ortho-model-checkbox/icast-ortho-model-toggle.component';
import { ProcedureFlowOrderComponent } from './containers/procedure-order/procedure-flow-order.component';
import { OrderFormConfirmationService } from './services/order-form-confirmation.service';

@NgModule({
	declarations: [
		CaseTypeFlowOrderComponent,
		ProcedureFlowOrderComponent,
		CaseTypeSelectorComponent,
		SimpleFormFieldComponent,
		IcastOrthoModelToggleComponent,
		DueDatePickerComponent,
		CurrentAlignerInputComponent
	],
	imports: [
		CommonModule,
		SharedModule
	],
	exports: [
		CaseTypeFlowOrderComponent,
		ProcedureFlowOrderComponent
	],
	providers: [
		CaseTypeChangedService,
		TreatmentStageChangedService,
		OrderFormConfirmationService
	]
})
export class OrderModule { }
