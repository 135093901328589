import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface ScanOptionsState {
	isSleeveChecked: NewSleeveAttachedState;
	isSleeveCheckedSentByScanner: boolean;
	isNiriCaptureChecked: boolean;
	isPreTreatmentChecked: boolean;
}

export interface NewSleeveAttachedState {
	value: boolean;
	isValueChangedByUser: boolean;
}

export const createInitialState = (): ScanOptionsState => ({
	isSleeveChecked: { value: false, isValueChangedByUser: false},
	isSleeveCheckedSentByScanner: false,
	isNiriCaptureChecked: false,
	isPreTreatmentChecked: false,
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'scan-options', resettable: true })
export class ScanOptionsStore extends Store<ScanOptionsState> {
	constructor() {
		super(createInitialState());
	}

	reset() {
		const newState = { ...this.getValue(), isPreTreatmentChecked: createInitialState().isPreTreatmentChecked};
		this.update(newState);
	}
}
