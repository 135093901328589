import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DoctorComponent } from './containers/doctor/doctor.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DoctorNameComponent } from './components/doctor-name/doctor-name.component';
import { LicenseReadOnlyComponent } from './components/license-read-only/license-read-only.component';
import { LicenseInputComponent } from './components/license-input/license-input.component';

@NgModule({
	declarations: [
		DoctorComponent,
		DoctorNameComponent,
		LicenseReadOnlyComponent,
		LicenseInputComponent
	],
	imports: [
		CommonModule,
		SharedModule
	],
	exports: [
		DoctorComponent
	]
})
export class DoctorModule { }
