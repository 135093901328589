<header mat-dialog-title class="header">
	<p class="main-title">
		<ng-content select="[main-title]"></ng-content>
	</p>
	<p class="sub-title">
		<ng-content  select="[sub-title]"></ng-content>
	</p>
</header>
<div class="body" mat-dialog-content>
	<aside class="body-qr-part">
			<ng-content select="[aside]"></ng-content>
	</aside>
	<div class="body-main-part">
		<article class="body-main-part--content">
			<ng-content select="[article]"></ng-content>
		</article>
		<footer class="body-main-part--buttons">
			<button
					*ngFor="let button of buttons"
					mat-raised-button
					[mat-dialog-close]="button.matDialogClose"
					[ngClass]="{xl: true, 'ok-button': button.confirm }"
					[color]="button.confirm ? 'primary' : undefined"
			>
				{{ button.term | translate }}
			</button>
		</footer>
	</div>
</div>