import { Component, Injector, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { ControlValueAccessorConnector } from '@shared/base-classes/control-value-accessor-connector';

@Component({
	selector: 'rx-license-input',
	templateUrl: './license-input.component.html',
	styleUrls: ['./license-input.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: LicenseInputComponent, multi: true
	}]
})
export class LicenseInputComponent extends ControlValueAccessorConnector implements OnInit {

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit(): void {
		this.control.setValidators([Validators.required, Validators.maxLength(36)]);
	}
}
