<div class="rx-card" [class.read-only]="isReadOnly$ | async">
    <div class="rx-card-title">
        <div class="rx-title-row" fxLayout="row" fxLayoutAlign="start center">
            <div class="rx-card-title-text">{{ 'ScanOptions.ScanOptionsTitle' | translate }}:</div>
        </div>
    </div>
    <div class="rx-card-body" fxLayout="row wrap" fxLayoutAlign="space-between">
        <div
            fxLayoutAlign="start center"
            fxFlex="0 1 calc(50% - 15px)"
            *ngIf="isNiriCaptureVisible$ | async"
            class="rx-scan-option"
            id="scan-option-niri-toggle-forSonic">
            <label class="mr-3">{{ 'ScanOptions.NIRICapture' | translate }}:</label>
            <mat-slide-toggle
                [color]="'primary'"
                [disabled]="(isReadOnly$ | async) || (isRxTakenForScan$ | async)"
                (change)="onNiriCaptureUpdate($event.checked)"
                [checked]="isNiriCaptureChecked$ | async"
                [class.read-only]="isReadOnly$ | async"
            ></mat-slide-toggle>
        </div>
        <div
            fxLayoutAlign="start center"
            fxFlex="0 1 calc(50% - 15px)"
            *ngIf="isMultiBiteVisible"
            class="rx-scan-option"
            id="scan-option-multiBite-toggle-forSonic">
            <label class="mr-3">{{ 'ScanOptions.MultiBite' | translate }}:</label>
            <mat-slide-toggle
                [color]="'primary'"
                [disabled]="isReadOnly$ | async"
                (change)="updateScanOptionsMultiBite($event.checked)"
                [checked]="isMultiBiteSelected$ | async"
                [class.read-only]="isReadOnly$ | async"
            ></mat-slide-toggle>
        </div>
        <div
            fxLayoutAlign="start center"
            fxFlex="0 1 calc(50% - 15px)"
            *ngIf="isSleeveConfirmationVisible$ | async"
            class="rx-scan-option"
            id="scan-option-sleeve-toggle-forSonic">
            <label class="mr-3">{{ 'ScanOptions.NewSleeveAttached' | translate }}:</label>
            <mat-slide-toggle
                [color]="'primary'"
                [disabled]="isSleeveConfirmationReadOnly$ | async"
                (change)="onSleeveConfirmationUpdate($event.checked)"
                [checked]="isSleeveChecked$ | async"
                [class.read-only]="isReadOnly$ | async"
            ></mat-slide-toggle>
        </div>
        <div
            fxLayoutAlign="start center"
            fxFlex="0 1 calc(50% - 15px)"
            *ngIf="isPreTreatmentVisible$ | async"
            class="rx-scan-option"
            id="scan-option-pre-treatment-toggle-forSonic">
        <label class="mr-3">{{ 'ScanOptions.PreTreatmentScan' | translate }}:</label>
            <mat-slide-toggle
                [color]="'primary'"
                [disabled]="isPreTreatmentReadOnly$ | async"
                (change)="onPreTreatmentUpdate($event.checked)"
                [checked]="isPreTreatmentChecked$ | async"
                [class.read-only]="isReadOnly$ | async"
            ></mat-slide-toggle>
        </div>
        <div
            fxFlex="0 1 calc(50% - 15px)"
            fxLayoutAlign="start center"
            *ngIf="isDentureCopyScanVisible$ | async"
            class="rx-scan-option"
            id="scan-option-dentureCopyScan-toggle-forSonic">
            <label class="mr-3">{{ 'ScanOptions.DentureCopyScan' | translate }}:</label>
            <mat-slide-toggle
                color="primary"
                (change)="onDentureCopyScanUpdate($event.checked)"
                [checked]="isDentureCopyScan$ | async"
				[disabled]="(isRxTakenForScan$ | async)"
                [class.read-only]="isReadOnly$ | async"
			>
            </mat-slide-toggle>
        </div>
    </div>
    <rx-banner [type]="'notification'" *ngIf="(shouldShowMultiBiteInformationMessage$ | async)"
               [message]="'ScanOptions.MultiBiteInformationMessage' | translate"
               class="bottom-banner"
    ></rx-banner>
</div>
