import { Injectable } from '@angular/core';
import { ApiService, IApiCall } from '@core/services/api.service';
import { RxConfiguration } from '@shared/models/rx-configuration';
import { Response } from '@shared/models/response';
import { UserSettings } from '@shared/models/user-settings';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { RoleTypeEnum } from '@shared/models/role-type';
import { FeatureToggle } from '@shared/models/feature-toggle';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { EnvironmentParams } from '@shared/models/environment-params';
import { PatientModelDto } from '@shared/models/rx-models/interfaces/patient-model-dto';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { LoggerService } from '@core/services/logger/logger.service';
import { appSettingsPath } from '@shared/models/consts';
import { CompanyScanner } from '@shared/models/company-scanner';
import { ContactModel } from '@shared/models/rx-models/contact-model';

@Injectable({ providedIn: 'root' })
export class ShellApiService {
	constructor(private apiService: ApiService, private shellQuery: ShellQuery, private logger: LoggerService) {}

	getRxConfiguration({ companyId }: { companyId: number }): Observable<RxConfiguration> {
		const apiCallOptions: IApiCall = {
			selector: 'getRxConfig',
			pathParams: { companyId },
			queryParams: { isLabOrTechnician: false, LangCode: this.shellQuery.languageCode }
		};

		return this.apiService.request(apiCallOptions).pipe(map(response => (response as Response<RxConfiguration>).Result));
	}

	getProcedureFlowConfiguration({ companyId }: { companyId: number }): Observable<RxConfiguration> {
		const apiCallOptions: IApiCall = {
			selector: 'getProcedureFlowConfiguration',
			pathParams: { companyId },
			queryParams: { isLabOrTechnician: false, LangCode: this.shellQuery.languageCode }
		};

		return this.apiService.request(apiCallOptions).pipe(map(response => (response as Response<RxConfiguration>).Result));
	}

	getUserSettings({ contactId }: { contactId: number }): Observable<UserSettings> {
		const apiCallOptions: IApiCall = {
			selector: 'getUserSettings',
			pathParams: { contactId }
		};

		return this.apiService
			.request(apiCallOptions)
			.pipe(map(response => this.getParsedUserSettingsResponse({ response }) as UserSettings));
	}

	getCompaniesScanners({ companyId }: { companyId: number }): Observable<CompanyScanner[]> {
		const apiCallOptions: IApiCall = {
			selector: 'getCompaniesScanners',
			queryParams: { companyId }
		};

		return this.apiService.request(apiCallOptions) as Observable<CompanyScanner[]>;
	}

	getRxByOrderId({
		orderId,
		langCode,
		roleType,
		shouldAnonymize
	}: {
		orderId: number;
		langCode: string;
		roleType: RoleTypeEnum;
		shouldAnonymize?: boolean;
	}) {
		const apiCallOptions: IApiCall = {
			selector: 'getRxByOrderId',
			options: {
				body: {
					id: orderId,
					langCode,
					roleType,
					...(shouldAnonymize && { shouldAnonymize })
				}
			}
		};

		return this.apiService.request(apiCallOptions).pipe(map(response => (response as Response<RxModel>).Result));
	}

	getFeatureToggles(): Observable<FeatureToggle[]> {
		const apiCallOptions: IApiCall = {
			selector: 'getFeatureToggles'
		};

		return this.apiService.request(apiCallOptions).pipe(map(response => response as FeatureToggle[]));
	}

	getRxById({ rxId, shouldAnonymize }: { rxId: string; shouldAnonymize?: boolean }): Observable<RxModel> {
		const apiCallOptions: IApiCall = {
			selector: 'getRxById',
			pathParams: { rxId },
			queryParams: {
				...(shouldAnonymize && { shouldAnonymize })
			}
		};

		return this.apiService.request(apiCallOptions).pipe(
			tap(response => {
				const rxResponse = this.getParsedRxResponse({ response }) as RxModel;
				this.logger.logRXData(rxResponse, 'rx-app getRxById response: ', 'ApiService');
			}),
			map(response => this.getParsedRxResponse({ response }) as RxModel)
		);
	}

	getAppSettingsConfig(): Observable<EnvironmentParams> {
		return this.apiService.requestStaticFile(appSettingsPath).pipe(map(response => response as EnvironmentParams));
	}

	getPatientByUid({
		patientGuid,
		doctorId,
		companyId,
		isHostPlatformScanner
	}: {
		patientGuid: string;
		doctorId: number;
		companyId: number;
		isHostPlatformScanner: boolean;
	}): Observable<PatientModelDto> {
		const apiCallOptions: IApiCall = isHostPlatformScanner
			? {
					selector: 'getPatientByUid',
					pathParams: { patientGuid }
			  }
			: {
					selector: 'getPatientByUid',
					queryParams: {
						doctorId,
						companyId,
						id: patientGuid
					}
			  };

		return this.apiService
			.request(apiCallOptions)
			.pipe(map(response => (isHostPlatformScanner ? JSON.parse(response).Result : response.Result.Result) as PatientModelDto));
	}

	getRxsForPrint({ rxIds }: { rxIds: number[] }): Observable<RxModel[]> {
		const ids: string[] = rxIds?.map(rxId => `${rxId}`);

		// Required for PDF Creator - it can't access getRxsBulk on BFF
		// When printing only one Rx
		if (ids.length === 1) {
			if (this.shellQuery.orderId && this.shellQuery.orderId === rxIds[0]) {
				return this.shellQuery.rx$.pipe(
					filter(rx => rx?.Order?.ID === rxIds[0]),
					map(rx => [rx]),
					take(1)
				);
			}
		}

		return this.getRxsBulk(ids);
	}

	getLabContact(): Observable<ContactModel> {
		const apiCallOptions: IApiCall = {
			selector: 'getLabContact'
		};

		return this.apiService.request(apiCallOptions) as Observable<ContactModel>;
	}

	private getRxsBulk(ids: string[]): Observable<RxModel[]> {
		const apiCallOptions: IApiCall = {
			selector: 'getRxsBulk',
			options: {
				body: {
					ids,
					roleType: RoleTypeEnum.Doctor
				}
			}
		};

		return this.apiService.request(apiCallOptions).pipe(map(response => (response as Response<RxModel[]>).Result));
	}

	private getParsedUserSettingsResponse({ response }: { response: any }) {
		try {
			// MIDC
			return JSON.parse((response as Response<string>).Result);
		} catch {
			// SCANNER
			return JSON.parse(response).Result;
		}
	}

	private getParsedRxResponse({ response }: { response: any }) {
		try {
			// SCANNER
			const parsedResponse = JSON.parse(response);
			if (!!parsedResponse.Result) {
				throw new Error('not scanner response');
			}
			return parsedResponse;
		} catch {
			// MIDC
			return response.Result;
		}
	}
}
