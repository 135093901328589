import { ControlContainer, ControlValueAccessor, FormControl, FormControlDirective } from '@angular/forms';
import { Component, Injector, Input, ViewChild } from '@angular/core';

@Component({
	selector: '',
	template: ''
})
// tslint:disable-next-line: component-class-suffix
export class ControlValueAccessorConnector implements ControlValueAccessor {
	@ViewChild(FormControlDirective, { static: true }) formControlDirective: FormControlDirective;
	@Input() formControl: FormControl;
	@Input() formControlName: string;

	get control() {
		const formControlName = this.formControl || this.controlContainer.control.get(this.formControlName);
		return formControlName;
	}

	get controlContainer() {
		return this.injector.get(ControlContainer);
	}

	constructor(protected injector: Injector) {}

	registerOnTouched(fn: any): void {
		this.formControlDirective?.valueAccessor.registerOnTouched(fn);
	}

	registerOnChange(fn: any): void {
		this.formControlDirective?.valueAccessor.registerOnChange(fn);
	}

	writeValue(obj: any): void {
		this.formControlDirective?.valueAccessor.writeValue(obj);
	}

	setDisabledState(isDisabled: boolean): void {
		this.formControlDirective?.valueAccessor.setDisabledState(isDisabled);
	}

	setValue(value: any): void {
		this.control.setValue(value);
	}
}
