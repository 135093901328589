<rx-favorite-selector
        [id]="'implant-manufacturer'"
        [label]="'ToothEditor.ImplantManufacturer' | translate"
        [isReadOnly]="isReadOnly"
        [formControl]="implantManufacturerControl"
        [options]="implantManufacturerOptions$ | async"
        name="implantManufacturerControl"
        [favoriteName]="favoriteImplant"
        [enableSimpleMode]="isFavoritesHidden$ | async"
></rx-favorite-selector>
