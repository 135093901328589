import { Injectable } from '@angular/core';
import { RxNote } from '@modules/notes/models/rx-note';
import { NotesService } from '@modules/notes/services/notes.service';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { Observable, combineLatest } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { AlignTechNotesQuery } from '../state/aligntech-notes-query';
import { AlignTechNotesStore } from '../state/aligntech-notes-store';
import { ModelingNotesService } from './modeling-notes.service';

@Injectable({
	providedIn: 'root'
})
export class AligntechNotesService {
	constructor(
		private alignTechNotesStore: AlignTechNotesStore,
		private aligntechNotesQuery: AlignTechNotesQuery,
		private notesService: NotesService,
		private shellQuery: ShellQuery,
		private modelingNotesService: ModelingNotesService
	) {}

	updateAlignTechNotes(notes: RxNote[]) {
		this.alignTechNotesStore.update({
			alignTechNotesArray: [...notes],
			alignTechNotes: this.notesService.toNotesString(notes)
		});
	}

	setAlignTechNotesArray(): Observable<[string, RxNote[]]> {
		return combineLatest([this.shellQuery.alignTechNotes$, this.shellQuery.alignTechNotesArray$]).pipe(
			filter(([notes, notesArray]) => !!notes || !!notesArray),
			tap(([notes, notesArray]: [string, RxNote[]]) => {
				this.updateAlignTechNotes(notesArray ?? this.notesService.getNotesArray(notes));
			})
		);
	}

	createChangeUnitTypeNote(tooth: Tooth, updtTooth: Tooth) {
		this.modelingNotesService.getToothUnitTypeChangeNote$(tooth, updtTooth).pipe(take(1)).subscribe((newNote) => {
			const existingNotes = this.aligntechNotesQuery.alignTechNotesArray || [];

			this.updateAlignTechNotes([newNote, ...existingNotes]);
		});
	}
}
