import { DefaultCrownState } from '@modules/tooth-editor/models/default-crown-data';
import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export const createInitialState = (): DefaultCrownState => ({
	crownData: {}
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'default-crown-data', resettable: true })
export class DefaultCrownStore extends Store<DefaultCrownState> {
	constructor() {
		super(createInitialState());
	}

	setDefaultData(bridgeIndex: number, propertyValue: number, propertyName: string) {
		let crownData = this.getValue().crownData;
		let bridgeData = crownData[bridgeIndex];
		if (propertyValue == null || propertyName == null || (!!bridgeData && !!bridgeData[propertyName])) {
			return;
		}
		bridgeData = { ...bridgeData, [propertyName]: propertyValue };
		crownData = { ...crownData, [bridgeIndex]: bridgeData };
		this.update({ crownData });
	}

	setTouchedPointic(bridgeIndex: number, toothId: number) {
		let crownData = this.getValue().crownData;
		let bridgeData = crownData[bridgeIndex];
		if (toothId == null || bridgeData?.touchedPontics?.includes(toothId)) {
			return;
		}
		const touchedPontics = bridgeData?.touchedPontics ? [...bridgeData?.touchedPontics, toothId] : [toothId];
		bridgeData = { ...bridgeData, touchedPontics };
		crownData = { ...crownData, [bridgeIndex]: bridgeData };
		this.update({ crownData });
	}

	clearDefaultData(bridgeIndex: number) {
		const crownData = { ...this.getValue().crownData };
		if (!!crownData && !!crownData[bridgeIndex]) {
			delete crownData[bridgeIndex];
			this.update({ crownData });
		}
	}
}
