<mat-form-field fxFlex hideRequiredMarker [class.read-only-due-date-picker]="isReadOnly">
    <mat-label [class.read-only-label-no-value]="isReadOnly && !control.value"
        >{{ 'OrderSection.DueDate' | translate }}:
        <span class="mat-form-field-required-marker" *ngIf="isRequired && control.enabled">&#32;*</span>
    </mat-label>
    <input
        matInput
        [formControl]="control"
        [matDatepicker]="dueDateDatepicker"
        [min]="minimalAvailableDueDate"
        [max]="maxDueDate"
        autocomplete="off"
        [readonly]="true"
        name="dueDateDatepicker"
        ngDefaultControl
        [rxIsRequired]="isRequired"
        (click)="dueDateDatepicker.open()"
        [errorStateMatcher]="matcher"
        [class.read-only]="isReadOnly"
    />
    <mat-datepicker-toggle matSuffix [for]="dueDateDatepicker" *ngIf="!isReadOnly">
        <mat-icon class="color-icon" matDatepickerToggleIcon svgIcon="calendar_icon"></mat-icon>
        <mat-icon class="disabled-icon" matDatepickerToggleIcon svgIcon="calendar_icon_disabled"></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #dueDateDatepicker panelClass="rx-auto-due-datepicker-panel-class"> </mat-datepicker>
</mat-form-field>
