import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggerService } from '@core/services/logger/logger.service';
import { AppSettingsService } from '@core/services/app-settings/app-settings.service';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { delayedRetry } from '@core/operators/delayedRetry';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	public readonly name = 'HttpErrorInterceptor';

	private skipStatuses = new Set([400, 409]);

	constructor(private injector: Injector) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const retryIntervals = this.injector.get(AppSettingsService)?.retryIntervals ?? [];
		const staticFilesEndpoint = this.injector.get(ShellQuery)?.staticFilesEndpoint;

		if (request.url.startsWith(staticFilesEndpoint)) {
			return next.handle(request.clone()).pipe(catchError(err => this.logError(err)));
		}

		return next.handle(request.clone()).pipe(
			delayedRetry(retryIntervals, (error: Error) => {
				return error instanceof HttpErrorResponse && this.skipStatuses.has(error.status);
			}),
			catchError(err => this.logError(err))
		);
	}

	private logError(error: HttpErrorResponse) {
		const logger = this.injector.get(LoggerService);
		let extendedParameters: { [key: string]: string } = {
			url: error?.url,
			statusCode: error?.status.toString()
		};

		const traceId = error?.headers?.get('x-trace');
		if (traceId) {
			extendedParameters = { ...extendedParameters, traceId };
		}

		const exceptionMessage = error.error?.ExceptionMessage;
		if (exceptionMessage) {
			extendedParameters = { ...extendedParameters, exceptionMessage };
		}

		logger.error(error.message, {
			module: this.name,
			extendedParameters
		});

		return throwError(error);
	}
}
