<ng-container>
	<mat-form-field *ngIf="null | mapper : getFavoriteOptions.bind(this) as favoritesOptions"
					[attr.id]="'rx-mat-form-field-' + id" fxFlex [class.read-only]="isReadOnly" hideRequiredMarker>
		<mat-label [class.read-only-label]="isReadOnly && !control.value"
		>{{ label }}<span *ngIf="secondaryLabel">- {{secondaryLabel}}</span
		><span *ngIf="labelSeparator">{{ labelSeparator }}</span
		><span class="mat-form-field-required-marker" *ngIf="isRequired && control.enabled">&#32;*</span>
		</mat-label>
		<mat-select
			#favorites
			[id]="'rx-auto-select-' + id"
			[value]="control.value"
			[rxIsRequired]="isRequired"
			[formControl]="control"
			[rxIsDisabled]="isDisabled"
			(rxIsDisabledEvent)="handleDisableEvent($event)"
			[compareWith]="compareWith"
			[class.read-only]="isReadOnly"
			[panelClass]="panelClass"
			name="option"
			(openedChange)="setViewMode($event)"
		>
			<ng-container *ngIf="!enableFavoriteManagement">
				<mat-option	*ngFor="let option of options; let index = index"
						[value]="option"
						[id]="option | mapper : generateOptionId.bind(this)"
						[class]="'rx-auto-simple-select-option-' + index"
				>
					{{ propertyName ? option[propertyName] : option }}
				</mat-option>
			</ng-container>
			<div *ngIf="enableFavoriteManagement" fxFlex fxLayout="column" class="wrapper" >
				<div class="custom-panel" #panel>
					<ng-container *ngIf="!isInEditMode">
						<div class="favorites-panel">
							<mat-option
									*ngIf="!favoritesOptions.length"
									disabled="true"
									value=""
									class="rx-auto-simple-select-option no-favorites"
									[class.viewMode]="isInEditMode">
								<rx-star-checkbox [checked]="false" [disabled]="true">
									{{ 'Favorites.NoFavorites' | translate }}
								</rx-star-checkbox>
							</mat-option>
							<mat-option
									*ngFor="let option of favoritesOptions; let index = index; trackBy:isTheSame"
									[value]="option"
									[id]="option | mapper : generateOptionId.bind(this)"
									[class]="'rx-auto-simple-select-option-' + index"
							>
								<rx-star-checkbox [checked]="true" [disabled]="true">
									{{ propertyName ? option[propertyName] : option }}
								</rx-star-checkbox>
							</mat-option>
							<mat-divider></mat-divider>
						</div>
						<ng-container
								*ngFor="let option of (true | mapper : getOptions.bind(this)); let index = index; trackBy:isTheSame">
							<mat-option
									[value]="option"
									[id]="option | mapper : generateOptionId.bind(this)"
									[class]="'view-mode rx-auto-simple-select-option-' + index"
							>{{ propertyName ? option[propertyName] : option }}</mat-option>
						</ng-container>
					</ng-container>

					<!-- EDIT MODE -->

					<ng-container *ngIf="isInEditMode">
						<div class="favorites-panel">
							<mat-option
									*ngIf="!favoritesOptions.length"
									disabled="true"
									value=""
									class="rx-auto-simple-select-option no-favorites"
									[class.view-mode]="isInEditMode">{{ 'Favorites.NoFavorites' | translate }} </mat-option>
							<ng-container *ngFor="let option of favoritesOptions; let index = index; trackBy:isTheSame">
								<rx-star-checkbox [checked]="true"
												  [id]="option | mapper : generateOptionId.bind(this)"
												  (change)="changeSelection(option)">
									{{ propertyName ? option[propertyName] : option }}
								</rx-star-checkbox>
							</ng-container>
							<mat-divider></mat-divider>
						</div>
						<ng-container
								*ngFor="let option of (false | mapper : getOptions.bind(this)); let index = index; trackBy:isTheSame">
							<rx-star-checkbox
									[id]="option | mapper : generateOptionId.bind(this)"
									(change)="changeSelection(option)">
								{{ propertyName ? option[propertyName] : option }}
							</rx-star-checkbox>
						</ng-container>
					</ng-container>
				</div>
				<footer *ngIf="!isInEditMode" class="view" fxFlex fxLayout="row" fxLayoutAlign="flex-end center">
					<button mat-raised-button name='add' *ngIf="!favoritesOptions.length"
							(click)="switchMode()">{{ 'Favorites.Add' | translate }}</button>
					<button mat-raised-button name='edit' *ngIf="favoritesOptions.length"
							(click)="switchMode()">{{ 'Favorites.Edit' | translate }}</button>
				</footer>
				<footer *ngIf="isInEditMode" class="edit" fxFlex fxLayout="row" fxLayoutAlign="space-between center">
					<span>{{ 'Favorites.EditingModeFooter' | translate }}</span>
					<button mat-raised-button (click)="saveFavorites()">{{ 'Favorites.Done' | translate }}</button>
				</footer>
			</div>
		</mat-select>
		<mat-error *ngIf="isRequired && control.enabled">{{ 'Validation.RequiredField' | translate }}</mat-error>
		<mat-icon *ngIf="isCancellable && !!control?.value" id="cancel-selection" class="cancel-selection"
				  (click)="handleCancelSelection($event)">close
		</mat-icon>
	</mat-form-field>
</ng-container>