import {
	Component,
	ComponentFactoryResolver,
	EventEmitter,
	HostListener,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	SimpleChanges,
	ViewChild,
	ViewContainerRef,
	ViewEncapsulation
} from '@angular/core';
import { AppFacade } from './app.facade';
import { Router } from '@angular/router';
import { fromEvent, merge, Observable, of, pipe, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, delay, exhaustMap, filter, find, first, map, switchMap, switchMapTo, take, takeUntil, tap } from 'rxjs/operators';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { SaveRxService } from '@modules/rx-for-doctor/services/save-rx.service';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { PopUpActions } from '@shared/models/enums/popup-modal-actions.enum';
import { resetStores } from '@datorama/akita';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { LoggerService } from '@core/services/logger/logger.service';
import { PdfCreationService } from '@shared/services/pdf-creation.service';
import { RxComponents } from '@shared/models/rx-models/interfaces/rx-components-model';
import { RoleTypeEnum } from '@shared/models/role-type';
import { debounceDelay, emptyGuid } from '@shared/models/consts';
import { MatIconService } from '@shared/services/mat-icons.service';
import { PatientQuery } from '@modules/patient/state/patient-query';
import { localSettingsStoreName } from '@shared/store/localSettings/local-settings-store';
import { PrintOrientation } from '@shared/models/enums/enums';
import { ShellStore } from '@shared/store/shell/shell-store';
import { RxNote } from '@modules/notes/models/rx-note';
import { NotesService } from '@modules/notes/services/notes.service';
import { PRINT_IFRAME_ELEMENT, PrintRxComponent } from '@modules/print-rx/containers/print-rx/print-rx.component';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { OrderInformationModel } from '@shared/models/rx-models/interfaces/order-information-model';
import { NotesQuery } from '@modules/notes/state/notes-query';
import { RxRulesService } from '@shared/services/rx-rules-helper/rx-rules.service';
import { Version as rxAppVersion } from '../environments/app-version';
import { PatientAppIframeCommunicationService } from '@modules/patient/services/patient-app-iframe-communication.service';
import { ForceSaveToothEditorService } from '@shared/services/force-save-tooth-editor.service';
import { ToothEditorQuery } from '@modules/tooth-editor/state/tooth-editor-query';
import { ChangeUnitTypeInput } from '@shared/models/change-unit-type-input';
import { PrintService } from '@shared/services/print.service';
import { AligntechNotesService } from '@modules/aligntech-notes/services/aligntech-notes.service';
import { TeethNumberingSystem } from '@modules/teeth-diagram/models/teeth-numbering-system.enum';
import { TeethUnitType } from '@shared/models/rx-rules-json-interface';
import { HostPlatformService } from '@shared/services/host-platform.service';

@Component({
	selector: 'rx-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [AppFacade, PrintService],
	// tslint:disable-next-line:no-host-metadata-property
	// eslint-disable-next-line @angular-eslint/no-host-metadata-property
	host: { class: 'mat-typography' }
})
export class AppComponent extends BaseDestroyableComponent implements OnInit, OnChanges, OnDestroy {
	@ViewChild('printRxOutlet', { read: ViewContainerRef }) private printVcr: ViewContainerRef;

	constructor(
		private facade: AppFacade,
		private router: Router,
		private translateService: TranslateService,
		private saveRxService: SaveRxService,
		private logger: LoggerService,
		private pdfCreationService: PdfCreationService,
		private matIconService: MatIconService,
		private patientQuery: PatientQuery,
		private shellStore: ShellStore,
		public notesService: NotesService,
		private vcRef: ViewContainerRef,
		private cfr: ComponentFactoryResolver,
		private shellQuery: ShellQuery,
		private notesQuery: NotesQuery,
		private toothEditorQuery: ToothEditorQuery,
		private rxRulesService: RxRulesService,
		private patientAppIframeCommunicationService: PatientAppIframeCommunicationService,
		private forceSaveToothEditorService: ForceSaveToothEditorService,
		private printService: PrintService,
		private injector: Injector,
		private renderer: Renderer2,
		public aligntechNotesService: AligntechNotesService,
		private hostPlatformService: HostPlatformService
	) {
		super();
	}
	private readonly componentName = 'AppComponent';

	@Input() contactId: number;
	@Input() doctorId: number;
	@Input() companyId: number;
	@Input() languageCode: string;
	@Input() staticFilesEndpoint: string;
	@Input() apiEndpoint: string;
	@Input() scannerId: string;
	@Input() runMode: string;
	@Input() clientVersion: string;
	@Input() packageVersion: string;
	@Input() applicationMode: string;
	@Input() productType: string;
	@Input() rxId: string;
	@Input() isRxTakenForScan: boolean;
	@Input() orderId: string;
	@Input() saveRx: string;
	@Input() saveRxScanner: string;
	@Input() validateBeforeSend: string;
	@Input() isReadOnly: boolean;
	@Input() authToken: string;
	@Input() readOnlyRules: RxComponents;
	@Input() patientGuid: string;
	@Input() validationMode: string;
	@Input() userRole: RoleTypeEnum;
	@Input() clonedFromRxId: string;
	@Input() rxIdsForPrint: number[];
	@Input() triggerPrintRx: boolean;
	@Input() isHeadlessPrint: boolean;
	@Input() orientation = 'portrait';
	@Input() forceV0: boolean;
	@Input() isReturn: boolean;
	@Input() addNote: RxNote;
	@Input() changeUnitType: ChangeUnitTypeInput;
	@Input() orderInformation: OrderInformationModel;
	@Input() shouldAnonymizeRx: boolean;
	@Input() enableAllCaseTypesForAddRx: boolean;
	@Input() prepareRx: number;
	@Input() getUnitType: number;
	@Input() shouldCheckSleeveConfirmationCheckBox: boolean;
	@Input() forceDisplayTeethNumberingSystem: TeethNumberingSystem;
	@Output() sleeveConfirmationUpdated: EventEmitter<any> = new EventEmitter<any>();
	@Output() rxSaved: EventEmitter<any> = new EventEmitter<any>();
	@Output() rxSaveFailed: EventEmitter<any> = new EventEmitter<any>();
	@Output() rxValidated: EventEmitter<any> = new EventEmitter<any>();
	@Output() rxsPrinted: EventEmitter<any> = new EventEmitter<any>();
	@Output() rxsPrintedFailed: EventEmitter<any> = new EventEmitter<any>();
	@Output() pdfRequested: EventEmitter<any> = new EventEmitter<any>();
	@Output() noteAdded: EventEmitter<any> = new EventEmitter<any>();
	@Output() getLabNotes: EventEmitter<any> = new EventEmitter<any>();
	@Output() orderInformationUpdated: EventEmitter<RxModel> = new EventEmitter<RxModel>();
	@Output() rxUpdated: EventEmitter<RxModel> = new EventEmitter<RxModel>();
	@Output() rxPrepared: EventEmitter<RxModel> = new EventEmitter<RxModel>();
	@Output() getUnitTypeConfiguration: EventEmitter<TeethUnitType[]> = new EventEmitter<TeethUnitType[]>();

	@Input() autoDisplayPrintViewInTheDOM: boolean;

	printAlive$ = new Subject<void>();

	title = 'Rx App';
	currentApplicationVersion = environment.appVersion;
	configuration = [];
	showDevInfo: boolean;
	isDevelopmentPanelVisible$: Observable<boolean> = this.facade.isDevelopmentPanelVisible$;
	isScanner: boolean;
	isPrintPortrait = true;
	resizeHandlerTimeout: any;

	private trySaveRx$: Subject<boolean> = new Subject<boolean>();

	isHostPlatformScanner$: Observable<boolean> = this.hostPlatformService.isScanner$;

	@HostListener('window:resize', [])
	handleWindowResize() {
		clearTimeout(this.resizeHandlerTimeout);
		this.resizeHandlerTimeout = setTimeout(() => {
			document.activeElement.scrollIntoView();
		}, debounceDelay);
	}

	@HostListener('window:keydown', ['$event'])
	handleKeyDown(event: KeyboardEvent) {
		if (this.isReadOnly && event.key === 'Tab') {
			event.preventDefault();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		// Base url should be first for translation to work
		if (changes.staticFilesEndpoint) {
			const staticFilesEndpoint = changes.staticFilesEndpoint?.currentValue;
			this.facade.updateStaticFilesEndpoint({ staticFilesEndpoint });
		}
		if (changes.languageCode) {
			const languageCode = changes.languageCode?.currentValue;
			this.facade.updateLanguageCode(languageCode);
			this.translateService.reloadLang(languageCode);
			this.translateService.use(languageCode);
		}
		if (changes.apiEndpoint) {
			const apiEndpoint = changes.apiEndpoint?.currentValue;
			this.facade.updateApiEndpoint({ apiEndpoint });
		}
		if (changes.contactId) {
			const contactId = +changes.contactId?.currentValue;
			this.facade.updateContactId({ contactId });
		}
		if (changes.companyId) {
			const companyId = +changes.companyId?.currentValue;
			this.facade.updateCompanyId({ companyId });
		}
		if (changes.scannerId) {
			const scannerId = changes.scannerId?.currentValue;
			this.facade.updateScannerId({ scannerId });
		}
		if (changes.clientVersion) {
			const clientVersion = changes.clientVersion?.currentValue;
			this.facade.updateClientVersion({ clientVersion });
		}
		if (changes.packageVersion) {
			const packageVersion = changes.packageVersion?.currentValue;
			this.facade.updatePackageVersion({ packageVersion });
		}
		if (changes.runMode) {
			// const runMode = changes.runMode?.currentValue;
			// this.shellStore.update({ runMode }); // TODO: implement
		}
		if (changes.applicationMode) {
			const applicationMode = changes.applicationMode?.currentValue;
			this.facade.updateApplicationMode({ applicationMode });
		}
		if (changes.productType) {
			const productType = changes.productType?.currentValue;
			this.facade.updateProductType({ productType });
		}
		if (changes.doctorId) {
			// const doctorId = changes.doctorId?.currentValue;
			// this.shellStore.update({ doctorId }); // TODO: implement
			// TODO: DOCTOR ID should also come from MIDC (Scanner only for now). When it does, filter doctor by doctor ID in shell-query
		}
		if (changes.rxId) {
			const rxId = changes.rxId?.currentValue;
			this.facade.updateRxId({ rxId });
		}
		if (changes.orderId) {
			const orderId: string = changes.orderId?.currentValue || null;
			if (orderId) {
				this.logger.info(`rx-app opened with orderId = ${orderId}`, { module: this.componentName });
			}

			this.facade.updateOrderId({ orderId });
		}
		if (changes.isReadOnly) {
			const isReadOnly = changes.isReadOnly?.currentValue;
			this.facade.updateIsReadOnly({ isReadOnly });
		}
		if (changes.readOnlyRules) {
			const readOnlyRules = changes.readOnlyRules?.currentValue;
			this.facade.updateReadOnlyRules({ readOnlyRules });
		}
		if (changes.patientGuid) {
			const patientGuid = changes.patientGuid?.currentValue || null;
			if (patientGuid && patientGuid !== emptyGuid) {
				this.facade.loadPatient(patientGuid).pipe(take(1), takeUntil(this.componentAlive$)).subscribe();
			}
		}
		if (changes.saveRx) {
			this.trySaveRx$.next(false);
		}
		if (changes.saveRxScanner) {
			this.trySaveRx$.next(true);
		}
		if (changes.validateBeforeSend) {
			this.facade
				.validateBeforeSend()
				.pipe(takeUntil(this.componentAlive$))
				.subscribe(isValid => this.rxValidated.emit(isValid));
		}
		if (changes.isRxTakenForScan) {
			const isRxTakenForScan = changes.isRxTakenForScan?.currentValue;
			this.facade.updateIsRxTakenForScan({ isRxTakenForScan });
		}
		if (changes.authToken) {
			const authToken = changes.authToken?.currentValue;
			this.facade.updateAuthToken(authToken);
		}
		if (changes.validationMode) {
			const validationMode = changes.validationMode?.currentValue;
			this.facade.updateValidationMode(validationMode);
		}
		if (changes.userRole) {
			const userRole = changes.userRole?.currentValue;
			this.facade.updateUserRole(userRole);
		}
		if (changes.clonedFromRxId) {
			const clonedFromRxId = changes.clonedFromRxId?.currentValue;
			this.facade.updateClonedFromRxId(clonedFromRxId);
		}
		if (changes.isHeadlessPrint) {
			this.facade.updateIsHeadlessPrint({ isHeadlessPrint: changes.isHeadlessPrint?.currentValue });
		}
		if (changes.triggerPrintRx) {
			const triggerRXPrint = changes.triggerPrintRx?.currentValue;
			this.printAlive$.next();
			if (triggerRXPrint) {
				const rxIdsForPrint = changes.rxIdsForPrint?.currentValue || this.rxIdsForPrint;
				const printOrientation = this.orientation === 'landscape' ? PrintOrientation.landscape : PrintOrientation.portrait;
				this.facade.updatePrintOrientation({ printOrientation });
				if (rxIdsForPrint?.length > 0) {
					this.printRx({ rxIds: rxIdsForPrint });
				}
			}
		}
		if (changes.forceV0) {
			const forceV0 = changes.forceV0?.currentValue;
			this.facade.updateForceV0(forceV0);
		}
		if (changes.isReturn) {
			const isReturn = changes.isReturn?.currentValue;
			this.facade.updateIsReturn(isReturn);
		}
		if (changes.addNote) {
			const noteData = changes.addNote?.currentValue;
			const addedNote = this.notesService.addLabNote(noteData);
			this.noteAdded.emit(addedNote);
		}
		if (changes.orderInformation) {
			if (this.shellQuery.userRole !== RoleTypeEnum.Lab) {
				return;
			}
			const orderInformation = changes.orderInformation?.currentValue;
			this.facade.updateOrderInformation(orderInformation);
			this.orderInformationUpdated.next(this.saveRxService.prepareRxModel(this.shellQuery.rx));
		}
		if (changes.shouldAnonymizeRx) {
			this.facade.updateShouldAnonymizeRx(changes.shouldAnonymizeRx.currentValue);
		}
		if (changes.autoDisplayPrintViewInTheDOM) {
			this.shellStore.update({ autoDisplayPrintViewInTheDOM: changes.autoDisplayPrintViewInTheDOM.currentValue });
		}
		if (changes.enableAllCaseTypesForAddRx) {
			const enableAllCaseTypesForAddRx = changes.enableAllCaseTypesForAddRx.currentValue;
			this.facade.updateEnableAllCaseTypesForAddRx(enableAllCaseTypesForAddRx);
		}
		if (changes.changeUnitType) {
			const changeUnitType = changes.changeUnitType.currentValue;
			this.facade.changeUnitType(changeUnitType);
		}
		if (changes.prepareRx) {
			this.rxPrepared.emit(this.saveRxService.prepareRxModel(this.shellQuery.rx));
		}
		if (changes.shouldCheckSleeveConfirmationCheckBox) {
			const isSleeveChecked = changes.shouldCheckSleeveConfirmationCheckBox.currentValue;
			this.facade.updateIsSleeveCheckedSentByScanner(isSleeveChecked);
		}
		if (changes.forceDisplayTeethNumberingSystem) {
			const forceDisplayTeethNumberingSystem = changes.forceDisplayTeethNumberingSystem.currentValue;
			this.facade.updateForceDisplayTeethNumberingSystem(forceDisplayTeethNumberingSystem);
		}

		if (changes.getUnitType) {
			const config = this.rxRulesService.getUnitTypeConfiguration(this.shellQuery?.rx);
			this.getUnitTypeConfiguration.emit(config);
		}
	}

	ngOnInit(): void {
		this.router.initialNavigation(); // Manually triggering initial navigation for @angular/elements
		this.facade.loadConfigurationAndRxIfNeeded().pipe(takeUntil(this.componentAlive$)).subscribe();

		this.changePageAccordingToUserType().pipe(takeUntil(this.componentAlive$)).subscribe();

		this.facade.loadAssetsForCache();
		if (this.shellQuery.userRole === RoleTypeEnum.Lab) {
			this.setLabNotesListener();
		}
		if (this.hostPlatformService.isIteroModeling || this.shellQuery.userRole === RoleTypeEnum.Lab) {
			this.setRxLoadedListener();
		}

		this.pdfCreationListener();
		this.rxFormValidationListener();
		this.matIconService.registerMatIcons();
		this.notesService.setNotesArray().subscribe();
		this.aligntechNotesService.setAlignTechNotesArray().pipe(takeUntil(this.componentAlive$)).subscribe();
		this.translateSelectOptions().subscribe();
		this.logRxAppVersion();
		this.subscribeToTrackSleeveConfirmationUpdate();

		this.trySaveRx$
			.pipe(
				exhaustMap(isCalledByScanner => this.tryToSaveRx(isCalledByScanner)),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.patientAppIframeCommunicationService.patientAppPopupClose();
		this.unsubscribeFromPrint();
		super.ngOnDestroy();
		resetStores({ exclude: [localSettingsStoreName] });
	}

	subscribeToTrackSleeveConfirmationUpdate() {
		this.facade.sleeveCheckedChangedByUser$
			.pipe(
				tap((isSleeveChecked: boolean) => this.sleeveConfirmationUpdated.next(isSleeveChecked)),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	navigateToHome(): void {
		this.router.navigate(['']);
	}

	navigateToRxForDoctor(): void {
		void this.router.navigate([{ outlets: { rx: 'rx-for-doctor' } }]);
	}

	navigateToRxForLab(): void {
		void this.router.navigate([{ outlets: { rx: 'rx-for-lab' } }]);
	}

	navigateToRxForModeling(): void {
		void this.router.navigate([{ outlets: { rx: 'rx-for-modeling' } }]);
	}

	printRx({ rxIds }: { rxIds: number[] }): void {
		try {
			this.facade
				.getRxsBulk({ rxIds })
				.pipe(
					switchMap(() => this.printService.createPrintIframeElement()),
					switchMap(printRxIframe => {
						const compFactory = this.cfr.resolveComponentFactory(PrintRxComponent);
						const injector = Injector.create({
							providers: [{ provide: PRINT_IFRAME_ELEMENT, useValue: printRxIframe }],
							parent: this.injector
						});

						this.printVcr.clear();
						const compRef = this.printVcr.createComponent(compFactory, 0, injector);
						compRef.changeDetectorRef.detectChanges();

						this.renderer.appendChild(printRxIframe.contentDocument.body, compRef.location.nativeElement);

						return fromEvent(printRxIframe.contentWindow, 'afterprint');
					}),
					delay(0), // need to emit event asynchroniously because context was on print preview
					tap(() => this.rxsPrinted.emit()),
					catchError(err => {
						this.rxsPrintedFailed.emit(err);
						return of(err);
					}),
					takeUntil(merge(this.componentAlive$, this.printAlive$))
				)
				.subscribe();

			if (!this.shellQuery.apiEndpoint) {
				this.toggleOrientation();
			}
		} catch (err) {
			this.rxsPrintedFailed.next(err);
		}
	}

	mockGetRx(): void {
		this.facade.mockGetRx().pipe(takeUntil(this.componentAlive$)).subscribe();
	}

	toggleOrientation() {
		this.orientation = this.isPrintPortrait ? 'portrait' : 'landscape';
		this.facade.updatePrintOrientation({
			printOrientation: this.orientation === 'landscape' ? PrintOrientation.landscape : PrintOrientation.portrait
		});
	}

	rxFormValidationListener(): void {
		this.facade.validateRxForSave().subscribe();
	}

	pdfCreationListener(): void {
		this.pdfCreationService.pdfRequested.next = () => {
			this.tryToSaveRx(false)
				.pipe(
					tap(rxModel => {
						if (!rxModel) {
							return;
						}
						rxModel = rxModel.Result;
						const rx = { ...rxModel };
						this.shellStore.update({ rx });
						this.pdfRequested.emit(rxModel);
					})
				)
				.subscribe();
		};
	}

	callTryToSaveRx(): void {
		this.tryToSaveRx(false).subscribe();
	}

	tryToSaveRx(isCalledByScanner: boolean): Observable<any> {
		this.logger.info('RXLog - Attempting to save Rx.', { module: this.componentName, extendedParameters: { rxId: this.rxId } });

		if (document.activeElement instanceof HTMLElement) {
			document.activeElement.blur();
		}

		if (this.shellQuery.userRole === RoleTypeEnum.Lab && !this.hostPlatformService.isIteroLab) {
			// Sivan: In 21A we decided to remove the Edit option for lab - The Rx should be in read only.
			// TODO: clarify requirements
			// return this.saveRxForLab();
		}

		if (this.toothEditorQuery.isToothEditorOpen) {
			this.forceSaveToothEditorService.forceSaveToothEditor$.next(true);
		}

		if (this.shellQuery.isPatientAppDialogOpen) {
			this.logger.info('Confirmation dialog from PatientApp blocks Rx to be saved', {
				module: this.componentName,
				extendedParameters: { rxId: this.rxId }
			});

			if (isCalledByScanner) {
				this.rxSaved.next('stayInRx');
			}
			return of(false);
		}

		return this.facade.validateRxAndPatientAppForSave().pipe(
			take(1),
			switchMap(isValid => {
				if (isValid) {
					return this.saveRxService.saveRx().pipe(this.afterSaveRx());
				}

				if (isCalledByScanner) {
					return this.handleInvalidRxOnScanner();
				}

				return this.handleInvalidRx();
			}),
			takeUntil(this.componentAlive$)
		);
	}

	setLabNotesListener(): void {
		this.notesQuery.labNotes$
			.pipe(
				filter(notes => !!notes),
				tap(notes => this.getLabNotes.emit(notes)),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	setRxLoadedListener(): void {
		this.shellQuery.rx$
			.pipe(
				filter(rx => !!rx),
				tap(rx => this.rxUpdated.emit(this.saveRxService.prepareRxModel(rx))),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private logRxAppVersion() {
		if (rxAppVersion?.version) {
			this.logger.info(`Rx-App loaded - version: ${rxAppVersion?.version}`, {
				module: this.componentName,
				extendedParameters: { rxAppVersion: rxAppVersion?.version }
			});
		} else {
			this.logger.error('Rx-App version number Failed to load.', {
				module: this.componentName
			});
		}
	}

	private saveOnlyValidRx(): Observable<any> {
		return this.facade.validateRxForSave().pipe(
			take(1),
			switchMap(isValid => {
				if (isValid) {
					return this.saveRxService.saveRx().pipe(this.afterSaveRx());
				}
				return of(false);
			})
		);
	}

	private afterConflictPopUpClosed() {
		return pipe<Observable<PopUpActions>, Observable<boolean>, Observable<boolean>>(
			switchMap(popUpResult => {
				if (popUpResult === PopUpActions.Ok) {
					return this.patientQuery.patientGuid$.pipe(
						find(guid => !!guid),
						switchMapTo(this.saveOnlyValidRx())
					);
				}
				return of(false);
			}),
			first()
		);
	}

	private afterPopUpClosed() {
		return pipe<Observable<PopUpActions>, Observable<boolean>>(
			map(popUpResult => {
				if (popUpResult === PopUpActions.Ok) {
					this.rxSaved.next();
				}
				return false;
			})
		);
	}

	private handleInvalidRxOnScanner(): Observable<void> {
		if (this.patientQuery.isPatientInConflict) {
			this.facade.openPatientConflictPopUp();
			this.rxSaved.next('stayInRx');
		} else {
			this.rxSaved.next();
		}

		return of();
	}

	private handleInvalidRx(): Observable<boolean> {
		if (this.patientQuery.isPatientInConflict) {
			return this.facade.openPatientConflictPopUp().pipe(this.afterConflictPopUpClosed());
		}

		return this.facade.openValidationPopUp().pipe(this.afterPopUpClosed());
	}

	private translateSelectOptions(): Observable<TranslationChangeEvent> {
		return this.translateService.onLangChange.pipe(
			takeUntil(this.componentAlive$),
			tap(() => this.rxRulesService.translateSelectOptions())
		);
	}

	private changePageAccordingToUserType(): Observable<RoleTypeEnum> {
		return this.facade.userRole$.pipe(
			take(1),
			tap(userRole => {
				switch (userRole) {
					case RoleTypeEnum.Lab:
						this.navigateToRxForLab();
						break;
					case RoleTypeEnum.Technician:
						this.navigateToRxForModeling();
						break;
					default:
						this.navigateToRxForDoctor();
						break;
				}
			})
		);
	}

	private saveRxForLab(): Observable<any> {
		return this.saveRxService.saveRx().pipe(this.afterSaveRx());
	}

	private afterSaveRx() {
		return pipe<Observable<RxModel>, Observable<any>, Observable<RxModel | any>>(
			tap(rxModel => {
				let model;
				if (this.hostPlatformService.isScanner && typeof rxModel !== 'object') {
					// save from scanner
					model = JSON.parse(rxModel);
				} else if (!rxModel.Result) {
					// save from lab
					model = rxModel;
				} else {
					// save from doctor
					model = rxModel.Result;
				}
				this.logger.logRXData(model, 'RXLog - Rx Saved Successfully.', this.componentName);

				this.rxSaved.next(rxModel);
			}),
			catchError(error => {
				this.logger.error('RXLog - Error Saving Rx.', { module: this.componentName, extendedParameters: error });
				this.rxSaveFailed.next(error);

				return of(error);
			})
		);
	}
	private unsubscribeFromPrint() {
		this.printAlive$.next();
		this.printAlive$.complete();
	}
}
