
  <div class="dev-tools-editor rx-card-title">

    <h3>API configurations:</h3>

    <div class="rx-card-body" fxLayout="column" fxLayoutAlign="stretch">
        <mat-form-field appearance="standard">
        <mat-label>RX Configuration: </mat-label>
          <textarea
          [formControl]="rxConfigMockControl"
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
            placeholder="Add Rx configuration"
          ></textarea>
        </mat-form-field>
        <div>
          <button [cdkCopyToClipboard]="rxConfigMockControl.value">Copy to clipboard</button> and Edit <a href="https://jsoneditoronline.org/">Here</a></div>
    </div>
    <div class="rx-card-body" fxLayout="column" fxLayoutAlign="stretch">
        <mat-form-field appearance="standard">
        <mat-label>User Settings: </mat-label>
          <textarea
          [formControl]="userSettingsMockControl"
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
          ></textarea>
        </mat-form-field>
        <div>
          <button [cdkCopyToClipboard]="userSettingsMockControl.value">Copy to clipboard</button> and Edit 
          <a href="https://jsoneditoronline.org/">Here</a>
        </div>
    </div>
    <div class="rx-card-body" fxLayout="column" fxLayoutAlign="stretch">
      <mat-form-field appearance="standard">
      <mat-label>Feature Toggles: </mat-label>
        <textarea
        [formControl]="featureTogglesMockControl"
          matInput
          cdkTextareaAutosize
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
          placeholder="Add Rx Feature Toggles"
        ></textarea>
      </mat-form-field>
      <div>
        <button [cdkCopyToClipboard]="featureTogglesMockControl.value">Copy to clipboard</button> and Edit <a href="https://jsoneditoronline.org/">Here</a></div>
    </div>
    <div class="rx-card-body" fxLayout="column" fxLayoutAlign="stretch">
        <mat-form-field appearance="standard">
        <mat-label>Available CaseTypes: </mat-label>
          <textarea
          [formControl]="availableCaseTypesMockControl"
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
          ></textarea>
        </mat-form-field>
    </div>
    <hr>
    <h3>SDK inputs:</h3>
    <div class="rx-card-body" fxLayout="column" fxLayoutAlign="stretch">
        <mat-form-field appearance="standard">
        <mat-label>rx for doctor inputs: </mat-label>
          <textarea
          [formControl]="sdkInputsMockControl"
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
            placeholder="Add SDK inputs"
          ></textarea>
        </mat-form-field>
        <div>
          <button [cdkCopyToClipboard]="sdkInputsMockControl.value">Copy to clipboard</button> and Edit <a href="https://jsoneditoronline.org/">Here</a>
          <div>
            Json Examples:
            <div *ngFor="let example of sdkInputsExamples"><span>{{example}}</span><button (click)="applyRxForDoctors(example)">Apply</button></div>
          </div>
        </div>
    </div>
    <hr>
  </div>