import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { defaultLanguageCode } from '@shared/models/consts';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { TranslationLoaderService } from './services/translation-loader.service';
import { UserSettingsDateAdapter } from './services/user-settings-date-adapter';

@NgModule({
	imports: [
		TranslateModule.forRoot({
			defaultLanguage: defaultLanguageCode,
			loader: {
				provide: TranslateLoader,
				useClass: TranslationLoaderService,
				deps: [HttpClient, ShellQuery]
			}
		})
	],
	providers: [
		{ 
			provide: HTTP_INTERCEPTORS,
			useClass: LoaderInterceptor,
			 multi: true 
		},
		{ 
			provide: DateAdapter,
			useClass: UserSettingsDateAdapter,
			deps: [ShellQuery]
		}
	]
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		if (parentModule) {
			throw new Error(
				'CoreModule already loaded. Import it in AppModule only.'
			);
		}
	}
}
