import { ValueOf } from '@shared/utils/type-util';

type mapObjectCallback<T extends {} = {}> = (pair: [string, ValueOf<T>]) => ValueOf<T>;


export class ObjectUtils {
	static mapObject<T extends {} = {}>(obj: T, callback: mapObjectCallback<T>): T {
		return Object.entries<ValueOf<T>>(obj)
			.reduce((acc, [key, value]) => { acc[key] = callback([key, value]); return acc; }, {}) as T;
	}
}
