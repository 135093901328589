import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { Tooth } from "@modules/teeth-diagram/models/tooth";
import { AbutmentData } from "@modules/tooth-editor/models/tooth-editor";
import { ToothEditorFacade } from "@modules/tooth-editor/tooth-editor.facade";
import { BaseDestroyableComponent } from "@shared/base-classes/base-destroyable";
import { IdName } from "@shared/models/id-name";
import { debounceTime, distinctUntilChanged, takeUntil, tap } from "rxjs/operators";

@Component({
	selector: 'rx-abutment-section',
	styleUrls: ['./abutment-section.component.scss'],
	templateUrl: 'abutment-section.component.html',
	providers: [ToothEditorFacade],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbutmentSectionComponent extends BaseDestroyableComponent implements OnInit, OnChanges, AfterViewInit {
	@Input() toothClickedOn: Tooth;
	@Input() isReadOnly: boolean

	abutmentSectionForm = new FormGroup({
		abutmentType: new FormControl({ value: null, disabled: false }),
		abutmentMaterial: new FormControl({ value: null, disabled: false }),
		marginStyle: new FormControl({ value: null, disabled: false })
	});

	get abutmentTypeControl(): AbstractControl { return this.abutmentSectionForm.controls.abutmentType }
	get abutmentMaterialControl(): AbstractControl { return this.abutmentSectionForm.controls.abutmentMaterial }
	get marginStyleControl(): AbstractControl { return this.abutmentSectionForm.controls.marginStyle }

	abutmentTypeOptions: IdName[];
	abutmentMaterialOptions: IdName[];
	marginStyleOptions: IdName[];
	isAllAbutmentTypeOptionsShown: boolean;

	constructor(private toothEditorFacade: ToothEditorFacade) {
		super();
	}

	ngOnInit() {
		this.toothEditorFacade.allMaterialNames$.pipe(takeUntil(this.componentAlive$)).subscribe();
		this.toothEditorFacade.allAbutmentTypeOptions$.pipe(takeUntil(this.componentAlive$)).subscribe();
		this.toothEditorFacade.abutmentTypeOptions$.pipe(takeUntil(this.componentAlive$)).subscribe();
		this.abutmentTypeControl.valueChanges.pipe(tap((abutmentTypeConterolChanges: IdName) => {
			if (!abutmentTypeConterolChanges) {
				return;
			}
			this.updateAbutmentMaterialOptionsAndSelection({ abutmentTypeSelectionId: abutmentTypeConterolChanges.Id })
			this.updateMarginStyleOptionsAndSelection({ abutmentTypeSelectionId: abutmentTypeConterolChanges.Id });
		}), takeUntil(this.componentAlive$)).subscribe();
	}

	ngAfterViewInit() {
		this.updateAbutmentTypeOptionsAndSelection();
		this.abutmentSectionForm.valueChanges
			.pipe(
				debounceTime(200),
				distinctUntilChanged(),
				tap(() => this.updateToothFromForm()), takeUntil(this.componentAlive$)).subscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.toothClickedOn?.currentValue.ToothID !== changes.toothClickedOn.previousValue?.ToothID) {
			this.updateAbutmentTypeOptionsAndSelection();
		}
		if (changes.isReadOnly?.currentValue) {
			this.toothEditorFacade.setFormGroupReadOnlyMode(this.abutmentSectionForm);
		}
	}

	private updateAbutmentTypeOptionsAndSelection() {
		let abutmentTypeSelected = this.toothEditorFacade.abutmentTypeOptions?.find(abutmentTypeOption =>
			abutmentTypeOption.Id === this.toothClickedOn.AbutmentType);
		this.isAllAbutmentTypeOptionsShown = (!abutmentTypeSelected && !!this.toothClickedOn.AbutmentType);
		if (this.isAllAbutmentTypeOptionsShown) {
			this.abutmentTypeOptions = this.toothEditorFacade.allAbutmentTypeOptions;
			abutmentTypeSelected = this.toothEditorFacade.allAbutmentTypeOptions?.find(abutmentTypeOption =>
				abutmentTypeOption.Id === this.toothClickedOn.AbutmentType);
		} else {
			this.abutmentTypeOptions = this.toothEditorFacade.abutmentTypeOptions;
		}
		this.abutmentTypeControl.patchValue(abutmentTypeSelected);
	}

	private updateAbutmentMaterialOptionsAndSelection({ abutmentTypeSelectionId }: { abutmentTypeSelectionId: any }) {
		const materialIds: number[] = this.toothEditorFacade.abutmentTypes
			.find(abtType => abtType?.Id === abutmentTypeSelectionId).AbutmentMaterial;
		this.abutmentMaterialOptions = this.toothEditorFacade.allMaterialNames?.filter(material => materialIds.includes(material.Id));
		const abutmentMaterialSelected = this.abutmentMaterialOptions?.find(abutmentMaterialOption =>
			abutmentMaterialOption.Id === this.toothClickedOn.AbutmentMaterialId);
		this.abutmentMaterialControl.patchValue(abutmentMaterialSelected, { emitEvent: false });
	}

	private updateMarginStyleOptionsAndSelection({ abutmentTypeSelectionId }: { abutmentTypeSelectionId: any }) {
		this.marginStyleOptions = this.toothEditorFacade.abutmentTypes
			.find(abutmentType => abutmentType?.Id === abutmentTypeSelectionId)?.MarginStyle?.map(
				marginStyle => ({ Id: marginStyle, Name: this.toothEditorFacade.getAbutmentMarginStyleName({ marginDesignId: marginStyle }) }));
		const marginStyleSelected = this.marginStyleOptions?.find(marginStyleOption =>
			marginStyleOption.Id === this.toothClickedOn.AbutmentMarginStyleId);
		this.marginStyleControl.patchValue(marginStyleSelected, { emitEvent: false });
	}

	private updateToothFromForm() {
		const abutmentSectionFormChanges: AbutmentData = this.abutmentSectionForm.value;
		for (const [key, value] of Object.entries(abutmentSectionFormChanges)) {
			abutmentSectionFormChanges[key] = !!value ? abutmentSectionFormChanges[key]?.Id : null;
		}
		this.toothEditorFacade.updateTooth({ tooth: this.toothClickedOn, abutmentData: { ...abutmentSectionFormChanges } });
	}
}