import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UnitTypesInBridge } from '@modules/teeth-diagram/models/unit-type-in-bridge.enum';
import { PreDefinedNotesService } from '@modules/teeth-diagram/services/pre-defined-notes.service';
import { TeethDiagramFacade } from '@modules/teeth-diagram/teeth-diagram.facade';
import { TranslateService } from '@ngx-translate/core';
import { BridgeService } from '@shared/services/bridge.service';
import { ToothEditorService } from '@shared/services/tooth-editor.service';
import { TeethDiagramBaseDirective } from '@modules/teeth-diagram/containers/teeth-diagram/base/teeth-diagram-base.directive';
import { TOOTH_EDITOR_PROVIDERS } from '@modules/tooth-editor/injection/providers';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { isDentureRemovableFullDentureImplantBased } from '@shared/utils/procedure-type-util';
import { ToothEditorSharingService } from '@modules/tooth-editor/services/tooth-editor-sharing.service';
import { AligntechNotesService } from '@modules/aligntech-notes/services/aligntech-notes.service';

@Component({
	selector: 'rx-teeth-diagram-v1',
	templateUrl: './teeth-diagram-v1.component.html',
	styleUrls: ['../base/teeth-diagram-base.component.scss'],
	providers: [TeethDiagramFacade, TOOTH_EDITOR_PROVIDERS]
})
export class TeethDiagramV1Component extends TeethDiagramBaseDirective implements OnInit, AfterViewInit {
	constructor(
		teethDiagramFacade: TeethDiagramFacade,
		toothEditorService: ToothEditorService,
		bridgeService: BridgeService,
		translateService: TranslateService,
		preDefinedNotesService: PreDefinedNotesService,
		private toothEditorSharingService: ToothEditorSharingService,
		aligntechNotesService: AligntechNotesService
	) {
		super(teethDiagramFacade, toothEditorService, bridgeService, translateService, preDefinedNotesService, aligntechNotesService);
	}

	isImmediatelyChangeUnitType(unitTypeInBridge: number): boolean {
		return [UnitTypesInBridge.Missing].includes(unitTypeInBridge);
	}

	getOverriddenUnitType(tooth: Tooth): UnitTypes {
		if (this.isFullDentureImplantBased() && tooth.UnitTypeID === UnitTypes.Regular) {
			return UnitTypes.Missing;
		}
		return null;
	}

	handleUnitTypeSelection({ unitType, tooth }: { unitType: number; tooth: Tooth }) {
		if (this.isFullDentureImplantBased() && unitType === UnitTypes.Missing) {
			unitType = UnitTypes.Regular;
		}
		tooth = this.toothEditorSharingService.resetToothIfNeeded(tooth, unitType);

		super.handleUnitTypeSelection({ unitType, tooth });
	}

	handleUnitTypeInBridgeSelection({ unitTypeInBridge, tooth }: { unitTypeInBridge: number; tooth: Tooth }) {
		const convertedUnitType = this.bridgeService.convertFromBridgeToothUnitType({ unitTypeInBridge });
		tooth = this.toothEditorSharingService.resetToothIfNeeded(tooth, convertedUnitType);
		super.handleUnitTypeInBridgeSelection({ unitTypeInBridge, tooth });
	}

	handleToothClick({ tooth, event, isReadOnly }: { tooth: Tooth; event: any; isReadOnly?: boolean }): void {
		const overriddenUnitType = this.getOverriddenUnitType(tooth);
		super.handleToothClick({ tooth, event, isReadOnly, overriddenUnitType });
	}

	private isFullDentureImplantBased(): boolean {
		if (!this.rxForPrint) {
			return this.teethDiagramFacade.isFullDentureImplantBased();
		}
		const procedureId = this.rxForPrint.Order?.ProcedureId;
		const typeId = this.rxForPrint.Order?.ProcedureTypeId;
		return isDentureRemovableFullDentureImplantBased(procedureId, typeId);
	}
}
