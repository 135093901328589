<div class="rx-card" [class.read-only]="isReadOnly$ | async">
    <div class="rx-card-title notes-title">
        <div class="rx-title-row" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="aligntech-notes-title rx-card-title-text">{{ 'AligntechNotes.AligntechNotes' | translate }}</div>
        </div>
    </div>
    <div class="rx-card-body notes-card-body">
        <form [formGroup]="alignTechNotesForm">
            <div class="create-note" *ngIf="!(this.isReadOnly$ | async)">
                <mat-form-field appearance="outline" fxFlex="flex-grow">
                    <textarea
                        id="new-note"
                        [formControl]="newNote"
                        (focus)="focusNotes($event)"
                        matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="3"
                        [placeholder]="'NotesSection.AddNote' | translate"
                    >
                    </textarea>
                </mat-form-field>
            </div>
            <ng-container formArrayName="alignTechNotes">
                <div
                    *ngFor="let note of alignTechNotes.controls; index as i"
                    fxLayout="row"
                    class="mt-4 note-row"
                    [class.not-editable]="!(i | mapper: canDelete.bind(this))"
                    fxLayoutAlign="start center"
                >
                    <div fxFlex fxLayout="column" fxLayoutAlign="center stretch">
                        <div
                            *ngIf="note.value.createdByName || note.value.dateCreated"
                            id="note-title"
                            class="note-title note-title-position"
                        >
                            <span [id]="'note-created-by-name-' + i">{{ note.value.createdByName }}</span>
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <span class="date-and-time mr-4">
                                    {{ note.value.dateCreated | date: (dateFormat$ | async) }}
                                    <span class="line-separator">|</span>
                                    {{ note.value.dateCreated | date: 'shortTime' }}</span
                                >
                                <span>
                                    <span *ngIf="i | mapper: canEdit.bind(this)" [id]="'edit-note-' + i" class="edit-note" (click)="editNote(i)">
                                        <mat-icon class="color-icon" svgIcon="pencil_icon"></mat-icon>
                                    </span>
                                    <span *ngIf="i | mapper: canDelete.bind(this)" [id]="'remove-note-' + i" class="remove-note" (click)="removeNote(i)">
                                        <mat-icon class="color-icon" svgIcon="trash_icon"></mat-icon>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="note-content">
                            <mat-form-field appearance="standard" fxFlex="flex-grow" [class.disabled]="!isInEditableMode(i)">
                                <ng-container [formGroup]="note">
                                    <textarea
                                        ngDefaultControl
                                        #noteinput
                                        (focus)="focusNotes($event)"
                                        (blur)="blurNotes()"
                                        formControlName="content"
                                        [id]="'notes-input-' + i"
                                        class="notes-input"
                                        matInput
                                        autocomplete="off"
                                        cdkTextareaAutosize
                                        cdkAutosizeMinRows="1"
                                        [placeholder]="'NotesSection.AddNotes' | translate"
                                        [disabled]="!isInEditableMode(i)"
                                    ></textarea>
                                </ng-container>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </ng-container>
        </form>
    </div>
</div>
