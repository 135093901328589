import { Inject, Injectable } from '@angular/core';
import { RestorationConfiguration } from '@shared/models/restoration-configuration-interface';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { distinctUntilChanged, map, shareReplay, withLatestFrom } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ConfigurationItem } from '@shared/models/configuration-item';
import { RESTORATION_CONFIGURATION } from '@shared/rules/rules-tokens';
import { RestorationTypeForm } from '@modules/tooth-editor/models/restoration-type-from';
import { ToothEditorQuery } from '@modules/tooth-editor/state/tooth-editor-query';
import { LoggerService } from '@core/services/logger/logger.service';
import { ToothEditorUpdatingService } from '@modules/tooth-editor/services/tooth-editor-updating.service';
import { IdName } from '@shared/models/id-name';
import { getNewTooth } from '@modules/teeth-diagram/models/tooth';

@Injectable()
export class RestorationTypeFacade {
	private readonly facadeName = 'RestorationTypeFacade';

	abutmentTypes$: Observable<ConfigurationItem[]> = this.shellQuery.rxConfiguration$.pipe(
		map(rxConfiguration => rxConfiguration.AbutmentTypes),
		map(abutmentTypes => abutmentTypes.filter(x => this.configuration.DisplayedAbutmentTypeIds.includes(x.Id))),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	restorationTypes$: Observable<ConfigurationItem[]> = this.shellQuery.restorationTypes$;
	restorationTypeId$: Observable<number> = this.toothEditorQuery.restorationTypeId$;
	abutmentTypeId$: Observable<number> = this.toothEditorQuery.abutmentTypeId$;

	materials$: Observable<IdName[]> = this.toothEditorQuery.abutmentTypeId$.pipe(
		distinctUntilChanged(),
		withLatestFrom(this.shellQuery.materials$),
		map(([abutmentTypeId, materials]) =>
			this.configuration.AvailableMaterials.find(x => x.AbutmentTypeIds.includes(abutmentTypeId))
				?.MaterialIds.map(materialId => materials.find(material => material.Id === materialId))
				.filter(x => x)
		)
	);

	restorationTypeForm$: Observable<RestorationTypeForm> = this.toothEditorQuery.toothClickedOn$.pipe(
		withLatestFrom(this.shellQuery.rxConfiguration$),
		map(([tooth, rxConfiguration]) => {
			const emptyForm = {
				restorationType: null,
				abutmentType: null,
				abutmentMaterial: null,
				isTiBased: false
			};

			if (!tooth.ImplantBasedRestorationTypeId) {
				return emptyForm;
			}
			const abutmentTypeMap = this.configuration.AbutmentTypeMappings.find(x => x.TargetId === tooth.AbutmentType);
			if (tooth.AbutmentType && !abutmentTypeMap) {
				this.logger.error(`Didn't find abutmentTypeMapping with TargetId == ${tooth.AbutmentType}`, { module: this.facadeName });
				return emptyForm;
			}
			return {
				restorationType: rxConfiguration.RestorationTypes?.find(x => x.Id === tooth.ImplantBasedRestorationTypeId),
				abutmentType: abutmentTypeMap ? rxConfiguration.AbutmentTypes?.find(x => x.Id === abutmentTypeMap.VisibleId) : null,
				abutmentMaterial: rxConfiguration.Materials?.find(x => x.Id === tooth.AbutmentMaterialId),
				isTiBased: abutmentTypeMap?.VisibleIsTiBase
			};
		})
	);

	constructor(
		@Inject(RESTORATION_CONFIGURATION) private configuration: RestorationConfiguration,
		private shellQuery: ShellQuery,
		private toothEditorQuery: ToothEditorQuery,
		private logger: LoggerService,
		private updatingService: ToothEditorUpdatingService
	) {}

	updateTooth(formValue: RestorationTypeForm): void {
		const isTiBased = formValue.isTiBased ?? false;
		const abutmentTypeMap = this.configuration.AbutmentTypeMappings.find(
			x => x.VisibleId === formValue.abutmentType?.Id && x.VisibleIsTiBase === isTiBased
		);
		const tooth = this.toothEditorQuery.toothClickedOn;
		let abutmentMaterialId = formValue.abutmentMaterial?.Id;
		if (
			tooth.AbutmentType !== abutmentTypeMap?.TargetId &&
			!this.configuration.AvailableMaterials.find(x => x.AbutmentTypeIds.includes(abutmentTypeMap?.TargetId))?.MaterialIds.includes(
				formValue.abutmentMaterial?.Id
			)
		) {
			abutmentMaterialId = null;
		}

		tooth.ImplantBasedRestorationTypeId = formValue.restorationType?.Id ?? getNewTooth().ImplantBasedRestorationTypeId;
		tooth.AbutmentType = abutmentTypeMap?.TargetId;
		tooth.AbutmentMaterialId = abutmentMaterialId;

		this.updatingService.updateSelectedTooth(tooth);
	}
}
