import { Component, OnInit, Input } from '@angular/core';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { Observable } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { ScanOptionsFacade } from '../../scan-options.facade';


@Component({
	selector: 'rx-scan-options',
	templateUrl: './scan-options.component.html',
	styleUrls: ['./scan-options.component.scss'],
	providers: [ScanOptionsFacade]
})
export class ScanOptionsComponent extends BaseDestroyableComponent implements OnInit {
	isNiriCaptureChecked$: Observable<boolean> = this.facade.isNiriCaptureChecked$;
	isNiriCaptureVisible$: Observable<boolean> = this.facade.isNiriCaptureVisible$;
	isSleeveChecked$: Observable<boolean> = this.facade.isSleeveChecked$;
	isSleeveConfirmationReadOnly$: Observable<boolean> = this.facade.isSleeveConfirmationReadOnly$;
	isSleeveConfirmationVisible$: Observable<boolean> = this.facade.isSleeveConfirmationVisible$;
	isReadOnly$: Observable<boolean> = this.facade.isReadOnly$;
	isPreTreatmentChecked$: Observable<boolean> = this.facade.isPreTreatmentChecked$;
	isPreTreatmentReadOnly$: Observable<boolean> = this.facade.isPreTreatmentReadOnly$;
	isPreTreatmentVisible$: Observable<boolean> = this.facade.isPreTreatmentVisible$;
	isMultiBiteSelected$: Observable<boolean> = this.facade.isMultiBiteSelected$.pipe(
		shareReplay({ bufferSize: 1, refCount: true })
	);
	isDentureCopyScan$: Observable<boolean> = this.facade.isDentureCopyScan$;
	isDentureCopyScanVisible$: Observable<boolean> = this.facade.isDentureCopyScanVisible$;
	isRxTakenForScan$ = this.facade.isRxTakenForScan$;
	shouldShowMultiBiteInformationMessage$: Observable<boolean> = this.facade.shouldShowMultiBiteInformationMessage$
		.pipe(
			shareReplay({ bufferSize: 1, refCount: true })
	);

	@Input() isMultiBiteVisible: boolean;

	constructor(private facade: ScanOptionsFacade) {
		super();
	}

	ngOnInit(): void {
		this.facade
			.loadNiri()
			.pipe(takeUntil(this.componentAlive$))
			.subscribe();
		this.facade
			.loadSleeveConfirmation()
			.pipe(takeUntil(this.componentAlive$))
			.subscribe();
		this.facade
			.loadPreTreatment()
			.pipe(takeUntil(this.componentAlive$))
			.subscribe();
	}
	
	onNiriCaptureUpdate(isNiriCaptureChecked: boolean): void {
		this.facade.updateNiriCapture({ isNiriCaptureChecked });
	}

	onSleeveConfirmationUpdate(isSleeveChecked: boolean) {
		this.facade.updateSleeveConfirmation(isSleeveChecked);
	}

	onPreTreatmentUpdate(isPreTreatmentChecked: boolean) {
		this.facade.updatePreTreatmentConfirmation({ isPreTreatmentChecked });
	}

	updateScanOptionsMultiBite(checked: boolean): void {
		this.facade.updateScanOptionsMultiBite(checked);
	}

	onDentureCopyScanUpdate(isDentureCopyScan: boolean): void {
		this.facade.updateScanOptionsDentureCopyScan(isDentureCopyScan);
	}

	get isProcedureFlow(): boolean {
		return this.facade.isProcedureFlow;
	}
}
