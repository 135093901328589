import { Injectable } from '@angular/core';
import { IdName } from '@shared/models/id-name';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { OrderQuery } from './state/order-query';
import { OrderStore } from './state/order-store';
import { combineQueries } from '@datorama/akita';
import { CaseTypeEnum } from '@modules/order/models/case-type.enum';
import { LoggerService } from '@core/services/logger/logger.service';

@Injectable()
export class OrderFacadeBase {
	protected static get componentName() { return 'OrderFacadeBase'; }
	availableLabs$: Observable<IdName[]> = this.query.availableLabs$;
	minimalAvailableDueDate$: Observable<Date> = combineLatest([this.query.minimalAvailableDueDate$, this.shellQuery.isRxSent$]).pipe(
		filter(([, isRxSent]) => !isRxSent),
		map(([minimalDate]) => minimalDate)
	);
	isReadOnly$: Observable<boolean> = this.shellQuery.getReadOnlyState('order');

	constructor(
		protected store: OrderStore,
		protected query: OrderQuery,
		protected shellQuery: ShellQuery,
		protected logger: LoggerService
	) {}

	getDirectToLab(): Observable<boolean> {
		return this.shellQuery.directToLab$.pipe(
			tap((directToLab = false) => {
				this.logger.debug(`directToLab updated: ${directToLab}`, { module: OrderFacadeBase.componentName });
				this.store.update({ directToLab });
			})
		);
	}

	setDirectToLab(): Observable<boolean> {
		return combineQueries([this.shellQuery.directToLabs$, this.query.caseType$, this.query.sendTo$]).pipe(
			filter(([directToLabs, caseType]) => !!directToLabs && !!caseType),
			tap(([, caseType, sendTo]) => {
				this.logger.debug(`caseType: ${caseType.Name}, sendTo: ${sendTo?.Name}`, { module: OrderFacadeBase.componentName });
			}),
			map(([directToLabs, caseType, sendTo]) => {
				if (!sendTo) {
					return false;
				}
				return [CaseTypeEnum.Restorative].includes(caseType?.Id) && directToLabs.some(directToLab => directToLab.Id === sendTo.Id);
			}),
			tap(directToLab => {
				this.logger.debug(`directToLab: ${directToLab}`, { module: OrderFacadeBase.componentName });
			}),
			filter(directToLab => directToLab !== this.query.directToLab),
			tap(directToLab => {
				this.store.update({ directToLab });
			})
		);
	}
}
