import { Component } from '@angular/core';
import { RoleTypeEnum } from '@shared/models/role-type';
import { RxForLabFacade } from '../rx-for-lab.facade';

@Component({
	selector: 'rx-for-lab',
	templateUrl: './rx-for-lab.component.html',
	styles: [
		`
			.lab-container {
				height: calc(100vh - 138px);
			}
		`
	],
	providers: [RxForLabFacade]
})
export class RxForLabComponent {
	role: RoleTypeEnum = RoleTypeEnum.Lab;

	constructor(private facade: RxForLabFacade) {}

	get isProcedureFlow(): boolean {
		return this.facade.isProcedureFlow;
	}

	get isIteroLab(): boolean {
		return this.facade.isIteroLab;
	}
}
