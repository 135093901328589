import { Component, Injector, OnInit, Input, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SavingFavoritesService } from '@shared/services/savingFavorites.service';
import { IdName } from '../../models/id-name';
import { ObjectSelectorComponent } from '../object-selector/object-selector.component';
import { FavoritesEnum } from '@shared/models/enums/favorites.enum';

@Component({
	selector: 'rx-favorite-selector',
	templateUrl: './favorite-selector.component.html',
	styleUrls: ['./favorite-selector.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: FavoriteSelectorComponent,
			multi: true
		}
	]
})
export class FavoriteSelectorComponent extends ObjectSelectorComponent implements OnInit, OnChanges {
	@Input()
	favoriteName: FavoritesEnum;
	@Input()
	enableSimpleMode: boolean;

	@Input()
	minimumItemsCountToEnable = 7;
	selectedValues: Set<string>;
	@ViewChild('panel') favoritesPanel: ElementRef<HTMLInputElement>;
	private temporarySelectedValues: Set<string> = new Set<string>();
	isInEditMode = false;
	enableFavoriteManagement = true;
	constructor(injector: Injector, private savingFavoritesService: SavingFavoritesService) {
		super(injector);
	}

	ngOnInit(): void {
		if (!this.favoriteName) {
			this.enableSimpleMode = true;
			return;
		}
		if (this.enableSimpleMode) {
			return;
		}

		this.selectedValues = this.getSavedOptions();
		this.temporarySelectedValues = new Set(this.selectedValues);
	}

	ngOnChanges(changes: SimpleChanges) {
		const enableFavorites = !this.enableSimpleMode && this.options?.length >= this.minimumItemsCountToEnable;
		const basePanelClass = enableFavorites ? 'favorites' : 'eight-not-favorite-options-panel';
		this.enableFavoriteManagement = enableFavorites;

		const panelClass = changes.panelClass?.currentValue;
		if (!panelClass) {
			this.panelClass = basePanelClass;
		} else if (typeof panelClass === 'string') {
			this.panelClass = [basePanelClass, panelClass];
		} else if (panelClass instanceof Array) {
			this.panelClass = [basePanelClass, ...panelClass];
		} else if (panelClass instanceof Set) {
			this.panelClass = [basePanelClass, ...panelClass];
		} else if (typeof panelClass === 'object') {
			this.panelClass = { [basePanelClass]: true, ...panelClass };
		}
	}

	switchMode() {
		this.isInEditMode = !this.isInEditMode;
	}

	setViewMode(isWindowOpened: boolean) {
		if (!isWindowOpened) {
			this.isInEditMode = false;
		}
	}

	changeSelection(favorite: IdName) {
		if (this.temporarySelectedValues.has(favorite.Id)) {
			this.temporarySelectedValues.delete(favorite.Id);
		} else {
			this.temporarySelectedValues.add(favorite.Id);
		}
	}

	isTheSame(index: number, item: IdName) {
		return item.Id;
	}

	getFavoriteOptions() {
		return this.options?.filter(option => this.selectedValues?.has(option.Id)) ?? [];
	}

	getOptions(viewOptions: boolean) {
		if (viewOptions) {
			return this.isInEditMode ? null : this.options?.filter(option => !this.selectedValues?.has(option.Id));
		}
		return this.isInEditMode ? this.options?.filter(option => !this.selectedValues?.has(option.Id)) : null;
	}

	saveFavorites() {
		this.savingFavoritesService.save(this.favoriteName, Array.from(this.temporarySelectedValues));
		this.selectedValues = new Set(this.temporarySelectedValues);
		this.switchMode();
		this.scrollToTop();
	}

	private getSavedOptions(): Set<string> {
		return new Set(this.savingFavoritesService.get(this.favoriteName));
	}

	private scrollToTop() {
		setTimeout(() => {
			this.favoritesPanel?.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
		}, 2);
	}
}
