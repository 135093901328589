<div mat-dialog-actions class="navigation-action">
  <button
    mat-raised-button
    color="warn"
    disableRipple="true"
    id="delete-button"
    (click)="handleDelete()"
  >
    {{ 'ToothEditor.Delete' | translate }}
  </button>
</div>
