import { Injectable } from '@angular/core';
import { IdName } from '@shared/models/id-name';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ImplantMasterList } from '@shared/models/implant-master-list';
import { Implant } from '@shared/models/implant';

@Injectable({
	providedIn: 'root'
})
export class ImplantService {
	constructor(private shellQuery: ShellQuery) {}

	scanBodyManufacturerOptions$: Observable<IdName[]> = this.shellQuery.implantMasterList$.pipe(
		map(implantMasterList => implantMasterList.ScanBodyManufacturers)
	);

	activeImplantManufacturerOptions$: Observable<IdName[]> = this.shellQuery.implantMasterList$.pipe(
		filter(implantMasterList => !!implantMasterList),
		map(implantMasterList => this.filterActiveImplantManufacturers(implantMasterList))
	);

	filterImplants(scanBodyManufacturer: IdName, implantManufacturer: IdName): Implant[] {
		if (!scanBodyManufacturer || !implantManufacturer) {
			return [];
		}
		return (
			this.shellQuery.implantMasterList.Implants?.filter(
				implant =>
					implant.IsActive &&
					implant.ImplantManufacturerId === implantManufacturer.Id &&
					implant.ScanBodyManufacturerId === scanBodyManufacturer.Id
			) ?? []
		);
	}

	filterImplantManufacturers(scanBodyManufacturer: IdName): IdName[] {
		const { Implants, ImplantManufacturers } = this.shellQuery.implantMasterList;

		if (!(Implants?.length && ImplantManufacturers?.length)) {
			return [];
		}
		const set = new Set<number>();

		Implants.forEach(({ ImplantManufacturerId, IsActive, ScanBodyManufacturerId }) => {
			if (!set.has(ImplantManufacturerId) && IsActive && scanBodyManufacturer.Id === ScanBodyManufacturerId) {
				set.add(ImplantManufacturerId);
			}
		});

		return ImplantManufacturers.filter(({ Id }) => set.has(Id));
	}

	getSelectedValuesByImplantId(implantId: number): {
		scanBodyManufacturer: IdName;
		implantTypeScanBody: IdName;
		implantManufacturer: IdName;
	} {
		const selectedImplant = this.shellQuery.implantMasterList.Implants.find(implant => implant.Id === implantId);
		const selectedScanBody = this.shellQuery.implantMasterList.ScanBodyManufacturers.find(
			scanBodyManufacturer => scanBodyManufacturer.Id === selectedImplant?.ScanBodyManufacturerId
		);
		const selectedImplantManufacturer = this.shellQuery.implantMasterList.ImplantManufacturers.find(
			implantManufacturer => implantManufacturer.Id === selectedImplant?.ImplantManufacturerId
		);
		return {
			scanBodyManufacturer: selectedScanBody,
			implantTypeScanBody: selectedImplant,
			implantManufacturer: selectedImplantManufacturer
		};
	}

	getFirstActiveImplantIdByManufacturerId(id: number): number {
		if (id === undefined || id === null) {
			return null;
		}
		return this.shellQuery.implantMasterList?.Implants?.find(implant => implant.ImplantManufacturerId === id && implant.IsActive)?.Id;
	}

	filterActiveScanBodyManufacturers({ scanBodyManufacturers }: { scanBodyManufacturers: IdName[] }): IdName[] {
		const activeImplants = this.shellQuery.implantMasterList.Implants.filter(isb => isb.IsActive);

		return scanBodyManufacturers.filter(imf => activeImplants.map(i => i.ScanBodyManufacturerId).includes(imf.Id));
	}

	private filterActiveImplantManufacturers({ Implants, ImplantManufacturers }: ImplantMasterList): IdName[] {
		if (!(Implants?.length && ImplantManufacturers?.length)) {
			return [];
		}
		const set = new Set<number>();

		Implants.forEach(({ ImplantManufacturerId, IsActive }) => {
			if (!set.has(ImplantManufacturerId) && IsActive) {
				set.add(ImplantManufacturerId);
			}
		});

		return ImplantManufacturers.filter(({ Id }) => set.has(Id));
	}
}
