<div class="rx-card" >
    <div class="rx-card-title notes-title">
        <div class="rx-title-row" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="rx-card-title-text">{{ 'NotesSection.Notes' | translate }}</div>
        </div>
    </div>
    <div class="rx-card-body notes-card-body">
        <form [formGroup]="notesForm">
            <div class="create-note" *ngIf="!(this.isReadOnly$ | async) && !isPrintContext">
                <mat-form-field appearance="outline" fxFlex="flex-grow">
                    <textarea
                        id="new-note"
                        [formControl]="newNote"
                        (focus)="focusNotes($event)"
                        (blur)="blurNotes()"
                        matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="3"
                        [placeholder]="'NotesSection.AddNote' | translate"
                    >
                    </textarea>
                </mat-form-field>
            </div>
            <ng-container formArrayName="notes">
                <div
                    *ngFor="let note of notes.controls; index as i"
                    fxLayout="row"
                    class="mt-4 note-row"
                    fxLayoutAlign="start center"
                >
                    <div fxFlex fxLayout="column" fxLayoutAlign="center stretch">
                        <div
                            id="note-title"
                            [ngClass]="!isPrintContext ? 'note-title-position' : 'note-title-position-print'"
                            class="note-title"
                        >
                            <span [id]="'note-created-by-name-' + i">{{ note.value.createdByName ? note.value.createdByName: ''  }}</span>
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <span *ngIf="note.value.dateCreated" class="date-and-time mr-4">
                                    {{ note.value.dateCreated | date: (dateFormat$ | async) }}
                                    <span class="line-separator">|</span>
                                    {{ note.value.dateCreated | date: 'shortTime' }}</span
                                >
                                <span>
                                    <span *ngIf="i | mapper: canEdit.bind(this)" [id]="'edit-note-' + i" class="edit-note" (click)="editNote(i)">
                                        <mat-icon class="color-icon" svgIcon="pencil_icon"></mat-icon>
                                    </span>
                                    <span *ngIf="i | mapper: canDelete.bind(this)" [id]="'remove-note-' + i" class="remove-note" (click)="removeNote(i)">
                                        <mat-icon class="color-icon" svgIcon="trash_icon"></mat-icon>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="note-content">
                            <mat-form-field [class.read-only]="!(i | mapper: canEdit.bind(this))" appearance="standard" fxFlex="flex-grow" [class.disabled]="!(i | mapper: isInEditableMode.bind(this))">
                                <ng-container [formGroup]="note">
                                    <textarea
                                        ngDefaultControl
                                        #noteinput
                                        (focus)="focusNotes($event)"
                                        (blur)="blurNotes()"
                                        formControlName="content"
                                        [id]="'notes-input-' + i"
                                        class="notes-input"
                                        [class.hidden] = "isPrintContext"
                                        matInput
                                        autocomplete="off"
                                        cdkTextareaAutosize
                                        cdkAutosizeMinRows="1"
                                        [placeholder]="'NotesSection.AddNote' | translate"
                                        [disabled]="!(i | mapper: canEdit.bind(this))"
                                    ></textarea>
                                    <div *ngIf="isPrintContext" class="print-notes-input">
                                        {{ note.value.content }}
                                    </div>
                                </ng-container>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </ng-container>
        </form>
    </div>
</div>
