import { Component, Injector, Input } from '@angular/core';
import { PonticSelectorService } from '@modules/tooth-editor/services/pontic-selector.service';
import { ConfigurationItem } from '@shared/models/configuration-item';
import { ControlValueAccessorConnector } from '@shared/base-classes/control-value-accessor-connector';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'rx-pontic-selector',
	templateUrl: './pontic-selector.component.html',
	styleUrls: ['./pontic-selector.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: PonticSelectorComponent,
			multi: true
		}
	]
})
export class PonticSelectorComponent extends ControlValueAccessorConnector {
	@Input()
	isReadonly = false;
	@Input()
	name = '';
	@Input()
	options: ConfigurationItem[];
	@Input()
	showLabel = true;

	constructor(private ponticSelectorService: PonticSelectorService, injector: Injector) {
		super(injector);
	}

	getSrcForPonticDesignImage(ponticDesign: ConfigurationItem): string {
		return this.ponticSelectorService.getSrcForPonticDesignImage(ponticDesign);
	}

	getSrcForRadioButtonImg(): string {
		return this.ponticSelectorService.getSrcForRadioButtonImg();
	}

	addDefaultOptionForPontics(options: ConfigurationItem[]): ConfigurationItem[] {
		return [null, ...(options ?? [])];
	}

	onChange() {
		this.ponticSelectorService.setTouchedPontic();
	}
}
