import { Injectable } from '@angular/core';
import { combineQueries, Query } from '@datorama/akita';
import { PatientModel } from '@shared/models/rx-models/interfaces/patient-model';
import { Observable } from 'rxjs';
import { PatientState, PatientStore } from './patient-store';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { emptyGuid } from '@shared/models/consts';
import { patientSectionModeEnum } from '@modules/patient/models/patient-section-visibility.enum';
import { ShellQuery } from '@shared/store/shell/shell-query';

@Injectable({ providedIn: 'root' })
export class PatientQuery extends Query<PatientState> {
	patientState$: Observable<PatientState> = this.select(state => state);
	patient$: Observable<PatientModel> = this.select(state => state.patient);
	isPatientInConflict$: Observable<boolean> = this.select(state => state.isPatientInConflict);
	patientGuid$: Observable<string> = this.select(state => state.patient?.UID).pipe(map(patientGuid => this.mapPatientGuid(patientGuid)));
	patientSectionMode$: Observable<patientSectionModeEnum> = combineQueries([
		this.selectLoading(),
		this.patientGuid$,
		this.shellQuery.isNewRx$
	]).pipe(
		map(([isPatientLoading, patientGuid, isNewRx]) => {
			return (isNewRx && !patientGuid) || !(isPatientLoading || patientGuid)
				? patientSectionModeEnum.InlinePatientApp
				: patientSectionModeEnum.ReadOnly;
		}),
		distinctUntilChanged()
	);

	get patient(): PatientModel {
		return this.getValue().patient;
	}

	get patientGuid(): string {
		return this.mapPatientGuid(this.getValue().patient?.UID);
	}

	get isPatientInConflict(): boolean {
		return this.getValue().isPatientInConflict;
	}

	get isPatientAppConfigured(): boolean {
		return this.getValue().isPatientAppConfigured;
	}

	constructor(store: PatientStore, private shellQuery: ShellQuery) {
		super(store);
	}

	private mapPatientGuid(patientGuid: string) {
		return patientGuid === emptyGuid ? null : patientGuid;
	}
}
