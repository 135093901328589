<div
	class="rx-card"
	[class.read-only]="isReadOnly$ | async"
    *ngIf="formState$ | async as formState"
>
	<div class="rx-card-title">
		<div
			class="rx-title-row"
			fxLayout="row"
			fxLayoutAlign="start center"
		>
			<div class="rx-card-title-text">{{ 'OrderSection.Order' | translate }}:</div>
		</div>
	</div>
	<form
		class="rx-card-body"
		[formGroup]="orderForm"
		fxLayout="row"
		fxLayoutAlign="space-between"
	>
		<div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="0 1 calc(50% - 15px)">
			<rx-case-type-selector
				[isRequired]="!(isReadOnly$ | async) && !(isReturned$ | async)"
				[formControl]="caseTypeControl"
				[caseTypes]="caseTypes$ | async"
				name="caseTypeControl"
				ngDefaultControl
                [class.read-only]="(isCaseTypeReadOnly$ | async) || (isReturned$ | async)"
			></rx-case-type-selector>

			<rx-simple-form-field *ngIf="formState?.multiBite?.isVisible">
                <mat-slide-toggle
                [formControl]="isMultiBiteSelectedControl"
                [color]="'primary'"
                name="isMultiBiteSelected"
				ngDefaultControl
            >
                {{ "OrderSection.MultiBite" | translate }}
            </mat-slide-toggle>
            </rx-simple-form-field>
			<rx-due-date-picker
				*ngIf="formState?.dueDate?.isVisible"
				[formControl]="dueDateControl"
                [isRequired]="formState?.dueDate?.isRequired"
                [isReadOnly]="isReadOnly$ | async"
				[minimalAvailableDueDate]="minimalAvailableDueDate$ | async"
				[maxDueDate]="maxDueDate$ | async"
				name="dueDateControl"
				ngDefaultControl
			></rx-due-date-picker>
		</div>

		<div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="0 1 calc(50% - 15px)">
			<rx-object-selector
			*ngIf="formState?.sendTo?.isVisible"
			gdArea="sendToLab"
			[id]="'send-to-lab'"
			[label]="'OrderSection.SendTo' | translate"
			[class.send-to-selector-read-only]="isReadOnly$ | async"
			labelSeparator=":"
			[isReadOnly]="isReadOnly$ | async"
			[formControl]="sendToControl"
            [isRequired]="formState?.sendTo.isRequired"
			[isCancellable]="!(isReadOnly$ | async)"
			(cancelSelection)="handleCancelSendToLabSelection()"
			[options]="availableLabs$ | async"
			name="sendToControl"
			ngDefaultControl
			></rx-object-selector>

			<rx-simple-selector
			*ngIf="formState?.treatmentStage?.isVisible"
			gdArea="treatmentStage"
			[id]="'treatment-stage'"
			[label]="'OrderSection.TreatmentStage' | translate"
			labelSeparator=":"
			[isReadOnly]="(isReadOnly$ | async) || (isReturned$ | async)"
			[formControl]="treatmentStageControl"
			[isCancellable]="!(isViveraCasetypeSelected$ | async) && !(isReadOnly$ | async) && !(isReturned$ | async)"
			(cancelSelection)="handleCanceltreatmentStageSelection()"
			[options]="treatmentStages$ | async"
			name="treatmentStageControl"
			ngDefaultControl
			></rx-simple-selector>
		
			<rx-current-aligner-input
				*ngIf="formState?.currentAlignerId?.isVisible"
				[formControl]="currentAlignerIdControl"
				[isReadOnly]="(isReadOnly$ | async) || (isReturned$ | async)"
				name="currentAlignerIdControl"
				ngDefaultControl
			></rx-current-aligner-input>
		</div>
	</form>
</div>