import { Inject, Injectable } from '@angular/core';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { ObjectUtils } from '@shared/utils/object-utils/object-utils';
import { SHARE_TOOTH_PROPS_RULES } from '@shared/rules/rules-tokens';
import { ShareToothPropsRule } from '@shared/models/share-tooth-props-rule';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { DefaultCrownStore } from '@modules/tooth-editor/state/default-crown-store';
import { DefaultCrownQuery } from '@modules/tooth-editor/state/default-crown-query';
import { CrownDefaultProperties } from '@modules/tooth-editor/models/default-crown-data';
import { ShellQuery } from '@shared/store/shell/shell-query';

@Injectable({
	providedIn: 'root'
})
export class ToothEditorSharingService {
	constructor(
		@Inject(SHARE_TOOTH_PROPS_RULES) private rules: ShareToothPropsRule[],
		private defaultCrownValueStore: DefaultCrownStore,
		private defaultCrownValueQuery: DefaultCrownQuery,
		private shellQuery: ShellQuery
	) {}

	sharePropsToTeeth(teeth: Tooth[], tooth: Tooth): Tooth[] {
		return teeth?.map(t => {
			const targetTooth = t.ToothID === tooth.ToothID ? tooth : t;
			return this.mergeToothByRules(this.updateToothWithCrownData(tooth, teeth), targetTooth);
		});
	}

	getToothWithSharedProps(teeth: Tooth[], tooth: Tooth): Tooth {
		return teeth?.reduce((acc, t) => this.mergeToothByRules(t, acc), { ...this.updateToothWithCrownData(tooth, teeth) });
	}

	resetToothIfNeeded(tooth: Tooth, unitType: UnitTypes): Tooth {
		if (unitType !== UnitTypes.ImplantBased) {
			return {
				...tooth,
				ImplantBasedRestorationTypeId: null,
				AbutmentType: null,
				AbutmentMaterialId: null
			};
		}
		return tooth;
	}

	private mergeToothByRules(sourceTooth: Tooth, targetTooth: Tooth): Tooth {
		return this.rules.reduce((acc, rule) => this.copyPropsByRule(rule, sourceTooth, acc), targetTooth);
	}

	private copyPropsByRule(rule: ShareToothPropsRule, sourceTooth: Tooth, targetTooth: Tooth): Tooth {
		const { unitTypes, sharedProps } = rule;

		if (unitTypes.includes(sourceTooth.ToothInBridgeTypeID) && unitTypes.includes(targetTooth.ToothInBridgeTypeID)) {
			return this.copyProps(sharedProps, sourceTooth, targetTooth);
		}

		return targetTooth;
	}

	private copyProps(props: (keyof Tooth)[], sourceTooth: Tooth, targetTooth: Tooth): Tooth {
		return ObjectUtils.mapObject(targetTooth, ([key, value]) =>
			props.includes(key as keyof Tooth) &&
			(!value || value < 0) &&
			!this.doNotSetDefaultValueForPontic(targetTooth.BridgeIndex, targetTooth.ToothID, key)
				? sourceTooth[key]
				: value
		);
	}

	private updateToothWithCrownData(tooth: Tooth, teeth: Tooth[]) {
		return this.getUpdatedCrownDataTooth(Object.values(CrownDefaultProperties), tooth, teeth);
	}

	private getUpdatedCrownDataTooth(props: CrownDefaultProperties[], tooth: Tooth, teeth: Tooth[]): Tooth {
		if (!tooth) {
			return;
		}
		const result = { ...tooth };
		props.forEach(prop => {
			if (this.doNotSetDefaultValueForPontic(tooth.BridgeIndex, tooth.ToothID, prop)) {
				result[prop] = tooth.PonticDesignID;
				return;
			}
			let defaultValue = tooth[prop] > 0 ? tooth[prop] : this.defaultCrownValueQuery.getDefaultProperty(tooth.BridgeIndex, prop);
			if (!this.shellQuery.isNewRx) {
				const commonTeethProp = teeth.find(t => t[prop] >= 0);
				if (!!commonTeethProp) {
					defaultValue = commonTeethProp[prop];
				}
			}
			result[prop] = defaultValue ?? result[prop];
		});
		return result;
	}

	private doNotSetDefaultValueForPontic(bridgeIndex: number, toothId: number, propertyName: string): boolean {
		return propertyName === CrownDefaultProperties.PonticId && this.defaultCrownValueQuery.wasPonticTouched(bridgeIndex, toothId);
	}
}
