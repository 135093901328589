import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RxForLabFacade } from '../../rx-for-lab.facade';
import { DentureDetailsUtils } from '@modules/denture-details/utils/denture-details-utils';
import { ProcedureLoadRxService } from '@modules/order/services/procedure-load-rx.service';
import { BaseDestroyableDirective } from '@shared/base-classes/base-destroyable';

@Component({
	selector: 'rx-for-lab-v1',
	templateUrl: './rx-for-lab-v1.component.html',
	styleUrls: ['./rx-for-lab-v1.component.scss'],
	providers: [RxForLabFacade]
})
export class RxForLabV1Component extends BaseDestroyableDirective implements OnInit {
	isRxloadedAndReady$: Observable<boolean> = this.facade.isRxloadedAndReady$;
	isTeethDiagramAreaVisible$: Observable<boolean> = this.facade.isTeethDiagramAreaVisible$;
	procedureId$: Observable<number> = this.facade.procedureId$;
	rx$ = this.facade.rx$;

	constructor(private facade: RxForLabFacade, private procedureLoadRxService: ProcedureLoadRxService) {
		super();
	}

	isDentureDetailsVisible(procedureId: number): boolean {
		return DentureDetailsUtils.isDentureDetailsEnabled(procedureId);
	}

	ngOnInit(): void {
		// fill order store
		this.procedureLoadRxService.loadProcedureFlowOrder().pipe(takeUntil(this.componentAlive$)).subscribe();
	}
}
