import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DentureDetailsComponent } from './containers/denture-details/denture-details.component';
import { SharedModule } from '@shared/shared.module';
import { MouldElementComponent } from '@modules/denture-details/components/mould-element/mould-element.component';
import { MouldsDropdownComponent } from '@modules/denture-details/components/moulds-dropdown/moulds-dropdown.component';
import {
	DentureDetailsInfoComponent
} from '@modules/denture-details/containers/denture-details-info/denture-details-info.component';
import {GeneralRxDataModule} from '@modules/general-rx-data/general-rx-data.module';

@NgModule({
	declarations: [
		DentureDetailsComponent,
		DentureDetailsInfoComponent,
		MouldElementComponent,
		MouldsDropdownComponent
	],
	exports: [
		DentureDetailsComponent,
		DentureDetailsInfoComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		GeneralRxDataModule
	]
})
export class DentureDetailsModule { }
