import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { DentureDetailsFacade } from '@modules/denture-details/denture-details.facade';
import {takeUntil, tap} from 'rxjs/operators';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { ConfigurationItem } from '@shared/models/configuration-item';
import { RxConfiguration } from '@shared/models/rx-configuration';
import { Observable } from 'rxjs';
import * as flex from '@shared/utils/flex-utils';

@Component({
	selector: 'rx-denture-details-info',
	templateUrl: './denture-details-info.component.html',
	styleUrls: ['denture-details-info.component.scss'],
	providers: [DentureDetailsFacade],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DentureDetailsInfoComponent extends BaseDestroyableComponent implements OnInit{
	@Input() rx: RxModel;
	@Input() isResponsive = true;

	stage: string;
	isUpperJawChecked: boolean;
	isLowerJawChecked: boolean;
	gingivalShade: string;
	teethShade: string;
	mould: ConfigurationItem;
	shadeSystem: string;

	constructor(private facade: DentureDetailsFacade) {
		super();
	}

	ngOnInit() {
		this.subscribeToGetRxConfiguration();
	}

	subscribeToGetRxConfiguration() {
		this.getDentureDetailsConfig().pipe(takeUntil(this.componentAlive$)).subscribe();
	}

	getDentureDetailsConfig(): Observable<RxConfiguration> {
		return this.facade.rxConfiguration$.pipe(
			tap((rxConfiguration) => {
				this.stage = rxConfiguration?.Stages?.find(x => x.Id === this.rx?.DentureDetails?.Stage)?.Name;
				this.isUpperJawChecked = this.rx?.DentureDetails?.UpperJaw;
				this.isLowerJawChecked = this.rx?.DentureDetails?.LowerJaw;
				this.gingivalShade = rxConfiguration?.DentureGingivalShades?.find(x => x.Id === this.rx?.DentureDetails?.GingivalShadeId)?.Name;
				this.teethShade = this.rx?.DentureDetails?.TeethShade;
				this.mould = rxConfiguration?.Moulds?.find(x => x.Id === this.rx?.DentureDetails?.Mould);

				this.shadeSystem = this.facade.shadeSystems?.
					find( shadeSystem => shadeSystem.Id === this.rx?.DentureDetails?.ShadeSystemId)?.Name;
			})
		);
	}

	getSrcForRadioButtonImg = (checked: boolean): string => this.facade.getSrcForRadioButtonImg(checked);
	getResponsiveDirection = flex.getResponsiveDirection;

}
