import { Component, Input } from '@angular/core';
import { ConfigurationItem } from '@shared/models/configuration-item';
import { MouldSelectorService } from '@modules/denture-details/components/mould-element/mould-selector.service';

@Component({
	selector: 'rx-mould-element',
	templateUrl: './mould-element.component.html',
	styleUrls: ['./mould-element.component.scss']
})
export class MouldElementComponent {
	@Input()
	mould: ConfigurationItem;

	@Input()
	isSelected: boolean;

	@Input()
	showName = true;
	constructor(private mouldSelectorService: MouldSelectorService) { }

	getMouldImage(mould: ConfigurationItem): string {
			return mould ? this.mouldSelectorService.getSrcForMouldImage(mould) : '';
	}
}
