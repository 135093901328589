import { Injectable } from '@angular/core';
import { ConfigurationItem } from '@shared/models/configuration-item';
import { PonticDesignEnum } from '@shared/models/pontic-design.enum';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { DefaultCrownStore } from '@modules/tooth-editor/state/default-crown-store';
import { ToothEditorQuery } from '@modules/tooth-editor/state/tooth-editor-query';

@Injectable({
	providedIn: 'root'
})
export class PonticSelectorService {
	constructor(private shellQuery: ShellQuery, private defaultCrownStore: DefaultCrownStore, private toothEditorQuery: ToothEditorQuery) {}

	getSrcForPonticDesignImage(ponticDesign: ConfigurationItem): string {
		const ponticDesignSvgNames: Record<PonticDesignEnum, string> = {
			[PonticDesignEnum.RidgeLapPontic]: 'ridge-lap-pontic.svg',
			[PonticDesignEnum.ModifiedRidgeLapPontic]: 'modified-ridge-lap-pontic.svg',
			[PonticDesignEnum.SteinPontic]: 'stein-pontic.svg',
			[PonticDesignEnum.SanitaryPontic]: 'sanitary-pontic.svg',
			[PonticDesignEnum.OvalePontic]: 'ovale-pontic.svg'
		};

		return `${this.shellQuery.staticFilesEndpoint}/assets/pontic-designs/${ponticDesignSvgNames[ponticDesign.Id] as string}`;
	}

	getSrcForRadioButtonImg(): string {
		return `${this.shellQuery.staticFilesEndpoint}/assets/icons/check.svg`;
	}

	setTouchedPontic() {
		this.defaultCrownStore.setTouchedPointic(
			this.toothEditorQuery.toothClickedOn.BridgeIndex,
			this.toothEditorQuery.toothClickedOn.ToothID
		);
	}
}
