import { TeethNumberingSystem } from '@modules/teeth-diagram/models/teeth-numbering-system.enum';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';

export const isTeethNumberingSystemValid = (teethNumberingSystem): boolean => {
	return [TeethNumberingSystem.FDI, TeethNumberingSystem.ADA, TeethNumberingSystem.Quadrant].includes(teethNumberingSystem);
};

export const getTeethNumberingSystem = ({
	existingRx,
	isLabUser,
	teethNumberingSystem,
	forceTeethNumberingSystem
}: {
	existingRx: RxModel;
	isLabUser: boolean;
	teethNumberingSystem: TeethNumberingSystem;
	forceTeethNumberingSystem?: TeethNumberingSystem;
}): TeethNumberingSystem => {
	if (forceTeethNumberingSystem != null && isTeethNumberingSystemValid(forceTeethNumberingSystem)) {
		return forceTeethNumberingSystem;
	}
	if (isLabUser && existingRx) {
		return existingRx.ToothNumberingSystem;
	}
	if (!isTeethNumberingSystemValid(teethNumberingSystem)) {
		return TeethNumberingSystem.ADA;
	}
	return teethNumberingSystem;
};
