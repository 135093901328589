<div
    class="layout-container"
    fxLayout="row"
    fxLayoutAlign="center"
    [ngClass]="(isPatientReadyAndLoaded$ | async) ? 'isVisible' : 'isHidden'"
>
    <div fxFlex="0 0 calc(100% - 30px)" id="rx-for-doctor-container">
		<rx-awareness-banner-lmr *ngIf="showLmrAwareness$ | async"></rx-awareness-banner-lmr>
        <rx-awareness-banner-ga *ngIf="showGaAwareness$ | async"></rx-awareness-banner-ga>
        <rx-banner
            class="mt-4"
            *ngIf="isReadOnlyBannerVisible$ | async"
            [type]="'info'"
            [message]="'ReadOnlyMode.ReadOnlyMessage' | translate"
        ></rx-banner>
        <rx-doctor></rx-doctor>
        <rx-patient></rx-patient>
        <ng-container [ngSwitch]="isProcedureFlow$ | async">
            <div *ngSwitchCase="true">
                <rx-procedure-flow-order></rx-procedure-flow-order>
                <rx-denture-details *ngIf="isDentureDetailsVisible$ | async"></rx-denture-details>
            </div>
            <rx-case-type-flow-order *ngSwitchCase="false"></rx-case-type-flow-order>
        </ng-container>
        <rx-banner
            class="mb-5 mt-4"
            *ngIf="isChairSideMillingAvailableForDownload$ | async"
            [type]="'notification'"
            [message]="'OrderSection.ChairSideMillingAlert' | translate"
        ></rx-banner>
        <rx-scan-options [isMultiBiteVisible]="isMultiBiteVisible$ | async" *ngIf="isScanOptionsVisible$ | async"></rx-scan-options>
        <div *ngIf="isTeethDiagramAreaVisible$ | async">
            <rx-teeth-diagram [isProcedureFlow]="isProcedureFlow$ | async"></rx-teeth-diagram>
            <rx-treatment-information [isProcedureFlow]="isProcedureFlow$ | async" [isTreatmentsTableShownEmpty]="false"></rx-treatment-information>
        </div>
        <rx-notes></rx-notes>

        <div *ngIf="isPdfButtonVisible$ | async" fxLayout="row" fxLayoutAlign="end center" class="mb-4">
            <button
                mat-raised-button
                color="primary"
                disableRipple="true"
                [disabled]="isPdfButtonDisabled$ | async"
                (mousedown)="handlePdfButtonClick()"
            >
                {{ 'RxForDoctor.DownloadPDF' | translate }}
            </button>
        </div>

        <rx-notice [config]="noticeConfig" [companyCountryCode]="companyCountryCode$ | async"></rx-notice>
    </div>
</div>
