import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { Observable } from 'rxjs';
import { DentureDetailsFacade } from '@modules/denture-details/denture-details.facade';
import { IdName } from '@shared/models/id-name';
import { ShadeSystem } from '@shared/models/shade-system';
import { distinctUntilChanged, take, takeUntil, tap } from 'rxjs/operators';

@Component({
	selector: 'rx-denture-details',
	templateUrl: './denture-details.component.html',
	styleUrls: ['./denture-details.component.scss'],
	providers: [DentureDetailsFacade],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DentureDetailsComponent extends BaseDestroyableComponent implements OnInit {
	form: FormGroup = this.formBuilder.group({
		isUpperJawChecked: false,
		isLowerJawChecked: false,
		stage: [''],
		mould: [''],
		shadeSystem: [''],
		teethShade: [''],
		gingivalShade: ['']
	});

	stages$: Observable<IdName[]> = this.facade.stages$;
	moulds$: Observable<IdName[]> = this.facade.moulds$;
	shadeSystems: ShadeSystem[] = this.facade.shadeSystems;
	teethShades$: Observable<string[]> = this.facade.teethShades$;
	gingivalShades$: Observable<IdName[]> = this.facade.gingivalShades$;
	teethShadesHasOptions$: Observable<boolean> = this.facade.shouldTeethShadeBeSelected$;
	dentureUpperSectionIsReadonly$ = this.facade.dentureUpperSectionIsReadonly$;
	dentureLowerSectionIsReadonly$ = this.facade.dentureLowerSectionIsReadonly$;
	isReadonly$ = this.facade.isReadonly$;
	isStageDisabled$ = this.facade.isStageDisabled$;

	get isUpperControl(): AbstractControl {
		return this.form.controls.isUpperJawChecked;
	}
	get isLowerControl(): AbstractControl {
		return this.form.controls.isLowerJawChecked;
	}
	get stageControl(): AbstractControl {
		return this.form.controls.stage;
	}
	get mouldControl(): AbstractControl {
		return this.form.controls.mould;
	}
	get shadeSystemControl(): AbstractControl {
		return this.form.controls.shadeSystem;
	}
	get teethShadeControl(): AbstractControl {
		return this.form.controls.teethShade;
	}
	get gingivalShadeControl(): AbstractControl {
		return this.form.controls.gingivalShade;
	}

	simpleCompareWith(o1: any, o2: any) {
		return o1 === o2;
	}

	constructor(private formBuilder: FormBuilder, private facade: DentureDetailsFacade) {
		super();
	}

	ngOnInit(): void {
		this.subscribeToUserChanges();
		this.subscribeToRxLoaded();
		this.subscribeToProgramaticallyChangingToggles();
		this.subscribeToGetDefaultShade();
	}

	private subscribeToUserChanges(): void {
		this.form.valueChanges
			.pipe(
				distinctUntilChanged(),
				tap(() => {
					const result = this.facade.updateStore(this.form.getRawValue());
					if (result.isFormValuesModified) {
						this.form.patchValue(result.formValues, { emitEvent: false });
					}
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private subscribeToRxLoaded(): void {
		this.facade
			.getLoadedRx()
			.pipe(
				tap(state => this.form.patchValue(state)),
				take(1),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private subscribeToProgramaticallyChangingToggles(): void {
		this.facade.getUpperSectionToggleValue$
			.pipe(
				tap(isUpperJawChecked => this.form.patchValue({ ...this.form.getRawValue(), isUpperJawChecked })),
				takeUntil(this.componentAlive$)
			)
			.subscribe();

		this.facade.getLowerSectionToggleValue$
			.pipe(
				tap(isLowerJawChecked => this.form.patchValue({ ...this.form.getRawValue(), isLowerJawChecked })),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}
	private subscribeToGetDefaultShade(): void {
		this.facade.getDefaultShadeSystem()
			.pipe(
				tap((shadeSystem) => {
					if (!this.shadeSystemControl.value && shadeSystem) {
						this.form.patchValue({ ...this.form.getRawValue(), shadeSystem });
					}
				}),
				takeUntil(this.componentAlive$))
			.subscribe();
	}

	get isTeethShadeEnabled() {
		return !!this.shadeSystemControl?.value;
	}
}
