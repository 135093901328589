import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { NewSleeveAttachedState, ScanOptionsState, ScanOptionsStore } from './scan-options-store';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ScanOptionsQuery extends Query<ScanOptionsState> {

	isSleeveChecked$: Observable<NewSleeveAttachedState> = this.select(state => state.isSleeveChecked);
	isSleeveCheckedSentByScanner$: Observable<boolean> = this.select(state => state.isSleeveCheckedSentByScanner);
	isNiriCaptureChecked$: Observable<boolean> = this.select(state => state.isNiriCaptureChecked);
	isPreTreatmentChecked$: Observable<boolean> = this.select(state => state.isPreTreatmentChecked);
	get isNiriCaptureChecked(): boolean { return this.getValue().isNiriCaptureChecked; }
	get isSleeveChecked(): NewSleeveAttachedState { return this.getValue().isSleeveChecked; }
	get isPreTreatmentChecked(): boolean { return this.getValue().isPreTreatmentChecked; }
	
	sleeveCheckedChangedByUser$: Observable<boolean> = this.select(state => state.isSleeveChecked).pipe(
		filter((isSleeveChecked) => !!isSleeveChecked.isValueChangedByUser),
		map(isSleeveChecked => isSleeveChecked.value)
	);
	
	constructor(store: ScanOptionsStore) {
		super(store);
	}
}
