import { Injectable } from '@angular/core';
import { TimberMessageTypes } from '@shared/models/enums/enums';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { AppSettingsService } from '../app-settings/app-settings.service';
import { LogParameters } from './interfaces/log.parameters';
import { HostPlatformService } from '@shared/services/host-platform.service';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { Timber } from '@itero/timber';
import { LoggingSettings } from '@shared/models/environment-params';
import { ILogParameters } from '@itero/timber/interfaces/logParameters';
import { IRemoteConfig } from '@itero/timber/interfaces/config.remote';
import { AppenderType, LogLevel } from '@itero/timber/enums';

@Injectable({ providedIn: 'root' })
export class LoggerService extends BaseDestroyableComponent {
	protected timber: Timber;

	constructor(
		private appSettingsService: AppSettingsService,
		private shellQuery: ShellQuery,
		protected hostPlatformService: HostPlatformService
	) {
		super();
		this.createLogger(appSettingsService.loggingSetting);
		this.updateConfigWhenUserChanges();
		this.updateConfigWhenCompanyChanges();
	}

	static getTimberConfig(loggingSetting: LoggingSettings): IRemoteConfig {
		if (!loggingSetting) {
			return null;
		}
		const { url, appId, requiredFields, level, appenderTypes, eventType } = loggingSetting;
		return {
			url,
			appId,
			requiredFields,
			minLogLevel: LogLevel[level] ?? LogLevel.Off,
			appenderTypes: appenderTypes.filter(x => Object.values(AppenderType).includes(x)).map(x => AppenderType[x]),
			eventType
		} as IRemoteConfig;
	}

	trace(message: string, parameters: LogParameters): void {
		this.logByType(message, TimberMessageTypes.trace, parameters);
	}

	debug(message: string, parameters: LogParameters): void {
		this.logByType(message, TimberMessageTypes.debug, parameters);
	}

	info(message: string, parameters: LogParameters): void {
		this.logByType(message, TimberMessageTypes.info, parameters);
	}

	warn(message: string, parameters: LogParameters): void {
		this.logByType(message, TimberMessageTypes.warn, parameters);
	}

	error(message: string, parameters: LogParameters): void {
		this.logByType(message, TimberMessageTypes.error, parameters);
	}

	fatal(message: string, parameters: LogParameters): void {
		this.logByType(message, TimberMessageTypes.fatal, parameters);
	}

	logRXData(rx: RxModel, text: string, module: string): any {
		const rxForLog = {
			...rx,
			Teeth: undefined,
			Bridges: undefined,
			Patient: { UID: rx.Patient?.UID },
			Signature: undefined,
			OrderInformation: undefined,
			Notes: undefined,
			NotesArray: undefined,
			TechnicalNotes: undefined,
			AligntechNotes: undefined,
			AlignTechNotesArray: undefined
		};

		this.info(`${text}${JSON.stringify(rxForLog)}`, {
			module
		});

		return rxForLog;
	}

	private createLogger(loggingSettings: LoggingSettings): void {
		this.timber = new Timber(LoggerService.getTimberConfig(loggingSettings));
	}

	private logByType(message: string, messageType: TimberMessageTypes, parameters?: ILogParameters): void {
		if (this.hostPlatformService.isScanner) {
			const scannerMessage = `RxLog: ${message}, Parameters: ${JSON.stringify(parameters)}`;
			/* eslint-disable no-console */
			console.log(scannerMessage);
		}
		this.timber[messageType](message, parameters);
	}

	private updateConfigWhenUserChanges(): void {
		this.shellQuery.contactId$
			.pipe(
				filter(contactId => !!contactId),
				take(1),
				tap(contactId => this.timber.setConfig({ userId: contactId })),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private updateConfigWhenCompanyChanges(): void {
		this.shellQuery.companyId$
			.pipe(
				filter(companyId => !!companyId),
				take(1),
				tap(companyId => this.timber.setConfig({ companyId })),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}
}
