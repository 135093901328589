import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import {
	defaultTeethNumbers,
	getJawByToothNumber,
	getToothNumberById
} from '@modules/teeth-diagram/models/teeth-numbering';
import { TeethNumberingSystem } from '@modules/teeth-diagram/models/teeth-numbering-system.enum';
import { newTooth, Tooth } from '@modules/teeth-diagram/models/tooth';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { ToothEditorFacade } from '@modules/tooth-editor/tooth-editor.facade';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { IdName } from '@shared/models/id-name';
import { combineLatest, Observable } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

@Component({
	selector: 'rx-bridge-range-selector',
	templateUrl: './bridge-range-selector.component.html',
	styleUrls: ['./bridge-range-selector.component.scss'],
	providers: [ToothEditorFacade]
})
export class BridgeRangeSelectorComponent extends BaseDestroyableComponent implements OnInit, AfterViewInit, OnChanges {
	@Input() isReadOnly: boolean;
	teethNumberingSystem$: Observable<TeethNumberingSystem> = this.toothEditorFacade.teethNumberingSystem$;
	teeth$: Observable<Tooth[]> = this.toothEditorFacade.teeth$;
	allTeethInJaw$: Observable<Tooth[]> = this.toothEditorFacade.allTeethInJaw$;
	toothClickedOn$: Observable<Tooth> = this.toothEditorFacade.toothClickedOn$;
	bridgeIndex: number;
	allTeethInJaw: Tooth[];
	teeth: Tooth[];

	fromOptions: IdName[] = [];
	toOptions: IdName[] = [];
	fromToForm = new FormGroup({
		from: new FormControl({ value: null, disabled: false }),
		to: new FormControl({ value: null, disabled: false })
	});

	get fromControl(): AbstractControl {
		return this.fromToForm.controls.from;
	}
	get toControl(): AbstractControl {
		return this.fromToForm.controls.to;
	}
	constructor(private toothEditorFacade: ToothEditorFacade) {
		super();
	}

	ngOnInit() {
		combineLatest([this.allTeethInJaw$, this.teeth$])
			.pipe(
				tap(([allTeethInJaw, teeth]) => {
					this.allTeethInJaw = allTeethInJaw;
					this.teeth = teeth;
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
		combineLatest([this.allTeethInJaw$, this.toothClickedOn$, this.teethNumberingSystem$])
			.pipe(
				take(1),
				tap(([allTeethInJaw, toothClickedOn, teethNumberingSystem]: [Tooth[], Tooth, TeethNumberingSystem]) => {
					this.bridgeIndex = toothClickedOn.BridgeIndex;
					const jaw = getJawByToothNumber({ toothId: toothClickedOn?.ToothID });
					const availableBridgeSpanIndices = this.toothEditorFacade.getAvailableBridgeSpan({ toothClickedOn, allTeethInJaw });
					const options = defaultTeethNumbers[jaw === 'upper' ? 'upperJaw' : 'lowerJaw'].map((toothId: number) => {
						return {
							Id: toothId,
							Name: getToothNumberById({ toothId, teethNumberingSystem }).toString()
						};
					});
					this.fromOptions = [...options].slice(availableBridgeSpanIndices.fromIndex, availableBridgeSpanIndices.toIndex);
					this.toOptions = [...options].slice(availableBridgeSpanIndices.fromIndex, availableBridgeSpanIndices.toIndex);
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
		this.teeth$
			.pipe(
				tap((teeth: Tooth[]) => {
					if (!this.isReadOnly) {
						this.fromToForm.enable({ emitEvent: false });
						this.fromControl.enable({ emitEvent: false });
						this.toControl.enable({ emitEvent: false });
					}

					this.fromControl.patchValue(this.getSelection({ teeth, options: this.fromOptions, fromTo: 'from' }), {
						emitEvent: false
					});
					this.toControl.patchValue(this.getSelection({ teeth, options: this.toOptions, fromTo: 'to' }), { emitEvent: false });
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	ngAfterViewInit() {
		this.fromToForm.valueChanges
			.pipe(
				tap(({ from, to }: { from: IdName; to: IdName }) => {
					if (from && to) {
						const [first, last] = [from.Id, to.Id].sort((a, b) => a - b) || [];
						const newTeethRange = this.allTeethInJaw
							?.filter((tooth: Tooth) => first <= tooth.ToothID && tooth.ToothID <= last)
							?.map(tooth => this.teeth.find(t => t.ToothID === tooth.ToothID) ?? this.prepareToothToBridge(tooth));

						if (newTeethRange) {
							this.toothEditorFacade.initTeeth({ teeth: newTeethRange });
							this.toothEditorFacade.selectNearestToothInBridge(newTeethRange);
						}
					}
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.isReadOnly.currentValue) {
			this.toothEditorFacade.setFormGroupReadOnlyMode(this.fromToForm);
		}
	}

	private getSelection({ teeth, options, fromTo }: { teeth: Tooth[]; options: IdName[]; fromTo: 'from' | 'to' }): IdName {
		const toothIndex = fromTo === 'from' ? 0 : teeth.length - 1;
		return options.find(option => option.Id === teeth[toothIndex].ToothID);
	}

	private prepareToothToBridge(tooth: Tooth): Tooth {
		if (this.isProcedureFlow) {
			if (tooth.UnitTypeID !== null && tooth.UnitTypeID !== UnitTypes.Regular) {
				return {
					...newTooth,
					BridgeIndex: this.bridgeIndex,
					ToothID: tooth.ToothID
				};
			}
			return {
				...tooth,
				UnitTypeID: null,
				BridgeIndex: this.bridgeIndex,
				ToothInBridgeTypeID: null
			};	
		}

		return {
			...tooth,
			UnitTypeID: null,
			BridgeIndex: this.bridgeIndex,
			ToothInBridgeTypeID: tooth.ToothInBridgeTypeID ?? this.toothEditorFacade.convertToBridgeToothUnitType({ unitType: tooth.UnitTypeID })
		};
	}

	get isProcedureFlow(): boolean {
		return this.toothEditorFacade.isProcedureFlow;
	}
}
