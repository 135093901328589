import { Component, OnInit } from '@angular/core';
import { merge, Observable } from 'rxjs';
import { tap, takeUntil, map, filter } from 'rxjs/operators';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { RxConfiguration } from '@shared/models/rx-configuration';
import { RxForDoctorFacade } from '@modules/rx-for-doctor/rx-for-doctor.facade';
import { UserSettings } from '@shared/models/user-settings';
import * as availableCaseTypesMock from '../../../assets/mocks/mock_available_case_types.json';
import { FormControl } from '@angular/forms';
import { isJsonString } from '@modules/notes/services/validators';
import { AppFacade } from 'src/app/app.facade';
import { SdkInputs } from '@shared/models/sdk-inputs';
import { FeatureToggle } from '@shared/models/feature-toggle';
import { OrderV0Facade } from '@modules/order/order-v0.facade';

@Component({
	selector: 'rx-dev-tools-editor',
	templateUrl: './dev-tools-editor.component.html',
	styleUrls: ['./dev-tools-editor.component.scss'],
	providers: [AppFacade, RxForDoctorFacade, OrderV0Facade]
})
export class DevToolsEditorComponent extends BaseDestroyableComponent implements OnInit {
	rxConfigMockControl = new FormControl('', { validators: [isJsonString()], updateOn: 'blur'});
	userSettingsMockControl = new FormControl('', { validators: [isJsonString()], updateOn: 'blur' });
	sdkInputsMockControl = new FormControl('', { validators: [isJsonString()], updateOn: 'blur' });
	availableCaseTypesMockControl = new FormControl('',  { validators: [isJsonString()], updateOn: 'blur'});
	featureTogglesMockControl = new FormControl('',  { validators: [isJsonString()], updateOn: 'blur'});
	rxConfiguration$: Observable<RxConfiguration> = this.appFacade.rxConfiguration$;
	featureToggles$: Observable<FeatureToggle[]> = this.appFacade.featureToggles$;
	userSettings$: Observable<UserSettings> = this.appFacade.userSettings$;
	onSDKInputsChange$: Observable<SdkInputs> = this.appFacade.onSDKInputsChange$;
	sdkInputsExamples = [
		'{"applicationMode": "EmbeddedInScanner", "orderId":null, "rxId":null, "contactId": 19883,"companyId": 5226,"productType": "EVX","scannerId": "69fc0b55-8259-4695-a9db-ede771c0223d","clientVersion": "2.5.0.0", "languageCode":"en-US","isReadOnly":false,"validationMode": "Incomplete", "clonedFromRxId":""}',
		'{"applicationMode": "Web", "orderId":null, "rxId":null,"contactId": 19883,"companyId": 5226,"productType": "","scannerId": "","clientVersion": "", "languageCode":"en-US","isReadOnly":false, "readOnlyRules": null, "forceV0": false }',
		'{"applicationMode": "Web", "orderId":null, "rxId":null,"contactId": 19883,"companyId": 5226,"productType": "","scannerId": "","clientVersion": "", "languageCode":"en-US","isReadOnly":true ,"readOnlyRules": {"patient":false,"order":false,"doctor":false,"scan-options":false}, "forceV0": false }'
	];

	constructor(
		private appFacade: AppFacade,
		private orderFacade: OrderV0Facade
	) {
		super();
	}

	ngOnInit() {
		merge(
			this.updateStoreWithRxConfigMock$(),
			this.getRxConfigFromStore$(),
			this.updateStoreWithFeatureTogglesgMock$(),
			this.getFeatureTogglesFromStore$(),
			this.updateStoreWithUserSettingsMock$(),
			this.getUserSettingsFromStore$(),
			this.updateStoreWithAvailableCaseTypesMock$(),
			this.updateStoreWithSDKInputsMock$()
		).pipe(takeUntil(this.componentAlive$)).subscribe();

		this.availableCaseTypesMockControl.patchValue(
			JSON.stringify(availableCaseTypesMock),
			{ emitEvent: false }
		);

		this.onSDKInputsChange$.pipe(tap(sdkInputs => {
			this.setSDKInputsFromStore(sdkInputs);
		}),
		takeUntil(this.componentAlive$)).subscribe();

	}

	applyRxForDoctors = (example: string): void => this.sdkInputsMockControl.patchValue(example)

	private updateStoreWithRxConfigMock$(): Observable<string> {
		return this.rxConfigMockControl.valueChanges.pipe(
			filter(_ => this.rxConfigMockControl.valid),
			tap(rxConfigMock => {
				this.appFacade.updateRxConfiguration(JSON.parse(rxConfigMock.toString()) as RxConfiguration)
			})
		);
	}

	private getRxConfigFromStore$(): Observable<RxConfiguration> {
		return this.rxConfiguration$.pipe(
			tap(rxConfigMock =>
				this.rxConfigMockControl.patchValue(JSON.stringify(rxConfigMock), {
					emitEvent: false
				})
			)
		);
	}

	private updateStoreWithFeatureTogglesgMock$(): Observable<string> {
		return this.featureTogglesMockControl.valueChanges.pipe(
			filter(_ => this.featureTogglesMockControl.valid),
			tap(featureTogglesMock => {
				this.appFacade.updateFeatureToggles(JSON.parse(featureTogglesMock.toString()) as FeatureToggle[])
			})
		);
	}

	private getFeatureTogglesFromStore$(): Observable<FeatureToggle[]> {
		return this.featureToggles$.pipe(
			tap(featureTogglesMock =>
				this.featureTogglesMockControl.patchValue(JSON.stringify(featureTogglesMock), {
					emitEvent: false
				})
			)
		);
	}

	private updateStoreWithUserSettingsMock$(): Observable<string> {
		return this.userSettingsMockControl.valueChanges.pipe(
			filter(_ => this.userSettingsMockControl.valid),
			tap(userSettingsMock => {
				this.appFacade.updateUserSettings(JSON.parse(userSettingsMock.toString()) as UserSettings)
			}
		));
	}

	private getUserSettingsFromStore$(): Observable<UserSettings> {
		return this.userSettings$.pipe(
			tap(userSettingsMock =>
				this.userSettingsMockControl.patchValue(
					JSON.stringify(userSettingsMock),
					{ emitEvent: false }
				)
			)
		);
	}
	private updateStoreWithAvailableCaseTypesMock$(): Observable<number[]> {
		return this.availableCaseTypesMockControl.valueChanges.pipe(
			filter(_ => this.availableCaseTypesMockControl.valid),
			map(values => JSON.parse(values.toString()).default as number[]),
			tap(availableCaseTypes =>
				this.orderFacade.updateCaseTypes(availableCaseTypes)
			)
		);
	}

	private setSDKInputsFromStore = (sdkInputs: SdkInputs) => {
		this.sdkInputsMockControl.patchValue(JSON.stringify(sdkInputs), {
			emitEvent: false
		});
	};

	private updateStoreWithSDKInputsMock$(): Observable<string> {
		return this.sdkInputsMockControl.valueChanges.pipe(
			filter(_ => this.sdkInputsMockControl.valid),
			tap(sdkInputs => {
				this.appFacade.updateSdkInputs(JSON.parse(sdkInputs.toString()) as SdkInputs);
			})
		);
	}
}
