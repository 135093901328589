<mat-form-field [attr.id]="'rx-mat-form-field-' + id" fxFlex [class.read-only]="isReadOnly" hideRequiredMarker>
    <mat-label [class.read-only-label]="isReadOnly && !control.value"
    >{{ label }}<span *ngIf="secondaryLabel">- {{secondaryLabel}}</span
    ><span *ngIf="labelSeparator">{{ labelSeparator }}</span
    ><span class="mat-form-field-required-marker" *ngIf="isRequired && control.enabled">&#32;*</span>
    </mat-label>
    <mat-select [id]="'rx-auto-select-' + id"
                [value]="control.value"
                [rxIsRequired]="isRequired"
                [formControl]="control"
                [rxIsDisabled]="isDisabled"
                (rxIsDisabledEvent)="handleDisableEvent($event)"
                [compareWith]="compareWith"
                [panelClass]="panelClass"
                [class.read-only]="isReadOnly"
                name="option"
    >
        <mat-option
            *ngFor="let option of options; let index = index"
            [value]="option"
            [id]="option | mapper : generateOptionId.bind(this)"
            [class]="'rx-auto-simple-select-option-' + index"
        >
             {{ propertyName ? option[propertyName] : option }}
        </mat-option>
    </mat-select>
    <mat-error *ngIf="isRequired && control.enabled">{{ 'Validation.RequiredField' | translate }}</mat-error>
    <mat-icon *ngIf="isCancellable && !!control?.value" id="cancel-selection" class="cancel-selection" (click)="handleCancelSelection($event)">close</mat-icon>
</mat-form-field>
