import { Injectable } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { MatDialog, MatDialogConfig, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { PatientAppPopUpComponent } from '@modules/patient/components/patient-app-popup/patient-app-popup.component';
import { PatientAppPupupInput } from '@modules/patient/models/patient-app-pupup-input';
import { ConfirmationPopUpComponent } from '@shared/components/confirmation-pop-up/confirmation-pop-up.component';
import { ConfirmationPopUpInput } from '@shared/models/confirmation-pop-up-input';
import { PopUpActions } from '@shared/models/enums/popup-modal-actions.enum';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class PopupService {

	constructor(
		private dialog: MatDialog,
		private location: PlatformLocation
	) { }

	openConfirmationPopUp({ popUpInput }: { popUpInput: ConfirmationPopUpInput }): Observable<PopUpActions> {
		const popup = this.dialog.open(ConfirmationPopUpComponent, {
			maxWidth: '720px',
			width: '100%',
			data: popUpInput,
			disableClose: true,
			panelClass: 'confirmation-pop-up'
		});

		this.location.onPopState(() => popup.close());

		return popup.afterClosed().pipe(
			tap(popUpAction => {
				if (popUpAction === PopUpActions.Ok && popUpInput.dontShowAgainAction &&
					typeof popUpInput.dontShowAgainAction === 'function' && popup.componentInstance.dontShowAgain) {
					popUpInput.dontShowAgainAction();
				}
			}));
	}

	openPatientAppPopUp(inputParams: PatientAppPupupInput, config: Partial<MatDialogConfig> = {}): MatDialogRef<PatientAppPopUpComponent> {
		const popup = this.dialog.open(PatientAppPopUpComponent, {
			id: 'patient-app-popup',
			maxWidth: '790px',
			width: '100%',
			data: inputParams,
			panelClass: 'patient-app-dialog',
			...config,
		});

		this.location.onPopState(() => popup.close());

		return popup;
	}
	
	isPopupOpenById(popupId: string) {
		return this.dialog.getDialogById(popupId)?.getState() === MatDialogState.OPEN;
	}

	closePopUp(){
		this.dialog.closeAll()
	}

}