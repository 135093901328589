import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { IBaseFacade } from '@shared/base-classes/base.facade';
import { PatientState, PatientStore } from './state/patient-store';
import { PatientQuery } from './state/patient-query';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { PatientModel } from '@shared/models/rx-models/interfaces/patient-model';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { patientSectionModeEnum } from './models/patient-section-visibility.enum';
import { PatientAppUrlBuilderService } from './services/patient-app-url-builder.service';
import { PopupService } from '@shared/services/popup.service';
import { PatientAppIframeCommunicationService } from './services/patient-app-iframe-communication.service';
import { RxStatusService } from '@core/services/rx-status.service';
import { RxStatusEnum } from '@shared/models/enums/rx-status.enum';
import { LocalSettingsQuery } from '@shared/store/localSettings/local-settings-query';
import { LocalSettingsStore } from '@shared/store/localSettings/local-settings-store';
import { PopUpActions } from '@shared/models/enums/popup-modal-actions.enum';
import { SoftwareOptions } from '@shared/models/enums/enums';
import { SoftwareOptionsService } from '@shared/services/software-options.service';
import { combineQueries } from '@datorama/akita';

@Injectable()
export class PatientFacade implements IBaseFacade {
	getState$: Observable<PatientState> = this.query.patientState$;
	isReadOnly$: Observable<boolean> = this.shellQuery.getReadOnlyState('patient');
	patient$: Observable<PatientModel> = this.query.patient$;
	isPatientInConflict$: Observable<boolean> = this.query.isPatientInConflict$;
	regulatoryDOBMask$: Observable<string> = this.shellQuery.regulatoryDOBMask$;
	dateFormat$: Observable<string> = this.shellQuery.dateFormat$;

	isRxValidForSave$: Observable<boolean> = this.shellQuery.isRxValidForSave$;
	rxStatus$: Observable<RxStatusEnum> = this.rxScanStatusServiceService.status$;
	maxPatientLastNameLength$: Observable<number> = this.shellQuery.maxPatientLastNameLength$;
	patientSectionMode$: Observable<patientSectionModeEnum> = this.query.patientSectionMode$;
	isRxStatusScannedOrUnscanned$: Observable<boolean> = this.rxStatus$.pipe(
		map(scanStatus => scanStatus === RxStatusEnum.Unscanned || scanStatus === RxStatusEnum.Scanned)
	);
	isRxStatusUnscannedAndIsNotCloned$: Observable<boolean> = combineQueries([this.rxStatus$, this.shellQuery.clonedRx$]).pipe(
		map(([scanStatus, clonedRx]) => scanStatus === RxStatusEnum.Unscanned && !clonedRx)
	);
	isClearButtonVisibleForReadOnly$: Observable<boolean> = combineQueries([
		this.isRxStatusUnscannedAndIsNotCloned$,
		this.isReadOnly$
	]).pipe(map(([isRxStatusUnscannedAndIsNotCloned, isReadOnly]) => !isReadOnly && isRxStatusUnscannedAndIsNotCloned));
	isEditButtonVisibleForReadOnly$: Observable<boolean> = combineLatest([this.isRxStatusScannedOrUnscanned$, this.isReadOnly$]).pipe(
		map(([isRxStatusScannedOrUnscanned, isReadOnly]) => !isReadOnly && isRxStatusScannedOrUnscanned)
	);

	patientPopupCommandHandler$ = this.patientAppIframeCommunicationService.patientPopupCommandHandler$;
	patientPopupCommandEmitter$ = this.patientAppIframeCommunicationService.patientPopupCommandEmitter$;
	isRxFromReferralDoctorAndUserIsScannigCenter$: Observable<boolean> = this.getIsReferralFromRxAndScanningCenter();

	constructor(
		private store: PatientStore,
		private query: PatientQuery,
		private shellQuery: ShellQuery,
		private patientAppUrlBuilderService: PatientAppUrlBuilderService,
		private popupService: PopupService,
		private patientAppIframeCommunicationService: PatientAppIframeCommunicationService,
		private rxScanStatusServiceService: RxStatusService,
		private localSettingQuery: LocalSettingsQuery,
		private localSettingStore: LocalSettingsStore,
		private softwareOptionsService: SoftwareOptionsService
	) {}

	getInlinePatientAppUrl(): Observable<string> {
		return this.patientAppUrlBuilderService.getInlinePatientAppUrl();
	}

	registerToPatientAppEvents(): Observable<MessageEvent> {
		return this.patientAppIframeCommunicationService.registerToPatientAppEvents();
	}

	getStateSync(property?: string) {
		return property ? this.query.getValue()[property] : this.query.getValue();
	}

	loadPatientFromRx(): Observable<void> {
		return combineLatest([this.shellQuery.rx$, this.shellQuery.clonedRx$, this.shellQuery.regulatoryDOBMask$]).pipe(
			filter(([rx, clonedRx]) => !!rx || !!clonedRx),
			map(([rx, clonedRx, regulatoryDOBMask]) => {
				const loadedRx = rx || clonedRx;
				this.store.updatePatient(loadedRx.Patient, null, regulatoryDOBMask);
			})
		);
	}

	clearPatient(): Observable<any> {
		const contactId = this.shellQuery.contactId;

		if (this.localSettingQuery.userSettings[contactId]?.ClearPatientWithoutConfirmation) {
			this.store.update({ patient: null });
			this.patientAppIframeCommunicationService.postMessageForClearPatient();
			return of(null);
		}

		const popUpInput = {
			titleTranslationKey: 'Popup.Confirmation',
			contentTranslationKey: 'PatientSection.ClearPatientConfirmationMessage',
			dontShowAgainAction: () => {
				this.localSettingStore.updateLocalSettings(contactId, { ClearPatientWithoutConfirmation: true })
			}
		};

		return this.popupService.openConfirmationPopUp({ popUpInput }).pipe(
			tap(popUpResult => {
				if (popUpResult === PopUpActions.Ok) {
					this.store.update({ patient: null });
					this.patientAppIframeCommunicationService.postMessageForClearPatient();
				}
			})
		);
	}

	private getIsReferralFromRxAndScanningCenter(): Observable<boolean> {
		return combineLatest([
			this.shellQuery.rx$,
			this.softwareOptionsService.hasCompanySoftwareOption$(SoftwareOptions.ReferralWorkflowScanningCenter)
		]).pipe(
			map(([rx, isScanningCenter]) => {
				return !!(rx && rx?.Patient?.ChartNumber !== '') && isScanningCenter;
			})
		);
	}
}
