import { ProcedureMapEnum } from '@core/procedure-helpers/models/procedure-map.enum';
import { ProcedureRule } from '../../models/procedure-unit-type-material-rules-config';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { allTeeth } from '../teeth-sets';

export const rulesForDentureRemovableImplantBased: ProcedureRule = {
	Ids: [
		ProcedureMapEnum.DentureRemovableFullDentureImplantBased,
		ProcedureMapEnum.DentureRemovableFullDentureImplantBasedWithoutSendTo,
		ProcedureMapEnum.DentureRemovableFullDentureImplantBasedMidcOnly
	],
	TeethRules: [
		{
			Ids: allTeeth,
			UnitAndMaterialRules: {
				UnitTypeRules: [
					{
						Id: UnitTypes.ImplantBased
					},
					{
						Id: UnitTypes.Missing
					}
				]
			}
		}
	]
};
