import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { combineLatest, Observable, of } from 'rxjs';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { LocalSettingsQuery } from '@shared/store/localSettings/local-settings-query';
import { LocalSettingsStore } from '@shared/store/localSettings/local-settings-store';
import { Awareness, AwarenessPopUpActions } from '@shared/models/enums/awareness.enum';
import { ComponentType } from '@angular/cdk/portal';
import { LoggerService } from '@core/services/logger/logger.service';
import { AwarenessPopupComponent } from '@modules/awareness/containers/popup/awareness-popup/awareness-popup.component';
import { FeatureToggleSettings, SoftwareOptions } from '@shared/models/enums/enums';
import { SoftwareOptionsService } from '@shared/services/software-options.service';

@Injectable({
	providedIn: 'root'
})
export class AwarenessService {
	constructor(
		private dialog: MatDialog,
		private shellQuery: ShellQuery,
		private localSettingQuery: LocalSettingsQuery,
		private localSettingStore: LocalSettingsStore,
		private loggerService: LoggerService,
		private softwareOptionsService: SoftwareOptionsService
	) {}

	hasAwarenessMessageLmrSwo$ = this.softwareOptionsService.hasCompanySoftwareOption$(SoftwareOptions.RxAwarenessMessageLMR);
	hasAwarenessParticipateLmrSwo$ = this.softwareOptionsService.hasCompanySoftwareOption$(SoftwareOptions.RxAwarenessParticipateLMR);
	hasGaFeatureFlag$: Observable<boolean> = this.shellQuery.featureToggles$.pipe(
		map(featureToggles => featureToggles?.find(f => f.id === FeatureToggleSettings.RxAwarenessMessageGa)?.isActive)
	);

	showLmrAwareness$: Observable<boolean> = combineLatest([this.hasAwarenessMessageLmrSwo$, this.shellQuery.isNewRx$]).pipe(
		map(([hasAwarenessMessageLmrSwo, isNewRx]) => hasAwarenessMessageLmrSwo && isNewRx)
	);
	showGaAwareness$: Observable<boolean> = combineLatest([
		this.shellQuery.isNewRx$,
		this.hasAwarenessParticipateLmrSwo$,
		this.hasAwarenessMessageLmrSwo$,
		this.hasGaFeatureFlag$
	]).pipe(
		map(([isNewRx, hasAwarenessParticipateLmrSwo, hasAwarenessMessageLmrSwo, hasGaFeatureFlag]) => {
			return hasGaFeatureFlag && isNewRx && !hasAwarenessParticipateLmrSwo && !hasAwarenessMessageLmrSwo;
		})
	);

	static getAwarenessComponent(): ComponentType<any> {
		return AwarenessPopupComponent;
	}

	showAwareness(): Observable<any> {
		return this.findFirstAvailableAwareness().pipe(
			filter(awareness => awareness != null),
			switchMap(awareness => {
				const popup = this.openAwarenessPopup(awareness);
				if (popup) {
					return popup.afterClosed().pipe(
						tap(popUpAction => {
							this.loggerService.info(
								`Awareness pop-up was closed.
								Button ${AwarenessPopUpActions[popUpAction]} was clicked.
								It happened ${AwarenessService.verboseLog(awareness)}`,
								{
									module: 'AwarenessService'
								}
							);
							if (popUpAction === AwarenessPopUpActions.Confirm) {
								this.dontShowAgainAction(awareness);
							}
						})
					);
				}
				return of();
			})
		);
	}

	private static verboseLog(awareness: Awareness): string {
		switch (awareness) {
			case Awareness.BeforeLMR:
				return 'before LMR';
			case Awareness.BeforeGA:
				return 'before GA';
			case Awareness.AfterLMR:
				return 'after LMR';
			default:
				return 'after GA';
		}
	}

	private findFirstAvailableAwareness(): Observable<Awareness> {
		const contactId = this.shellQuery.contactId;
		const userSettings = this.localSettingQuery.userSettings;
		return combineLatest([this.showLmrAwareness$, this.showGaAwareness$, this.shellQuery.isProcedureFlow$]).pipe(
			map(([showLmrAwareness, showGaAwareness, isProcedureFlow]) => {
				if (showLmrAwareness) {
					if (!isProcedureFlow && !userSettings[contactId]?.DontShowBeforeLmrPopup) {
						return Awareness.BeforeLMR;
					} else if (isProcedureFlow && !userSettings[contactId]?.DontShowAfterLmrPopup) {
						return Awareness.AfterLMR;
					} else {
						return null;
					}
				} else if (showGaAwareness) {
					// Conditions will be enlarged when we have requirements about dates
					if (!isProcedureFlow) {
						return Awareness.BeforeGA;
					} else if (isProcedureFlow) {
						return Awareness.AfterGA;
					} else {
						return null;
					}
				}
				return null;
			})
		);
	}

	private openAwarenessPopup(awareness: Awareness): MatDialogRef<any, any> {
		this.loggerService.info(`Awareness pop-up was opened. It happened ${AwarenessService.verboseLog(awareness)}`, {
			module: 'AwarenessService'
		});
		return this.dialog.open(AwarenessService.getAwarenessComponent(), {
			disableClose: true,
			panelClass: 'awareness-popup',
			data: awareness
		});
	}

	private dontShowAgainAction(awareness: Awareness) {
		switch (awareness) {
			case Awareness.BeforeLMR:
				this.localSettingStore.updateLocalSettings(this.shellQuery.contactId, { DontShowBeforeLmrPopup: true });
				break;
			case Awareness.AfterLMR:
				this.localSettingStore.updateLocalSettings(this.shellQuery.contactId, { DontShowAfterLmrPopup: true });
				break;
		}
	}
}
