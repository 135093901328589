import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

let commonId = 0;

@Component({
	selector: 'rx-incremental-selector',
	templateUrl: './incremental-selector.component.html',
	styleUrls: ['./incremental-selector.component.scss']
})
export class IncrementalSelectorComponent {
	@Input() id: string = 'auto-incremental-selector-' + String(++commonId);
	@Input() label;
	@Input() formControl: FormControl;

	increment() {
		this.formControl.setValue((this.formControl.value as number) + 1);
	}

	decrement() {
		if (this.formControl.value === 0) {
			return;
		}
		this.formControl.setValue((this.formControl.value as number) - 1);
	}
}
