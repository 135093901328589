import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AppComponent } from '../../app.component';

@Injectable({
	providedIn: 'root'
})
export class FontFaceService {
	constructor(@Inject(DOCUMENT) private documentRef: Document) {}

	load(): Promise<unknown> {
		return Promise.all(
			this.getFontFaces().map(fontFace =>
				fontFace.load().then(
					loadedFontFace => this.documentRef.fonts.add(loadedFontFace),
					() => {}
				)
			)
		);
	}

	private get staticFilesEndpoint(): string {
		return (this.documentRef.querySelector('rx-app') as unknown as AppComponent)?.staticFilesEndpoint || '';
	}

	private getFontFaces(): FontFace[] {
		const staticFilesEndpoint = this.staticFilesEndpoint;
		const url = (fontName: string) => `url(${staticFilesEndpoint}/assets/fonts/${fontName})`;

		return [
			new FontFace('NeueHaasGroteskDisplay', url('NeueHaasDisplay-Light.woff'), { weight: '400' }),
			new FontFace('NeueHaasGroteskDisplay', url('NeueHaasDisplay-Roman.woff'), { weight: '450' }),
			new FontFace('NeueHaasGroteskDisplay', url('NeueHaasDisplay-Medium.woff'), { weight: '500' })
		];
	}
}
