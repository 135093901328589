import { Injectable } from '@angular/core';
import { ApplicationModes } from '@shared/models/enums/enums';
import { RoleTypeEnum } from '@shared/models/role-type';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class HostPlatformService {
	constructor(private shellQuery: ShellQuery) {}

	// use combineLatest intentionally instead of combineQueries
	// because queries are in the same store and we dont need to wait a tick to sync them
	isIteroLab$ = combineLatest([this.shellQuery.applicationMode$, this.shellQuery.userRole$]).pipe(
		map(([appMode, userRole]) => HostPlatformService.isIteroLab(appMode as ApplicationModes, userRole))
	);

	isScanner$ = this.shellQuery.applicationMode$.pipe(map(appMode => HostPlatformService.isScanner(appMode as ApplicationModes)));

	isIteroModeling$ = this.shellQuery.userRole$.pipe(map(role => HostPlatformService.isIteroModeling(role)));

	static isIteroLab(appMode: ApplicationModes, role: RoleTypeEnum): boolean {
		return HostPlatformService.isOrthoCad(appMode) && role === RoleTypeEnum.Lab; // can be only on OrthoCAD, has lab role
	}

	static isScanner(appMode: ApplicationModes): boolean {
		return appMode === ApplicationModes.scanner; // can be in Scanner (with role doctor or techinician)
	}

	static isWeb(appMode: ApplicationModes): boolean {
		return appMode === ApplicationModes.web; // can be in MIDC, MAT
	}

	static isIteroModeling(role: RoleTypeEnum): boolean {
		return role === RoleTypeEnum.Technician; // can be in OrthoCAD or in Scanner (recognizing by role only)
	}

	static isOrthoCad(appMode: ApplicationModes): boolean {
		return appMode === ApplicationModes.oldWeb; // Only OrthoCAD platform sets oldWeb value
	}

	get isIteroLab(): boolean {
		return HostPlatformService.isIteroLab(this.shellQuery.applicationMode as ApplicationModes, this.shellQuery.userRole);
	}

	get isScanner(): boolean {
		return HostPlatformService.isScanner(this.shellQuery.applicationMode as ApplicationModes);
	}

	get isOrthoCad(): boolean {
		return HostPlatformService.isOrthoCad(this.shellQuery.applicationMode as ApplicationModes);
	}

	get isIteroModeling(): boolean {
		return HostPlatformService.isIteroModeling(this.shellQuery.userRole);
	}
}
