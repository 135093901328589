<div class="rectangle-container" *ngIf="rx$ | async as rx">
  <div fxLayout="column">
    <div class="rx-card-title">
      <div class="general-info">{{ 'PrintRx.GeneralInfo' | translate }}</div>
    </div>
    <rx-patient-info-v1 [patient]="rx?.Patient" [isResponsive]="isResponsive">
    </rx-patient-info-v1>
    <div class="rx-separator horizontal"></div>
    <rx-info-block class="info-box" 
        [fields]="[{ name: 'DoctorSection.Doctor', id: 'general-doctor-name', value: rx?.Doctor?.Name, isInline: true }]">
    </rx-info-block>
    <div class="rx-separator horizontal"></div>
    <rx-general-order-info-v1
        [rx]="rx"
        [rxConfig]="rxConfiguration$ |async"
        [dateFormat]="dateFormat$ | async"
        [isResponsive]="isResponsive"
        [isRxForModelingContext]="isRxForModelingContext$ | async"
    ></rx-general-order-info-v1>

    <div fxLayout="row" fxFlex class="general-info-footer">
      <mat-checkbox
        class="pre-treatment-checkbox"
        [disabled]="true"
        [checked]="rx?.PrePrepScan"
        name="preTreatmentScan"
      >
        {{ 'GeneralRxData.PreTreatmentScan' | translate }}
      </mat-checkbox>
    </div>
  </div>
</div>
