import { Component, Input, SimpleChanges } from '@angular/core';
import { teethNumbersRowHeight } from '@modules/teeth-diagram/models/consts';
import { convertToothNumberToDefaultSystemById, TeethNumbers, teethNumbers } from '@modules/teeth-diagram/models/teeth-numbering';
import { TeethNumberingSystem } from '@modules/teeth-diagram/models/teeth-numbering-system.enum';

@Component({
	selector: 'rx-teeth-numbers',
	templateUrl: './teeth-numbers.component.html',
	styleUrls: ['./teeth-numbers.component.scss']
})
export class TeethNumbersComponent {
	@Input() teethNumberingSystem: TeethNumberingSystem;
	@Input() isPrintContext: boolean;
	@Input() rowHeight: string = teethNumbersRowHeight;
	@Input() toothBridges: number[] = [];
	teethNumbers: TeethNumbers = { ...teethNumbers };

	convertToothNumberToDefaultSystemById = convertToothNumberToDefaultSystemById;

	ngOnChanges(changes: SimpleChanges) {
		this.teethNumberingSystem = changes.teethNumberingSystem?.firstChange
			? changes.teethNumberingSystem.currentValue
			: changes.teethNumberingSystem.previousValue;
	}
}
