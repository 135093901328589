<div
    class="layout-container"
    fxLayout="row"
    fxLayoutAlign="center"
    id="rx-for-lab"
    [ngClass]="(isRxloadedAndReady$ | async) ? 'isVisible' : 'isHidden'"
>
    <div class="lab-container">
        <div class="lab-components-container" fxLayout="column" fxLayoutAlign="start">
            <rx-general-data-v1></rx-general-data-v1>
            <rx-denture-details-info [rx]="rx$ | async" [isResponsive]="true" *ngIf="procedureId$ | async | mapper : isDentureDetailsVisible">
            </rx-denture-details-info>
            <div *ngIf="isTeethDiagramAreaVisible$ | async">
                <rx-teeth-diagram-v1></rx-teeth-diagram-v1>
                <rx-treatment-information-v1></rx-treatment-information-v1>
            </div>
            <ng-content></ng-content>
        </div>
    </div>
</div>
