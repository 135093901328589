import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IdName } from '@shared/models/id-name';
import { TreatmentStageEnum } from '../models/treatment-stage.enum';
import { ProcedureMap } from '@shared/models/procedure-map';
import { defaultExpirationPeriodForReferral } from '@shared/models/consts';

export interface OrderState {
	caseType: IdName;
	procedureMap: ProcedureMap;
	treatmentStage: IdName;
	treatmentStages: IdName[];
	currentAlignerId: string;
	isMultiBiteSelected: boolean;
	directToLab: boolean;
	sendTo: IdName;
	availableLabs: IdName[];
	dueDate: Date;
	minimalAvailableDueDate: Date;
	maxDueDate: Date;
	availableCaseTypeIds: number[];
	availableProcedureMaps: ProcedureMap[];
	isFinalRecord: boolean;
	isTreatmentStageVisible: boolean;
}

export const getTreatmentStages = (): IdName[] => [
	{ Id: TreatmentStageEnum.InitialRecord, Name: 'Initial Record' },
	{ Id: TreatmentStageEnum.ProgressRecord, Name: 'Progress Record' },
	{ Id: TreatmentStageEnum.FinalRecord, Name: 'Final Record' }
];

export const getMinimalAvailableDueDate = (): Date => {
	const tomorrow = new Date();
	tomorrow.setDate(new Date().getDate() + 1);
	tomorrow.setHours(0, 0, 0, 0);
	return tomorrow;
};

export const createInitialState = (): OrderState => ({
	caseType: null,
	procedureMap: null,
	treatmentStage: null,
	treatmentStages: getTreatmentStages(),
	currentAlignerId: null,
	isMultiBiteSelected: false,
	directToLab: false,
	sendTo: null,
	availableLabs: null,
	dueDate: null,
	minimalAvailableDueDate: getMinimalAvailableDueDate(),
	maxDueDate: null,
	availableCaseTypeIds: null,
	availableProcedureMaps: null,
	isFinalRecord: false,
	isTreatmentStageVisible: false
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'order' })
export class OrderStore extends Store<OrderState> {
	constructor() {
		super(createInitialState());
	}

	addAvailableProcedureMap(procedureMap: ProcedureMap): void {
		this.update({ availableProcedureMaps: [procedureMap, ...(this.getValue().availableProcedureMaps ?? [])] });
	}
}

export const getMaximumDueDateForReferral = (expirationPeriod = defaultExpirationPeriodForReferral): Date => {
	const maxDueDate = new Date();
	maxDueDate.setDate(new Date().getDate() + expirationPeriod);
	maxDueDate.setHours(0, 0, 0, 0);
	return maxDueDate;
};
