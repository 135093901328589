import { Component, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CompareOptionsService } from '@modules/order/services/compare-options';
import { ControlValueAccessorConnector } from '@shared/base-classes/control-value-accessor-connector';
import { CaseType } from '@shared/models/case-type';

@Component({
	selector: 'rx-case-type-selector',
	templateUrl: './case-type-selector.component.html',
	styleUrls: ['./case-type-selector.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: CaseTypeSelectorComponent, multi: true
	}]
})
export class CaseTypeSelectorComponent extends ControlValueAccessorConnector {
	@Input() caseTypes: CaseType[]; 
	@Input() isRequired: boolean;

	constructor(injector: Injector) {
		super(injector);
	}

	compareOptions = CompareOptionsService.compare;

	
	generateOptionId(caseType: CaseType) {
		return 'case-type-option-' + caseType?.Id;
	}

}
