<mat-form-field [attr.id]="'rx-mat-form-field-' + id" fxFlex [class.disabled]="isDisabled" [class.read-only]="isReadOnly">
    <mat-label [class.read-only-label]="isReadOnly && !control.value"
        >{{ label }}<span *ngIf="secondaryLabel"> - {{ secondaryLabel }}</span
        ><span *ngIf="labelSeparator">{{ labelSeparator }}</span></mat-label
    >
    <mat-select
        [id]="'rx-auto-select-' + id"
        [value]="control.value"
        [formControl]="control"
        [required]="isRequired"
        name="option"
        ngDefaultControl
        [errorStateMatcher]="matcher"
        [compareWith]="compareIds"
        [class.read-only]="isReadOnly"
    >
        <mat-option
            [id]="generateOptionId(option, label, secondaryLabel)"
            [class]="'rx-auto-simple-select-option-' + index"
            *ngFor="let option of options; let index = index"
            [value]="option"
        >
            {{ option.Name }}
        </mat-option>
    </mat-select>
    <mat-icon
        *ngIf="isCancellable && !!control?.value"
        id="cancel-selection"
        class="cancel-selection"
        (click)="handleCancelSelection($event)"
        >close</mat-icon
    >
</mat-form-field>
