import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { ToothEditorFacade } from '@modules/tooth-editor/tooth-editor.facade';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { PopupIconNames } from '@shared/models/enums/popup-icon.enum';
import { IdName } from '@shared/models/id-name';
import { MaterialOption } from '@shared/models/material-option';
import { PopUpActions } from '@shared/models/enums/popup-modal-actions.enum';
import { PopupService } from '@shared/services/popup.service';
import { combineLatest, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, pairwise, take, takeUntil, tap, shareReplay, filter } from 'rxjs/operators';
import { ShadeSystem } from '@shared/models/shade-system';
import { LoggerService } from '@core/services/logger/logger.service';

@Component({
	selector: 'rx-crown-section',
	styleUrls: ['./crown-section.component.scss'],
	templateUrl: 'crown-section.component.html',
	providers: [ToothEditorFacade],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrownSectionComponent extends BaseDestroyableComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
	readonly maxInputLengthForOtherShadeSystem = 10;
	isOtherShadeSystemSelected: boolean;
	isMarginDesignEnabled = false;
	private allowToothReset = false;
	private toothResetTimeout;
	private readonly componentName = 'CrownSectionComponent';

	@Input() toothClickedOn: Tooth;
	@Input() isReadOnly: boolean;

	stumpShadeOptions: IdName[] = this.toothEditorFacade.getStumpShadeOptions();
	preparationDesignOptions: IdName[];
	shadeSystemOptions: ShadeSystem[];
	incisalBodyGingivalOptions: IdName[];

	materialOptions: IdName[];

	copyFromToothOptions$: Observable<IdName[]> = this.toothEditorFacade.copyFromToothOptions$;
	shouldValidateForSend$: Observable<boolean> = this.toothEditorFacade.shouldValidateForSend$;

	private isPopUpEnabled = true;

	marginDesignOptions: IdName[] = this.toothEditorFacade.getMarginDesignOptions();

	get isToothClickedOnAPrep() {
		return this.toothEditorFacade.isPrep({ tooth: this.toothClickedOn });
	}

	isCrownSectionDisabled$ = this.toothEditorFacade.toothClickedOn$.pipe(
		filter(tooth => !!tooth),
		map(tooth => this.checkIsCrownSectionDisabled(tooth)),
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	materialOption: MaterialOption;

	formChangingDestroy$ = new Subject<void>();

	crownSectionForm = new FormGroup({
		material: new FormControl({ value: null, disabled: false }),
		preparationDesignBuccal: new FormControl({ value: null, disabled: false }),
		preparationDesignLingual: new FormControl({ value: null, disabled: false }),
		marginDesignBuccal: new FormControl({ value: null, disabled: false }),
		marginDesignLingual: new FormControl({ value: null, disabled: false }),
		copyFromTooth: new FormControl({ value: null, disabled: false }),
		shadeSystem: new FormControl({ value: null, disabled: false }),
		incisal: new FormControl({ value: null, disabled: false }),
		body: new FormControl({ value: null, disabled: false }),
		gingival: new FormControl({ value: null, disabled: false }),
		stumpShade: new FormControl({ value: null, disabled: false }),
	});

	get materialControl(): AbstractControl {
		return this.crownSectionForm.controls.material;
	}
	get preparationDesignBuccalControl(): AbstractControl {
		return this.crownSectionForm.controls.preparationDesignBuccal;
	}
	get preparationDesignLingualControl(): AbstractControl {
		return this.crownSectionForm.controls.preparationDesignLingual;
	}
	get marginDesignBuccalControl(): AbstractControl {
		return this.crownSectionForm.controls.marginDesignBuccal;
	}
	get marginDesignLingualControl(): AbstractControl {
		return this.crownSectionForm.controls.marginDesignLingual;
	}
	get copyFromToothControl(): AbstractControl {
		return this.crownSectionForm.controls.copyFromTooth;
	}
	get shadeSystemControl(): AbstractControl {
		return this.crownSectionForm.controls.shadeSystem;
	}
	get incisalControl(): AbstractControl {
		return this.crownSectionForm.controls.incisal;
	}
	get bodyControl(): AbstractControl {
		return this.crownSectionForm.controls.body;
	}
	get gingivalControl(): AbstractControl {
		return this.crownSectionForm.controls.gingival;
	}
	get stumpShadeControl(): AbstractControl {
		return this.crownSectionForm.controls.stumpShade;
	}

	get isShadeDisabled() {
		return !this.materialOption?.ShadeVisible;
	}

	constructor(private toothEditorFacade: ToothEditorFacade, private popupService: PopupService, private logger: LoggerService) {
		super();
	}

	ngOnChanges(changes: SimpleChanges): void {
		const isDifferentTooth = changes.toothClickedOn.currentValue.ToothID !== changes.toothClickedOn.previousValue?.ToothID;
		const isDifferentUnitType = changes.toothClickedOn.currentValue.UnitTypeID !== changes.toothClickedOn.previousValue?.UnitTypeID;
		const isDifferentUnitTypeInBridge =
			changes.toothClickedOn.currentValue.ToothInBridgeTypeID !== changes.toothClickedOn.previousValue?.ToothInBridgeTypeID;

		if (isDifferentTooth || isDifferentUnitType || isDifferentUnitTypeInBridge || this.allowToothReset) {
			this.logger.info(`Crown section Inputs changed, see here: ${JSON.stringify(changes, null, 2)}`, {module: this.componentName});
			this.incisalControl.patchValue(null);
			this.bodyControl.patchValue(null);
			this.bodyControl.markAsTouched();
			this.gingivalControl.patchValue(null);
			this.formChangingDestroy$.next();
			this.setForm();
			this.subscribeToFormChanges();
			this.allowToothReset = false;
			this.isOtherShadeSystemSelected = (changes.toothClickedOn.currentValue as Tooth)?.ShadeSystemId === -1;
		}

		if (changes.isReadOnly?.currentValue) {
			this.toothEditorFacade.setFormGroupReadOnlyMode(this.crownSectionForm);
		}
	}

	ngOnInit() {
		this.subscribeToCopyFormToothChanges();
		this.subscribeToShadeSystemChanges();
		this.subscribeToMaterialChanges();
	}

	ngAfterViewInit() {
		this.formChangingDestroy$.next();
		this.setForm();
		this.subscribeToFormChanges();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		clearTimeout(this.toothResetTimeout);
		this.formChangingDestroy$.complete();
	}

	handleCancelCopyFromToothSelection() {
		this.copyFromToothControl.patchValue(null);
	}

	checkIsCrownSectionDisabled(tooth: Tooth): boolean {
		const isBridge = !!tooth.BridgeIndex;
		const isUnitTypeMissingOrRegular = !isBridge && this.toothEditorFacade.isToothMissingOrRegular(tooth.UnitTypeID);

		if (this.toothEditorFacade.isGlidewellOrder) {
			return isBridge;
		}

		return isUnitTypeMissingOrRegular || (isBridge && this.toothEditorFacade.isPonticMDAbutmentOrMissing(tooth.ToothInBridgeTypeID));
	}

	clearControl(control: AbstractControl): void {
		control.patchValue(null);
	}

	private clearShadeValuesAndSetOptions(controlValue: IdName) {
		this.incisalControl.patchValue(null);
		this.bodyControl.patchValue(null);
		this.gingivalControl.patchValue(null);
		this.setIncisalBodyGingivalOptions();
		this.isOtherShadeSystemSelected = controlValue?.Id === -1;
	}

	private subscribeToCopyFormToothChanges() {
		this.copyFromToothControl.valueChanges
			.pipe(
				tap((toothSelection: IdName) => this.copyFromTooth({ toothId: toothSelection?.Id })),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private subscribeToShadeSystemChanges() {
		this.shadeSystemControl.valueChanges
			.pipe(
				distinctUntilChanged(),
				pairwise(),
				tap(([prevShadeControlValue, nextShadeControlValue]: [IdName, IdName]) => {
					if (nextShadeControlValue) {
						if (this.incisalControl.value || this.bodyControl.value || this.gingivalControl.value) {
							if (!this.isPopUpEnabled) {
								return;
							}
							const popUpInput = {
								titleTranslationKey: 'Popup.Confirmation',
								contentTranslationKey: 'ToothEditor.ShadeSystemWarning',
								iconName: PopupIconNames.Question
							};
							this.popupService
								.openConfirmationPopUp({ popUpInput })
								.pipe(takeUntil(this.componentAlive$))
								.subscribe(result => {
									switch (result) {
										case PopUpActions.Cancel:
											this.isPopUpEnabled = false;
											this.shadeSystemControl.patchValue(prevShadeControlValue);
											this.isPopUpEnabled = true;
											break;
										case PopUpActions.Ok:
											this.clearShadeValuesAndSetOptions(nextShadeControlValue);
											break;
									}
								});
						} else {
							this.clearShadeValuesAndSetOptions(nextShadeControlValue);
						}
					}
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private subscribeToFormChanges() {
		this.crownSectionForm.valueChanges
			.pipe(
				debounceTime(200),
				distinctUntilChanged(),
				tap(() => this.updateToothFromForm()),
				takeUntil(merge(this.componentAlive$, this.formChangingDestroy$))
			)
			.subscribe();
	}

	private subscribeToMaterialChanges() {
		this.materialControl.valueChanges
			.pipe(
				tap(() => {
					this.setMaterialOption();
					this.setPreparationDesignOptions();
					this.setPrepAndMarginDesigns();
					this.setShadeSystemOptions();
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private setForm() {
		this.setMaterialSelection();
	}

	private setShadeSystemSelections() {
		let incisalSelection;
		let bodySelection;
		let gingivalSelection;

		if (this.isOtherShadeSystemSelected) {
			incisalSelection = this.toothClickedOn.ShadeIncisal;
			bodySelection = this.toothClickedOn.ShadeBody;
			gingivalSelection = this.toothClickedOn.ShadeGingival;
		} else {
			incisalSelection = this.incisalBodyGingivalOptions?.find(
				incisalBodyGingivalOption => incisalBodyGingivalOption.Id === this.toothClickedOn.ShadeIncisal
			);
			bodySelection = this.incisalBodyGingivalOptions?.find(
				incisalBodyGingivalOption => incisalBodyGingivalOption.Id === this.toothClickedOn.ShadeBody
			);
			gingivalSelection = this.incisalBodyGingivalOptions?.find(
				incisalBodyGingivalOption => incisalBodyGingivalOption.Id === this.toothClickedOn.ShadeGingival
			);
		}
		this.incisalControl.patchValue(incisalSelection || null);
		this.bodyControl.patchValue(bodySelection || null);
		this.gingivalControl.patchValue(gingivalSelection || null);
	}

	private setMaterialSelection() {
		combineLatest([this.toothEditorFacade.materialsByDefault$, this.shouldValidateForSend$])
			.pipe(
				take(1),
				tap(([activeMaterialOptions, shouldValidateForSend]) => {
					this.materialOptions = activeMaterialOptions;

					let materialOption = null;
					if (this.toothClickedOn?.MaterialID) {
						materialOption = activeMaterialOptions?.find(material => material?.Id === this.toothClickedOn.MaterialID);
					}
					this.materialControl.patchValue(materialOption);

					this.setShadeSystemOptions();
					this.setIncisalBodyGingivalOptions();
					this.setShadeSystemSelections();
					this.setStumpShadeSelection();
					if (!shouldValidateForSend) {
						this.crownSectionForm.markAsUntouched();
						this.crownSectionForm.markAsPristine();
					}
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private setStumpShadeSelection() {
		const stumpShadeOption = this.stumpShadeOptions.find(stumpShade => stumpShade?.Id === this.toothClickedOn.StumpfShade);
		this.stumpShadeControl.patchValue(stumpShadeOption || null);
	}

	private updateToothFromForm() {
		const crownSectionFormChanges = this.crownSectionForm.getRawValue();
		for (const [key, value] of Object.entries(crownSectionFormChanges)) {
			if (typeof value === 'string') {
				// handling gingival, incisal and body values when selecting 'other' shade system
				crownSectionFormChanges[key] = value;
			} else {
				crownSectionFormChanges[key] = value !== null ? crownSectionFormChanges[key]?.Id : null;
			}
		}
		this.toothEditorFacade.updateTooth({ tooth: this.toothClickedOn, crownData: { ...crownSectionFormChanges } });
	}

	private setMaterialOption() {
		this.materialOption = this.toothEditorFacade.getMaterialOption(this.materialControl.value?.Id);
	}

	private setPreparationDesignOptions() {
		this.preparationDesignOptions = this.toothEditorFacade.mapPreparationDesignOptions(this.materialOption?.PrepDesignValues);
	}

	private setPrepAndMarginDesigns() {
		const preparationDesignBuccalSelection = this.preparationDesignOptions?.find(
			prepDesign => prepDesign.Id === this.toothClickedOn.PreparationDesignBuccalId
		);
		const preparationDesignLingualSelection = this.preparationDesignOptions?.find(
			prepDesign => prepDesign.Id === this.toothClickedOn.PreparationDesignLingualId
		);
		const marginDesignBuccalSelection = this.marginDesignOptions?.find(
			marginDesign => marginDesign.Id === this.toothClickedOn.MarginDesignBuccalId
		);
		const marginDesignLingualSelection = this.marginDesignOptions?.find(
			marginDesign => marginDesign.Id === this.toothClickedOn.MarginDesignLingualId
		);
		this.preparationDesignBuccalControl.patchValue(preparationDesignBuccalSelection || null);
		this.preparationDesignLingualControl.patchValue(preparationDesignLingualSelection || null);
		this.marginDesignBuccalControl.patchValue(marginDesignBuccalSelection || null);
		this.marginDesignLingualControl.patchValue(marginDesignLingualSelection || null);
	}

	private setShadeSystemOptions() {
		const materialId = this.materialControl?.value?.Id;
		this.shadeSystemOptions = this.toothEditorFacade.getShadeSystemOptionsByMaterialId({ materialId });

		if (!this.shadeSystemOptions) {
			this.shadeSystemOptions = this.toothEditorFacade.getDefaultShadeSystemOptions();
			const userSettingsShadeSystemOption = this.toothEditorFacade.getDefaultShadeSystemFromUserSettings({
				defaultShadeSystemOptions: this.shadeSystemOptions
			});
			const shadeSystemSelection = this.shadeSystemOptions.find(option => option.Id === this.toothClickedOn.ShadeSystemId);

			this.isOtherShadeSystemSelected = shadeSystemSelection?.Id === -1;
			this.shadeSystemControl.patchValue(shadeSystemSelection || userSettingsShadeSystemOption || this.shadeSystemOptions[0]);
		} else {
			const defaultShadeSystemSelectionId = this.toothEditorFacade.getDefaultShadeSystemSelection({ materialId });
			const defaultShadeSystemSelection =
				defaultShadeSystemSelectionId === -2
					? null
					: this.shadeSystemOptions?.find(shadeSystemOption => shadeSystemOption?.Id === defaultShadeSystemSelectionId);
			this.shadeSystemControl.patchValue(defaultShadeSystemSelection);
		}
	}
	// TODO: refactor: no need to parse it when we have options in shadeSystemOptions prop
	private setIncisalBodyGingivalOptions() {
		this.incisalBodyGingivalOptions = this.toothEditorFacade.getIncisalBodyGingivalOptions({
			materialId: this.materialControl?.value?.Id,
			shadeSystemId: this.shadeSystemControl?.value?.Id
		});
	}

	private copyFromTooth({ toothId }: { toothId: number }) {
		this.toothEditorFacade
			.getToothByToothId({ toothId })
			.pipe(
				take(1),
				tap(tooth => {
					const { ToothID, UnitTypeID, ...toothPropsToCopy } = tooth;
					const toothToUpdate = Object.assign({}, this.toothClickedOn, toothPropsToCopy);
					const isCurrentlyBridge = !!this.toothClickedOn.BridgeIndex;

					if (!tooth) {
						if (isCurrentlyBridge) {
							toothToUpdate.ToothInBridgeTypeID = this.toothClickedOn.ToothInBridgeTypeID;
							toothToUpdate.BridgeIndex = this.toothClickedOn.BridgeIndex;
						} else {
							toothToUpdate.UnitTypeID = this.toothClickedOn.UnitTypeID;
						}
					} else {
						const isToothCopiedFromBridge = !!tooth.BridgeIndex;
						const copyFromBridgeToothToBridgeTooth = isCurrentlyBridge && isToothCopiedFromBridge;
						const copyFromBridgeToothToRegularTooth = !isCurrentlyBridge && isToothCopiedFromBridge;
						const copyFromRegularToothTBridgeTooth = isCurrentlyBridge && !isToothCopiedFromBridge;
						const copyFromRegularToothTRegularTooth = !isCurrentlyBridge && !isToothCopiedFromBridge;

						if (copyFromBridgeToothToBridgeTooth) {
							toothToUpdate.ToothInBridgeTypeID = this.toothClickedOn.ToothInBridgeTypeID;
							toothToUpdate.BridgeIndex = this.toothClickedOn.BridgeIndex;
						} else if (copyFromBridgeToothToRegularTooth) {
							toothToUpdate.UnitTypeID = this.toothClickedOn.UnitTypeID;
							toothToUpdate.BridgeIndex = 0;
							toothToUpdate.ToothInBridgeTypeID = null;
						} else if (copyFromRegularToothTBridgeTooth) {
							toothToUpdate.ToothInBridgeTypeID = this.toothClickedOn.ToothInBridgeTypeID;
							toothToUpdate.BridgeIndex = this.toothClickedOn.BridgeIndex;
							toothToUpdate.UnitTypeID = null;
						} else if (copyFromRegularToothTRegularTooth) {
							toothToUpdate.UnitTypeID = this.toothClickedOn.UnitTypeID;
						}
					}
					this.allowToothReset = true;
					this.toothResetTimeout = setTimeout(() => {
						this.toothEditorFacade.updateToothClickedOn({ toothClickedOn: { ...toothToUpdate } });
					});
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}
}
