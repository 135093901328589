import { Injectable } from '@angular/core';
import { ConfigurationItem } from '@shared/models/configuration-item';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { MouldEnum } from '@shared/models/enums/mould.enum';

@Injectable({
	providedIn: 'root'
})
export class MouldSelectorService {
	constructor(private shellQuery: ShellQuery) {}

	getSrcForMouldImage(mould: ConfigurationItem): string {
		const mouldEnumSvgNames: Record<MouldEnum, string> = {
			[MouldEnum.Square]: 'square.svg',
			[MouldEnum.SquareTapering]: 'square-tapering.svg',
			[MouldEnum.SquareOvoid]: 'square-ovoid.svg',
			[MouldEnum.Tapering]: 'tapering.svg',
			[MouldEnum.TaperingOvoid]: 'tapering-ovoid.svg',
			[MouldEnum.Ovoid]: 'ovoid.svg',
			[MouldEnum.SquareTaperingOvoid]: 'square-tapering-ovoid.svg',
		};

		return `${this.shellQuery.staticFilesEndpoint}/assets/moulds/${mouldEnumSvgNames[mould.Id]}`;
	}
}
