<link
  href="https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap"
  rel="stylesheet"
/>
<link
  href="https://fonts.googleapis.com/icon?family=Material+Icons"
  rel="stylesheet"
/>

<rx-popup></rx-popup>
<div class="app-hidden-version">RX-App - {{ currentApplicationVersion }}</div>
<mat-toolbar
  color="primary"
  *ngIf="isDevelopmentPanelVisible$ | async"
>
  <mat-toolbar-row>
    <span>RX-App - {{ currentApplicationVersion }}</span>
    <nav>
      <button mat-button (click)="navigateToHome()">
        Home
      </button>
      <button mat-button (click)="navigateToRxForDoctor()">
        Rx for doctor
      </button>
      <button mat-button (click)="navigateToRxForLab()">
        Rx for lab
      </button>
      <button mat-button (click)="navigateToRxForModeling()">
        Rx for modeling
      </button>
      <button mat-button (click)="printRx({ rxIds: [] })">
        Print Rx
      </button>
      <mat-slide-toggle
        [(ngModel)]="isPrintPortrait"
        (change)="toggleOrientation()"
        [color]="'primary'"
      >
        {{ isPrintPortrait ? 'Portrait' : 'Landscape' }}
      </mat-slide-toggle>
    </nav>
    <div
      class="actions-container"
      fxFlex="1 1 100%"
      fxLayoutAlign="space-between center"
    >
      <mat-slide-toggle [(ngModel)]="showDevInfo" [color]="'primary'">
        Show dev info
      </mat-slide-toggle>
      <button mat-button (click)="mockGetRx()">
        Get Rx (mock)
      </button>
      <button mat-button (click)="callTryToSaveRx()">
        try To Save Rx
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<div *ngIf="!isHeadlessPrint" class="main-layout" [class.scanner-mode] = "isHostPlatformScanner$ | async">
  <rx-spinner></rx-spinner>
  <rx-dev-tools-editor
    *ngIf="showDevInfo && (isDevelopmentPanelVisible$ | async)"
  >
  </rx-dev-tools-editor>
  <router-outlet name="rx"></router-outlet>
</div>
<div class="print-rx-hidden">
    <ng-container #printRxOutlet></ng-container>
</div>