import { InjectionToken } from '@angular/core';
import RxGeneralRulesJson from '@shared/rules/rx-general-rules.json';
import MaterialShadeSystemRules from '@shared/rules/material-shade-system-rules.json';
import CaseTypeUnitTypeMaterialsRules from '@shared/rules/case-type-unit-type-materials-rules.json';
import { procedureUnitTypeMaterialRules } from '@core/procedure-helpers/teeth-diagram/procedure-unit-type-material-rules';
import ProcedureUnitTypeMaterialBridgeRules from '@shared/rules/procedure-unit-type-materials-bridge-rules.json';
import SpecMaterialToothEditorRules from '@shared/rules/spec-material-tooth-editor-rules.json';
import UnitTypeMaterialToothEditorRules from '@shared/rules/unit-type-material-tooth-editor-rules.json';
import { ProcedureRule, CaseTypeRule } from '@shared/models/rx-rules-json-interface';
import { MaterialToothEditorRule } from '@shared/models/material-tooth-editor-rule';
import { RxGeneralRules } from '@shared/models/rx-general-rules';
import { MaterialShadeSystem } from '@shared/models/material-shade-system';
import { RestorationConfiguration } from '@shared/models/restoration-configuration-interface';
import { restorationConfigurationRules } from '@shared/rules/restoration.configuration';
import { ProcedureMapEnum } from '@core/procedure-helpers/models/procedure-map.enum';
import { ProcedureBridgeAvailabilityRules } from '@shared/rules/procedure-bridge-availability-rules';
import { RxDentureDetailsRules } from '@shared/models/denture-details-rules';
import DentureDetailsRules from '@shared/rules/denture-details-rules.json';
import { ShareToothPropsRule } from '@shared/models/share-tooth-props-rule';
import { shareToothPropsRules } from '@shared/rules/share-tooth-props-rules';
import CountryRegionRules from '@shared/rules/country-regions-rules.json';
import { CountryRegionRule } from '@shared/models/country-region-rule';
import CountryEmailRules from '@shared/rules/country-email-rules.json';

export const PROCEDURE_RULES = new InjectionToken<ProcedureRule[]>('procedure_rules', {
	providedIn: 'root',
	factory: () => procedureUnitTypeMaterialRules.ProcedureRules
});
export const PROCEDURE_IN_BRIDGE_RULES = new InjectionToken<ProcedureRule[]>('procedure_rules', {
	providedIn: 'root',
	factory: () => ProcedureUnitTypeMaterialBridgeRules.ProcedureRules
});
export const CASETYPE_RULES = new InjectionToken<CaseTypeRule[]>('caseType_rules', {
	providedIn: 'root',
	factory: () => CaseTypeUnitTypeMaterialsRules.CaseTypeRules
});
export const UNIT_TYPE_MATERIAL_TOOTH_EDITOR_RULES = new InjectionToken<MaterialToothEditorRule[]>(
	'unit_type_material_tooth_editor_rules',
	{
		providedIn: 'root',
		factory: () => UnitTypeMaterialToothEditorRules
	}
);
export const SPEC_MATERIAL_TOOTH_EDITOR_RULES = new InjectionToken<MaterialToothEditorRule[]>('spec_material_tooth_editor_rules', {
	providedIn: 'root',
	factory: () => SpecMaterialToothEditorRules
});
export const RX_GENERAL_RULES = new InjectionToken<RxGeneralRules>('rx_general_rules', {
	providedIn: 'root',
	factory: () => RxGeneralRulesJson
});
export const MATERIAL_SHADE_SYSTEM_RULES = new InjectionToken<MaterialShadeSystem[]>('material_shade_system_rules', {
	providedIn: 'root',
	factory: () => MaterialShadeSystemRules
});
export const RESTORATION_CONFIGURATION = new InjectionToken<RestorationConfiguration>('restoration_configuration', {
	providedIn: 'root',
	factory: () => restorationConfigurationRules
});
export const PROCEDURE_BRIDGE_AVAILABILITY_RULES = new InjectionToken<ReadonlyArray<ProcedureMapEnum>>(
	'procedure_bridge_availability_rules',
	{
		providedIn: 'root',
		factory: () => ProcedureBridgeAvailabilityRules
	}
);
export const DENTURE_DETAILS_RULES = new InjectionToken<RxDentureDetailsRules>('denture_details_rules', {
	providedIn: 'root',
	factory: () => DentureDetailsRules
});

export const SHARE_TOOTH_PROPS_RULES = new InjectionToken<ShareToothPropsRule[]>('share_tooth_props_rules', {
	providedIn: 'root',
	factory: () => shareToothPropsRules
});

export const COUNTRY_REGIONS_RULES = new InjectionToken<CountryRegionRule>('country_regions_rules', {
	providedIn: 'root',
	factory: () => CountryRegionRules
});

export const COUNTRY_EMAIL_RULES = new InjectionToken<ReadonlyArray<string>>('country_email_rules', {
	providedIn: 'root',
	factory: () => CountryEmailRules
});
