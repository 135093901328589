import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export const isJsonString = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		try {
			JSON.parse(control.value);
		} catch (e) {
			return { jsonInvalid: true };
		}
		return null;
	};
};
