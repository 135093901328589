import { Injector, Input, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { ControlValueAccessorConnector } from '@shared/base-classes/control-value-accessor-connector';
import { maxAlignerNumberValue, positiveIntegersOnlyRegExp } from '@shared/models/consts';

@Component({
	selector: 'rx-current-aligner-input',
	templateUrl: './current-aligner-input.component.html',
	styleUrls: ['./current-aligner-input.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: CurrentAlignerInputComponent, multi: true
	}]
})
export class CurrentAlignerInputComponent extends ControlValueAccessorConnector implements OnInit {

	@Input() isReadOnly: boolean;

	get maxValue() { return maxAlignerNumberValue; }

	constructor(injector: Injector) {
		super(injector);		
	}

	ngOnInit(): void {
		this.control.setValidators([Validators.pattern(positiveIntegersOnlyRegExp), Validators.max(this.maxValue)]);
		this.control.markAsTouched();
	}
}
