import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { PatientModel } from '@shared/models/rx-models/interfaces/patient-model';
import { DateOfBirthPipe } from '@shared/pipes/date-of-birth.pipe';
import { ShellQuery } from '@shared/store/shell/shell-query';

@Component({
	selector: 'rx-patient-read-only',
	templateUrl: './patient-read-only.component.html',
	styleUrls: ['./patient-read-only.component.scss']
})
export class PatientReadOnlyComponent implements OnChanges {
	@Input() patient: PatientModel;
	@Input() isEditButtonVisible: boolean;
	@Input() isClearButtonVisible: boolean;
	@Input() isButtonsVisible: boolean;
	@Input() maxPatientLastNameLength = 0;
	@Input() regulatoryDOBMask: string = null;
	@Input() dateFormat: string;
	@Output() patientCleared = new EventEmitter<void>();
	@Output() patientEdited = new EventEmitter<void>();
	genderOption = undefined;
	dateOfBirthPipe: DateOfBirthPipe = new DateOfBirthPipe();

	constructor(private shellQuery: ShellQuery, private formBuilder: FormBuilder) {}

	get firstNameControl(): AbstractControl {
		return this.formGroup.controls.FirstName;
	}
	get lastNameControl(): AbstractControl {
		return this.formGroup.controls.LastName;
	}
	get dateOfBirthControl(): AbstractControl {
		return this.formGroup.controls.DateOfBirth;
	}
	get chartNumberControl(): AbstractControl {
		return this.formGroup.controls.ChartNumber;
	}

	formGroup: FormGroup = this.formBuilder.group({
		FirstName: { value: '', disabled: true },
		LastName: { value: '', disabled: true },
		DateOfBirth: { value: null, disabled: true },
		ChartNumber: { value: '', disabled: true }
	});

	ngOnChanges(changes: SimpleChanges) {
		if (this.patient && this.regulatoryDOBMask !== null) {
			this.formGroup.patchValue({
				FirstName: this.patient.FirstName || '',
				LastName: this.patient.LastName || '',
				DateOfBirth: this.dateOfBirthPipe.transform(this.patient.DateOfBirth, this.shellQuery.dateFormat, this.regulatoryDOBMask),
				ChartNumber: this.patient.ChartNumber || ''
			});

			this.setMarkOnControl(this.dateOfBirthControl);
			this.setMarkOnControl(this.chartNumberControl);
			this.genderOption = this.patient.Gender;
		}
	}

	setMarkOnControl(control: AbstractControl) {
		if (!control.value) {
			control.patchValue(' ');
			control.markAsTouched();
		}
	}

	clearPatient(): void {
		this.patientCleared.next();
	}

	editPatient(): void {
		this.patientEdited.next();
	}
}
