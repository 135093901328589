import { Component, EventEmitter, Injector, Input, Output, } from '@angular/core';
import { SimpleSelectorComponent } from '@shared/components/simple-selector/simple-selector.component';
import { IdName } from '@shared/models/id-name';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

const idNameCompareWith = <T extends IdName, U extends IdName>(o1: T, o2: U) => {
	return o1 && o2 && o1.Id === o2.Id;
};

@Component({
	selector: 'rx-object-selector',
	templateUrl: './object-selector.component.html',
	styleUrls: ['./object-selector.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: ObjectSelectorComponent, multi: true
	}]
})
export class ObjectSelectorComponent extends SimpleSelectorComponent {
	@Input()
	compareWith: (o1: any, o2: any) => boolean = idNameCompareWith;

	@Input()
	options: any[] = [];

	@Input()
	propertyName = 'Name';
	
	private shouldCleanOnDisable = false;

	@Input('cleanOnDisable')
	set cleanOnDisable(value: any) {
		this.shouldCleanOnDisable = value !== undefined;
	}

	@Input()
	panelClass: string | string[] | Set<string> | { [key: string]: any; } = null;

	@Output()
	isDisabledEvent: EventEmitter<void> = new EventEmitter<void>();

	constructor(injector: Injector) {
		super(injector);
	}

	handleCancelSelection(event: any) {
		if (this.cancelSelection.observers.length > 0) {
			super.handleCancelSelection(event);
			return;
		}

		event.stopPropagation();
		this.control.patchValue(null);
	}

	generateOptionId(option: any): string {
		const value = this.propertyName ? option[this.propertyName] : option;
		return this.getOptionId(value, this.label, this.secondaryLabel);
	}

	handleDisableEvent($event: void) {
		if (this.shouldCleanOnDisable) {
			this.control.patchValue(null);	
		}
		
		this.isDisabledEvent.emit($event);
	}
}
